import { createContext, useState } from 'react';

import NTAttachmentData from './NTAttachmentData';

export const NTAttachmentContext = createContext();

const NTAttachmentContextProvider = ({ children }) => {
  const stateHook = useState(NTAttachmentData.INITIAL_NATIONAL_TEAM_ATTACHMENT_STATE);

  return (
    <NTAttachmentContext.Provider value={stateHook}>
      {children}
    </NTAttachmentContext.Provider>
  );
};

export default NTAttachmentContextProvider;