import useSocialMediaEditForm from "./UseSocialMediaEditForm";

import Input from "../../../../../../common/components/inputs/Input";
import ReadOnly from "../../../../../../common/components/readOnly/ReadOnly";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../common/components/buttons/SecondaryButton";

import global from '../../../../../../common/components/GlobalStyle.module.css';

const SocialMediaEditForm = ({ socialMediaObj, submitButtonText = 'Save', secondaryButtonText = 'Cancel',
  onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  } = useSocialMediaEditForm(socialMediaObj, onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label="Social Media Platform"
            name="socialMediaTypeId"
            value={socialMediaObj.socialMediaType?.typeName} />
        </div>
      </div>
      <div className="row">
        <div className='col-xs-12 col-sm-6'>
          <Input
            label="Handle / Username / Website"
            name="socialHandle"
            value={formState.socialHandle}
            error={errorState.socialHandle}
            message={errorState.socialHandle}
            onChange={(value) => { updateFormState('socialHandle', value); }} />
        </div>
      </div>
      {errorState.errorOnSubmitAction &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type='button' onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default SocialMediaEditForm;