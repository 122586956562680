import { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGridRow = ({ attachment }) => (
  <tr>
    <td>{attachment?.attachmentDescription}</td>
  </tr>
);

const DetailGrid = ({ attachment }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>File Description</th>
      </tr>
    </thead>
    <tbody>
      <DetailGridRow attachment={attachment} />
    </tbody>
  </table>
);

const GridRow = ({ attachment, onEditAttachment, onOpenDeleteAttachmentModal, expandedId, onExpandClicked }) => (
  <Fragment>
    <tr>
      <td>{attachment.personNationalAttachmentFileTypeName}</td>
      <td colSpan='2'>{attachment.attachmentName}</td>
      <td>
        <button className={global.IconButtonMargin} type="button" onClick={(e) =>
          onEditAttachment(e, attachment)}>
          <EditIcon />
        </button>
        <button className={global.IconButtonMargin} type="button" onClick={(e) =>
          onOpenDeleteAttachmentModal(e, attachment,)}>
          <DeleteIcon />
        </button>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onExpandClicked(e, attachment.personNationalAttachmentId)}>
          {expandedId === attachment.personNationalAttachmentId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {expandedId === attachment.personNationalAttachmentId &&
      <tr className={global.Expanded}>
        <td colSpan="4">
          <DetailGrid attachment={attachment} />
        </td>
      </tr>
    }
  </Fragment>
);

const NationalTeamAttachmentsLargeGrid = ({ state, onEditAttachment, onOpenDeleteAttachmentModal, expandedId, onExpandClicked }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>File Type</th>
          <th colSpan="2">File Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state?.isArrayLoaded !== true
          ? (
            <tr><td colSpan="4">{state.isArrayLoading === true ? 'Loading...' : 'No Attachments'}</td></tr>
          ) : Array.isArray(state?.arrayData) && state?.arrayData?.length > 0
            ? state?.arrayData?.map((attachment, i) => (
              <GridRow
                key={i}
                expandedId={expandedId}
                attachment={attachment}
                onOpenDeleteAttachmentModal={onOpenDeleteAttachmentModal}
                onEditAttachment={onEditAttachment}
                onExpandClicked={onExpandClicked}
              />))
            :
            <tr><td colSpan="4">No Attachments</td></tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default NationalTeamAttachmentsLargeGrid;