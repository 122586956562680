import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

const getNTRegistrationContactsData = (personId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);

  const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
  const url = `/Person/${personIdForUrl}/personNationalTeamRegistrationContact`;

  return api.executeObject ? api.executeObject(url, 'GET') : null;
};

const putNTRegistrationContactsData = (personId, personNTContactsObj, state, setState) => {
  if (personNTContactsObj) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
    const url = `/Person/${personIdForUrl}/personNationalTeamRegistrationContact`;

    return api.executeObject ? api.executeObject(url, 'PUT', personNTContactsObj) : null;
  }
};

const INITIAL_NATIONAL_TEAM_REGISTRATION_CONTACTS_STATE = {
  ...BASIC_INITIAL_STATE
};

const NTRegistrationContactsData = {
  INITIAL_NATIONAL_TEAM_REGISTRATION_CONTACTS_STATE,
  getNTRegistrationContactsData,
  putNTRegistrationContactsData
};

export default NTRegistrationContactsData;