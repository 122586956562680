import { useEffect } from 'react';

import validate from './NTContactAddressFormValidation';

import useCountryCombobox from '../../../../common/components/comboboxes/countryCombobox/UseCountryCombobox';
import useStatesCombobox from '../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox';

import useAddressTypeData from '../../../../common/state/addressType/UseAddressTypeData';

import { COUNTRY_CODE_CANADA, COUNTRY_CODE_MEXICO, COUNTRY_CODE_UNITED_STATES } from '../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../common/utils/HierarchicalDataUtils';
import useForm from '../../../../common/utils/UseForm';

const INITIAL_FORM_STATE = {
  addressType: [],
  address1: '',
  address2: '',
  city: '',
  stateCode: '',
  stateName: '',
  countryCode: '',
  countryName: '',
  postalCode: '',
  isCountryUSA: false,
  isCountryMexico: false,
  isCountryCanada: false,
  validatedAddress: null,
  showedAddressSuggestion: false
};

const useNTContactAddressForm = (contactAddressObj, contactAddressTypesArray, onSubmitFormCallback, onSecondaryButtonClicked) => {
  const { getCountryNameById } = useCountryCombobox();
  const { getStateNameById } = useStatesCombobox();
  const { addressTypeState } = useAddressTypeData();
  const { formState, errorState, isDirty, isSubmitting, handleSubmit, setErrors, updateFormState, setFormState, setFormData, setIsDirty,
    onValueTextPairChanged
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const handleSubmitProxy = async (event) => {
    event?.preventDefault();

    if (isDirty === false) {
      onSecondaryButtonClicked(event);
    } else {
      await handleSubmit(event)
    }

    updateFormState('showedAddressSuggestion', true);
  };

  const onCountryChanged = (countryCode, countryName) => {
    try {
      let newFormState = {
        ...formState,
        countryCode,
        countryName,
        isCountryUSA: false,
        isCountryMexico: false,
        isCountryCanada: false,
        stateCode: '',
        stateName: ''
      };

      if (countryCode === COUNTRY_CODE_UNITED_STATES) {
        newFormState = {
          ...newFormState,
          isCountryUSA: true,
        };
      } else if (countryCode === COUNTRY_CODE_MEXICO) {
        newFormState = {
          ...newFormState,
          isCountryMexico: true,
        };
      } else if (countryCode === COUNTRY_CODE_CANADA) {
        newFormState = {
          ...newFormState,
          isCountryCanada: true,
        };
      }

      setFormState(newFormState);
      setIsDirty(true);
    } catch (e) {
      setErrors({ formError: e?.message });
    }
  };

  useEffect(() => {
    if (contactAddressObj && addressTypeState.areOptionsLoaded === true) {
      const addressType = HierarchicalDataUtils.GetNameIdPairs(addressTypeState.optionsNationalTeamOnly, Array.isArray(contactAddressTypesArray) ? contactAddressTypesArray.map((x) => { return x.addressTypeId }) : []);

      setFormData({
        ...INITIAL_FORM_STATE,
        addressType: addressType,
        address1: contactAddressObj.address.address1 || '',
        address2: contactAddressObj.address.address2 || '',
        city: contactAddressObj.address.city || '',
        countryCode: contactAddressObj.address.countryCode || '',
        countryName: contactAddressObj.address.countryCode ? getCountryNameById(contactAddressObj.address.countryCode) : '',
        stateCode: contactAddressObj.address.stateCode || '',
        stateName: contactAddressObj.address.stateCode ? getStateNameById(contactAddressObj.address.stateCode) : '',
        postalCode: contactAddressObj.address.postalCode || '',
        isCountryUSA: contactAddressObj.address.countryCode === COUNTRY_CODE_UNITED_STATES,
        isCountryMexico: contactAddressObj.address.countryCode === COUNTRY_CODE_MEXICO,
        isCountryCanada: contactAddressObj.address.countryCode === COUNTRY_CODE_CANADA,
      }, true);
    } else if (addressTypeState.areOptionsLoaded === true) {
      setFormData({
        ...INITIAL_FORM_STATE,
        countryCode: COUNTRY_CODE_UNITED_STATES,
        countryName: getCountryNameById(COUNTRY_CODE_UNITED_STATES),
        isCountryUSA: true,
        isCountryMexico: false,
        isCountryCanada: false
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactAddressObj, addressTypeState.areOptionsLoaded]);

  return {
    isValidating: isSubmitting,
    formState,
    errorState,
    handleSubmit: handleSubmitProxy,
    updateFormState,
    onCountryChanged,
    onValueTextPairChanged,
    setFormData,
    isCountryUSA: formState.isCountryUSA === true,
    isCountryMexico: formState.isCountryMexico === true,
    isCountryCanada: formState.isCountryCanada === true,
  };
};

export default useNTContactAddressForm;