import { useEffect } from 'react';

import validate from './FunFactsEditFormValidation';

import useNTRegistrationData from '../../../../../state/nationalTeamRegistration/UseNTRegistrationData';

import useForm from "../../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  funFactsDescription: ''
};

const useFunFactsEditForm = (funFactObj, onSubmitFormCallback) => {
  const { onNTRegistrationError } = useNTRegistrationData();
  const { formState, errorState, setFormData, handleSubmit, updateFormState
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try {
      if (funFactObj) {
        setFormData({
          ...INITIAL_FORM_STATE,
          funFactsDescription: funFactObj.textValue
        }, true);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funFactObj]);
  
  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  };
};

export default useFunFactsEditForm;