import { createContext, useState } from 'react';

import NTRegistrationPersonData from './NTRegistrationPersonData';

export const NTRegistrationPersonContext = createContext();

const NTRegistrationPersonContextProvider = ({ children }) => {
  const stateHook = useState(NTRegistrationPersonData.INITIAL_NATIONAL_TEAM_REGISTRATION_PERSON_STATE);

  return (
    <NTRegistrationPersonContext.Provider value={stateHook}>
      {children}
    </NTRegistrationPersonContext.Provider>
  );
};

export default NTRegistrationPersonContextProvider;