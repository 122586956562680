import { useEffect } from 'react';

import validate from './NTContactFormValidation';

import useForm from '../../../../common/utils/UseForm';
import { DEFAULT_ID, DEFAULT_PHONE_NUMBER_AREA_CODE } from '../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  contactRelationshipTypeId: DEFAULT_ID,
  contactRelationshipTypeName: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: DEFAULT_PHONE_NUMBER_AREA_CODE
};

const useNTContactForm = (contactObj, contactPhonesArray, onSubmitFormCallback) => {
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setFormData, setIsDirty, onValueTextPairChanged
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    if (contactObj) {
      setFormData({
        ...INITIAL_FORM_STATE,
        contactRelationshipTypeId: contactObj.contactRelationshipTypeId || DEFAULT_ID,
        contactRelationshipTypeName: contactObj.contactRelationshipType?.typeName || '',
        firstName: contactObj.firstName || '',
        lastName: contactObj.lastName || '',
        email: contactObj.emailAddress || '',
        phoneNumber: Array.isArray(contactPhonesArray) && contactPhonesArray[0]?.phoneNumber ? contactPhonesArray[0].phoneNumber : DEFAULT_PHONE_NUMBER_AREA_CODE
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactObj]);

  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  };
};

export default useNTContactForm;