import { useContext } from "react";

import NTContactsData from "./NTContactsData";
import { NTContactsContext } from "./NTContactsContextProvider";

const useNTContactsData = () => {
  const [nationalTeamContactsState, setNationalTeamContactsState] = useContext(NTContactsContext);

  const getNTContacts = (personId) => {
    return NTContactsData.getNTContactsData(personId, nationalTeamContactsState, setNationalTeamContactsState);
  };

  const putNTContacts = (personId, personNTContactsObj) => {
    return NTContactsData.putNTContactsData(personId, personNTContactsObj, nationalTeamContactsState, setNationalTeamContactsState);
  };

  return {
    nationalTeamContactsState,
    getNTContacts,
    putNTContacts
  };
};

export default useNTContactsData;