import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';

import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';
import useProfilePhotoData from '../../../state/profilePhoto/UseProfilePhotoData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import Constants from '../../../../common/utils/Constants';

const TITLE = 'Photo Management';
const PROFILE_PICTURE_BIO_ENTRY_ID = 10;

const useProfilePhotoManagement = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { personProfileState, getPersonProfile, putPersonProfile } = usePersonProfileData();
  const { profilePhotoDownloadState, getProfilePhotoFile, getFileNameFromUrl } = useProfilePhotoData();
  const [photoState, setPhotoState] = useState({ photoUrl: '', photoName: '' });

  const onAddEditPhoto = (e, personBioId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_PROFILE_PHOTO_DETAIL?.route, { state: { personBioId } });
  };

  const onDeletePhoto = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const personObj = JSON.parse(JSON.stringify(personProfileState.objData));

    const index = personObj.bios.findIndex(e => e.bioEntryId === PROFILE_PICTURE_BIO_ENTRY_ID);

    if (index > Constants.DEFAULT_ID) {
      personObj.bios.splice(index, 1);
      putPersonProfile(personProfileState.objData.personId, personObj);
    }
  };

  const onDownloadUploadedFile = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    showUploadedFile(photoState.photoUrl);
  };

  useEffect(() => {
    if (personProfileState.isObjLoaded === true) {
      const photoUrl = getPhotoUrlFromPersonProfileState();
      const photoName = getFileNameFromUrl(photoUrl);
      setPhotoState({
        photoState,
        photoUrl: photoUrl,
        photoName: photoName
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personProfileState]);

  useEffect(() => {
    if (personProfileState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personProfileState.objData)) {
      getPersonProfile(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  async function showUploadedFile(fileUrl) {
    if (fileUrl) {
      const getFileResult = await getProfilePhotoFile(fileUrl);
      if (getFileResult) {
        if (getFileResult.objData?.blobFile?.size > 0) {
          var file = window.URL.createObjectURL(getFileResult.objData?.blobFile);
          const link = document.createElement('a');

          link.href = file;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
    }
  }

  function getPhotoUrlFromPersonProfileState() {
    const personBioArray = personProfileState.objData.bios;
    const selectedBioItem = personBioArray.find(e => e.bioEntryId === PROFILE_PICTURE_BIO_ENTRY_ID);
    const photoUrl = selectedBioItem !== undefined && selectedBioItem.textValue.length > 0 ? selectedBioItem.textValue : '';

    return photoUrl;
  }

  return {
    title: TITLE,
    DEFAULT_ID: Constants.DEFAULT_ID,
    personProfileState,
    profilePhotoDownloadState,
    photoState,
    onAddEditPhoto,
    onDeletePhoto,
    onDownloadUploadedFile
  };
};

export default useProfilePhotoManagement;