import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationProfileData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationProfile/UseNTRegistrationProfileData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_FUN_FACTS } from "../utils/NTRegistrationConstants";

const INITIAL_STATE = {
  funFactObj: null
};

const useNTRegistrationFunFactsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { onNTRegistrationError } = useNTRegistrationData();
  const { updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationProfileState, putNTRegistrationProfile } = useNTRegistrationProfileData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCancelClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_FUN_FACTS?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onSubmitFunFactsEditForm = async (formState) => {
    try {
      const personId = nationalTeamRegistrationProfileState.objData.personId;
      const newPersonProfileObj = JSON.parse(JSON.stringify(nationalTeamRegistrationProfileState.objData));
      const targetIndex = newPersonProfileObj.funFacts.findIndex((x) => x.personFunFactsId === state.funFactObj.personFunFactsId);
      newPersonProfileObj.funFacts[targetIndex] = {
        ...newPersonProfileObj.funFacts[targetIndex],
        textValue: formState.funFactsDescription.trim()
      };

      const putNTRegistrationProfileResult = await putNTRegistrationProfile(personId, newPersonProfileObj);

      if (putNTRegistrationProfileResult) {
        navigate(navRoutes.NT_REGISTRATION_FUN_FACTS?.route);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    try {
      if (location.state?.funFactObj) {
        updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_FUN_FACTS);

        setState({ ...state, funFactObj: location.state.funFactObj });
      } else {
        throw new Error('Failed to load Fun Facts Detail view');
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: nationalTeamRegistrationProfileState.isObjLoading,
    funFactObj: state.funFactObj || null,
    onCancelClicked,
    onSubmitFunFactsEditForm
  };
};

export default useNTRegistrationFunFactsDetail;