import { Fragment } from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useProfilePhotoManagement from './UseProfilePhotoManagement';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../../common/components/GlobalStyle.module.css';
import Constants from '../../../../common/utils/Constants';

const ProfilePhotoManagement = () => {
  const {
    title,
    DEFAULT_ID,
    personProfileState,
    profilePhotoDownloadState,
    photoState,
    onAddEditPhoto,
    onDeletePhoto,
    onDownloadUploadedFile
  } = useProfilePhotoManagement();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{title}</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H6>Profile Photo</Headings.H6>
        </div>
      </div>
      {(photoState.photoUrl === undefined || photoState.photoUrl.length === 0) &&
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-sm-4 col-md-3 usas-extra-top-margin">
            <div className="text-center">
              <FontAwesomeIcon icon={faUser} size='10x' />
            </div>
            <div className="text-center usas-extra-top-margin usas-extra-bottom-margin">
              <ActionIntraPageButton onClick={(e) => onAddEditPhoto(e, DEFAULT_ID)}>Add Photo</ActionIntraPageButton>&nbsp;
            </div>
          </div>
        </div>
      }
      {(photoState.photoUrl !== undefined && photoState.photoUrl.length > 0) &&
        <Fragment>
          <div className={`row usas-extra-top-margin`}>
            <div className="col-xs-12">
              <PrimaryButton onClick={(e) => onAddEditPhoto(e, DEFAULT_ID)}>{photoState.photoUrl !== undefined && photoState.photoUrl.length > 0 ? 'Change' : 'Add'} Photo</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onDeletePhoto}>Remove Photo</SecondaryButton>&nbsp;
              {photoState.photoName.includes("Bad Request") === false &&
                <PrimaryButton type="button" onClick={(event) => onDownloadUploadedFile(event)}>Download Uploaded Photo</PrimaryButton>
              }
            </div>
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              {photoState.photoName.includes("Bad Request")
                ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
                : <p><b>Uploaded File Name:</b> {photoState.photoName}</p>
              }
            </div>
          </div>
        </Fragment>
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personProfileState.isObjLoading || profilePhotoDownloadState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personProfileState.isSaving} />
    </Fragment >
  );
};

export default ProfilePhotoManagement;