import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import postErrorLogData from './PostErrorLogData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
};

const ErrorLoggerData = {
  INITIAL_STATE,
  postErrorLogData
};

export default ErrorLoggerData;