import { isValid5DigitZip, isValid9DigitZip, isValidCanadianZip, isValidAddressLine, validateAddress } from '../../../../common/utils/validation';
import { DEFAULT_ID } from '../../../../common/utils/Constants';

export const localValidate = async (formState) => {
  let errors = {};

  // Address Type
  if (!(formState.addressType.length > 0)) {
    errors.addressType = 'Address Type is required';
  }

  // Country
  if (formState.countryName?.trim()) {
    if (formState.countryCode === DEFAULT_ID || formState.countryCode === '') {
      errors.countryCode = 'Must be a valid country';
    }
  } else {
    errors.countryCode = 'Country is required';
  }

  // Address Line 1
  if (formState.address1.trim() === '') {
    errors.address1 = 'Address Line 1 is required';
  } else if (!isValidAddressLine(formState.address1.trim())) {
    errors.address1 = 'Address Line 1 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  // Address Line 2
  if (formState.address2.trim() !== '') {
    if (!isValidAddressLine(formState.address2.trim())) {
      errors.address2 = 'Address Line 2 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    }
  }

  // City
  if (formState.city.trim() === '') {
    errors.city = 'City is required';
  } else if (!isValidAddressLine(formState.city.trim())) {
    errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  // State
  if (formState.isCountryUSA === true || formState.isCountryMexico === true || formState.isCountryCanada === true) {
    if (formState.stateName !== '') {
      if (formState.stateCode === DEFAULT_ID || formState.stateCode === '') {
        errors.stateCode = 'Must be a valid state';
      }
    } else {
      errors.stateCode = 'State is required';
    }
  }

  // Zip Code
  if (formState.postalCode.trim() === '') {
    errors.postalCode = 'Zip Code is required';
  } else if ((formState.isCountryUSA === true) && !isValid5DigitZip(formState.postalCode.trim()) && !isValid9DigitZip(formState.postalCode.trim())) {
    errors.postalCode = 'United States Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
  } else if ((formState.isCountryCanada === true) && !isValidCanadianZip(formState.postalCode.trim())) {
    errors.postalCode = 'Canadian Zip Code must be valid and in A1A 1A1 format';
  } else if ((formState.isCountryMexico === true) && !isValid5DigitZip(formState.postalCode.trim())) {
    errors.postalCode = 'Mexican Zip Code must be 5 digits';
  }

  if (Object.keys(errors).length === 0 && formState.showedAddressSuggestion === false) {
    formState.validatedAddress = await validateAddress(formState.address1?.trim(), formState.address2?.trim(), formState.city?.trim(), formState.stateCode, formState.postalCode?.trim());

    if (formState.validatedAddress.isValid === true) {
      if (formState.address1?.trim() !== formState.validatedAddress.address1?.trim()) {
        errors.address1 = 'See Address Suggestion';
      }

      if (formState.address2?.trim() !== formState.validatedAddress.address2?.trim()) {
        errors.address2 = 'See Address Suggestion';
      }

      if (formState.city?.trim() !== formState.validatedAddress.city?.trim()) {
        errors.city = 'See Address Suggestion';
      }

      if (formState.stateCode !== formState.validatedAddress.stateCode) {
        errors.stateCode = 'See Address Suggestion';
      }

      if (formState.postalCode?.trim() !== formState.validatedAddress.postalCode?.trim()) {
        errors.postalCode = 'See Address Suggestion';
      }
    }
  }

  return errors;
};

const NTContactAddressFormValidation = async (formState) => {
  let errors = await localValidate(formState);

  return errors ? errors : {};
};

export default NTContactAddressFormValidation;