import useNTSupportStaffEditRoleForm from './UseNTSupportStaffEditRoleForm';

import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

const NTSupportStaffEditRoleForm = ({ supportStaffRoleObj, submitButtonText = 'Save', secondaryButtonText = 'Cancel',
  onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  } = useNTSupportStaffEditRoleForm(supportStaffRoleObj, onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label={'Support Staff Role'}
            name={'supportStaffTypeId'}
            value={supportStaffRoleObj.supportStaffType?.typeName || ''} />
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Effective Date*"
            name="effectiveDate"
            value={formState.effectiveDate}
            error={errorState.effectiveDate}
            message={errorState.effectiveDate}
            countOfYears={150}
            startYearOffset={-149}
            onChange={(value) => { updateFormState('effectiveDate', value); }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <YesNoSwitch
            label={'No Expiration Date?'}
            name={'hasNoExpirationDate'}
            checked={formState.hasNoExpirationDate === true}
            error={formState.hasNoExpirationDate}
            message={formState.hasNoExpirationDate}
            onChange={(value) => updateFormState('hasNoExpirationDate', value)} />
        </div>
        {formState.hasNoExpirationDate === false &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Expiration Date*"
              name="expirationDate"
              value={formState.expirationDate}
              error={errorState.expirationDate}
              message={errorState.expirationDate}
              countOfYears={150}
              startYearOffset={-140}
              onChange={(value) => { updateFormState('expirationDate', value); }} />
          </div>
        }
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
}

export default NTSupportStaffEditRoleForm;
