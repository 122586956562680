import { useContext, useState } from "react";

import NTRegistrationData from "./NTRegistrationData";
import { NTRegistrationContext } from "./NTRegistrationContextProvider";

const useNTRegistrationData = () => {
  const [nationalTeamRegistrationState, setNationalTeamRegistrationState] = useContext(NTRegistrationContext);
  const [groupMemberRegistrationState, setGroupMemberRegistrationState] = useState(NTRegistrationData.INITIAL_GROUP_MEMBER_REGISTRATION_STATE);

  const getNTGroupMemberRegistration = (personId) => {
    return NTRegistrationData.getNTGroupMemberRegistrationData(personId, groupMemberRegistrationState, setGroupMemberRegistrationState);
  };

  const putNTGroupMemberRegistration = (personId, groupMemberRegistrationObj) => {
    return NTRegistrationData.putNTGroupMemberRegistrationData(personId, groupMemberRegistrationObj, groupMemberRegistrationState, setGroupMemberRegistrationState);
  };

  const onNTRegistrationError = (e, errorMessage = undefined, errorTitle = 'National Team Registrations Error') => {
    setNationalTeamRegistrationState({
      ...nationalTeamRegistrationState,
      isError: true,
      error: e,
      errorTitle,
      errorMessage: errorMessage || e?.message || 'An error occurred.'
    });
  };

  const resetNationalTeamRegistrationState = () => {
    setNationalTeamRegistrationState({
      ...NTRegistrationData.INITIAL_NATIONAL_TEAM_REGISTRATION_STATE
    });
  };

  return {
    nationalTeamRegistrationState,
    groupMemberRegistrationState,
    getNTGroupMemberRegistration,
    putNTGroupMemberRegistration,
    onNTRegistrationError,
    resetNationalTeamRegistrationState
  };
};

export default useNTRegistrationData;