import useGroupPersonCoachesDropdown from './UseGroupPersonCoachesDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css';

const GroupPersonCoachesDropdown = ({ label, name, value, error, message, onChange, personId, isActive = null }) => {
  const { groupPersonCoachesState } = useGroupPersonCoachesDropdown(personId, isActive);

  return groupPersonCoachesState.message
    ? <span className={global.LoadingMsg}>{groupPersonCoachesState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={groupPersonCoachesState.options}
        name={name}
        value={value}
        onChange={onChange}
        areValuesIntegers={false}
        defaultValue={''}
        notSelectedLabel={'--'}
        isLoading={groupPersonCoachesState.areOptionsLoaded !== true} />
    );
};

export default GroupPersonCoachesDropdown;