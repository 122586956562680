import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationContactsData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationContacts/UseNTRegistrationContactsData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_CONTACTS } from "../utils/NTRegistrationConstants";
import { DEFAULT_PHONE_NUMBER_AREA_CODE, DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO } from "../../../../common/utils/Constants";

const SELF_CONTACT_NAME = 'Self'; //TODO - Move to Constants?

const INITIAL_STATE = {
  contactObj: null,
  isSelfContact: false,
  hasOtherSelfContact: false
};

const useNTRegistrationContactsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { onNTRegistrationError } = useNTRegistrationData();
  const { updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationContactsState, putNTRegistrationContacts } = useNTRegistrationContactsData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCancelClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_CONTACTS?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onSubmitContactForm = async (formState) => {
    try {
      const personId = nationalTeamRegistrationContactsState.objData.personId;
      const newPersonNTContactsObj = JSON.parse(JSON.stringify(nationalTeamRegistrationContactsState.objData));

      if (state.contactObj) {
        const targetIndex = newPersonNTContactsObj.personNationalTeamRegistrationContact.findIndex((x) => x.personNationalTeamRegistrationContactId === state.contactObj.personNationalTeamRegistrationContactId);
        newPersonNTContactsObj.personNationalTeamRegistrationContact[targetIndex] = createPersonNationalTeamContact(formState, personId, newPersonNTContactsObj.personNationalTeamRegistrationContact[targetIndex]);
      } else {
        newPersonNTContactsObj.personNationalTeamRegistrationContact.push(createPersonNationalTeamContact(formState, personId));
      }

      const putNTRegistrationPersonResult = await putNTRegistrationContacts(personId, newPersonNTContactsObj);

      if (putNTRegistrationPersonResult) {
        navigate(navRoutes.NT_REGISTRATION_CONTACTS?.route);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    try {
      updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_CONTACTS);

      if (location.state?.contactObj) {
        setState({
          ...state,
          contactObj: location.state.contactObj,
          isSelfContact: location.state.contactObj.contactRelationshipType?.typeName === SELF_CONTACT_NAME,
          hasOtherSelfContact: nationalTeamRegistrationContactsState.objData.personNationalTeamRegistrationContact.filter((x) => x.contactRelationshipType?.typeName === SELF_CONTACT_NAME && x.personNationalTeamRegistrationContactId !== location.state.contactObj.personNationalTeamRegistrationContactId).length > 0
        });
      } else {
        setState({
          ...state,
          contactObj: null,
          isSelfContact: false,
          hasOtherSelfContact: nationalTeamRegistrationContactsState.objData.personNationalTeamRegistrationContact.filter((x) => x.contactRelationshipType?.typeName === SELF_CONTACT_NAME).length > 0
        });
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createPersonNationalTeamContact(formState, personId, existingPersonNationalTeamContact = undefined) {
    if (existingPersonNationalTeamContact) {
      let phoneNumber = formState.phoneNumber.trim() !== '' && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO
        ? formState.phoneNumber
        : null;

      return {
        ...existingPersonNationalTeamContact,
        contactRelationshipType: undefined,
        personId,
        firstName: formState.firstName.trim(),
        lastName: formState.lastName.trim(),
        emailAddress: formState.email.trim(),
        contactRelationshipTypeId: formState.contactRelationshipTypeId,
        personNationalTeamRegistrationContactPhone: phoneNumber
          ? [{
            personNationalTeamRegistrationContactId: existingPersonNationalTeamContact.personNationalTeamRegistrationContactId,
            phoneTypeId: 1 /*TODO Magic number*/,
            phoneNumber
          }] : []
      };
    } else {
      let phoneNumber = formState.phoneNumber.trim() !== '' && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO
        ? formState.phoneNumber
        : null;

      return {
        personId,
        firstName: formState.firstName.trim(),
        lastName: formState.lastName.trim(),
        emailAddress: formState.email.trim(),
        contactRelationshipTypeId: formState.contactRelationshipTypeId,
        personNationalTeamRegistrationContactPhone: phoneNumber ? [{ phoneTypeId: 1 /*TODO Magic number*/, phoneNumber }] : []
      };
    }
  }

  return {
    isLoading: nationalTeamRegistrationContactsState.isObjLoading,
    contactObj: state.contactObj || null,
    hasOtherSelfContact: state.hasOtherSelfContact,
    isSelfContact: state.isSelfContact,
    onCancelClicked,
    onSubmitContactForm
  };
};

export default useNTRegistrationContactsDetail;