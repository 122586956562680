import { useEffect } from 'react';

import useAddressTypeData from '../../../common/state/addressType/UseAddressTypeData';

const useAddressTypeMultiselect = (showOnlyStandardTypes, showOnlyNationalTeamTypes) => {
  const { addressTypeState, getAddressTypes } = useAddressTypeData();

  useEffect(() => {
    if (addressTypeState.areOptionsLoaded !== true) {
      getAddressTypes();
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressTypeState.areOptionsLoaded]);

  function getOptionsForMultiselect(showOnlyStandardTypes, showOnlyNationalTeamTypes) {
    if (showOnlyStandardTypes === true) {
      return addressTypeState.optionsStandardOnly;
    } else if (showOnlyNationalTeamTypes === true) {
      return addressTypeState.optionsNationalTeamOnly;
    } else {
      return addressTypeState.options;
    }
  }

  return {
    addressTypeState,
    isLoading: addressTypeState.areOptionsLoaded !== true,
    options: getOptionsForMultiselect(showOnlyStandardTypes, showOnlyNationalTeamTypes) || []
  };
};

export default useAddressTypeMultiselect;