export const LEFT_NAV_ITEM_NAME_COACH = 'Coach';
export const LEFT_NAV_ITEM_NAME_SUBMIT = 'Submit';
export const LEFT_NAV_ITEM_NAME_BIO_INFO = 'BioInfo';
export const LEFT_NAV_ITEM_NAME_CONTACTS = 'Contacts';
export const LEFT_NAV_ITEM_NAME_SPONSORS = 'Sponsors';
export const LEFT_NAV_ITEM_NAME_FUN_FACTS = 'FunFacts';
export const LEFT_NAV_ITEM_NAME_ATTACHMENTS = 'Attachments';
export const LEFT_NAV_ITEM_NAME_SOCIAL_MEDIA = 'SocialMedia';
export const LEFT_NAV_ITEM_NAME_SUPPORT_STAFF = 'SupportStaff';
export const LEFT_NAV_ITEM_NAME_FREQUENT_FLYER = 'FrequentFlyer';

const NTRegistrationConstants = {
  LEFT_NAV_ITEM_NAME_BIO_INFO,
  LEFT_NAV_ITEM_NAME_CONTACTS,
  LEFT_NAV_ITEM_NAME_ATTACHMENTS,
  LEFT_NAV_ITEM_NAME_SUPPORT_STAFF,
  LEFT_NAV_ITEM_NAME_FREQUENT_FLYER,
  LEFT_NAV_ITEM_NAME_SPONSORS,
  LEFT_NAV_ITEM_NAME_COACH,
  LEFT_NAV_ITEM_NAME_SOCIAL_MEDIA,
  LEFT_NAV_ITEM_NAME_FUN_FACTS,
  LEFT_NAV_ITEM_NAME_SUBMIT
};

export default NTRegistrationConstants;