import { Fragment } from 'react';

import SocialMediaGridLarge from './SocialMediaGridLarge';
import SocialMediaGridSmall from './SocialMediaGridSmall';

const SocialMediaGrid = ({ isLoading, gridData, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <SocialMediaGridLarge
      gridData={gridData}
      isLoading={isLoading}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked} />
    <SocialMediaGridSmall
      gridData={gridData}
      isLoading={isLoading}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default SocialMediaGrid;