import { useState } from "react";
import { isValidNumber } from "../../../common/utils/validation";

const useFeetInchesInput = () => {
  const [displayFormattedValueState, setDisplayFormattedValueState] = useState(true);

  const formatValueForDisplay = (value) => {
    try {
      const heightInInches = parseInt(value);

      if (isNaN(heightInInches) !== true && isValidNumber(heightInInches) === true) {
        const feet = Math.floor(heightInInches / 12);
        const inches = (heightInInches - (feet * 12));

        return `${feet}'${inches}"`;
      } else {
        return value;
      }
    } catch (e) {
      return value;
    }
  };

  return {
    displayFormattedValueState,
    setDisplayFormattedValueState,
    formatValueForDisplay
  };
};

export default useFeetInchesInput;