import { createContext, useState } from 'react';

import NTRegistrationData from './NTRegistrationData';

export const NTRegistrationContext = createContext();

const NTRegistrationContextProvider = ({ children }) => {
  const stateHook = useState(NTRegistrationData.INITIAL_NATIONAL_TEAM_REGISTRATION_STATE);

  return (
    <NTRegistrationContext.Provider value={stateHook}>
      {children}
    </NTRegistrationContext.Provider>
  );
};

export default NTRegistrationContextProvider;