import useAddressTypeMultiselect from './UseAddressTypeMultiselect';

import MultiSelect from '../../../common/components/multiSelect/MultiSelect';

import global from '../../../common/components/GlobalStyle.module.css';

const AddressTypeMultiselect = ({ label, name, value, error, message, onChange,
  showOnlyStandardTypes = false, showOnlyNationalTeamTypes = false }) => {
  const { addressTypeState, isLoading, options } = useAddressTypeMultiselect(showOnlyStandardTypes, showOnlyNationalTeamTypes);

  return addressTypeState.message
    ? <span className={global.LoadingMsg}>{addressTypeState.message}</span>
    : (
      <MultiSelect
        label={label}
        name={name}
        value={value}
        error={error}
        message={message}        
        options={options}
        isLoading={isLoading}
        onChange={onChange} />
    );
};

export default AddressTypeMultiselect;