import { isValidDate } from '../../../../common/utils/validation';
import { BLANK_DATE_STRING, } from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};
  const effectiveDate = new Date(formState.effectiveDate);
  const expirationDate = new Date(formState.expirationDate);

  // Support Staff Role
  if (formState.supportStaffTypeId < 0) {
    errors.supportStaffTypeId = 'Support Staff Role is required';
  }

  // Effective Date
  if (formState.effectiveDate === BLANK_DATE_STRING) {
    errors.effectiveDate = 'Effective Date is required';
  } else if (!isValidDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Effective Date must be a valid date';
  } else if (formState.hasNoExpirationDate !== true && formState.effectiveDate === formState.expirationDate) {
    errors.effectiveDate = 'Effective Date and Expiration Date cannot be the same date';
  } else if (formState.hasNoExpirationDate !== true && expirationDate < effectiveDate) {
    errors.effectiveDate = 'Effective Date must be before the Expiration Date';
  }

  // Expiration Date
  if (formState.hasNoExpirationDate !== true) {
    if (formState.expirationDate === BLANK_DATE_STRING) {
      errors.expirationDate = 'Expiration Date is required';
    } else if (!isValidDate(formState.expirationDate)) {
      errors.expirationDate = 'Expiration Date must be a valid date';
    } else if (expirationDate < effectiveDate) {
      errors.expirationDate = 'Expiration Date must be after the Effective Date';
    }
  }

  return errors;
};

const NTSupportStaffAddRoleFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default NTSupportStaffAddRoleFormValidation;