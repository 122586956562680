import { useContext } from "react";

import NTRegistrationProfileData from "./NTRegistrationProfileData";
import { NTRegistrationProfileContext } from "./NTRegistrationProfileContextProvider";

const useNTRegistrationProfileData = () => {
  const [nationalTeamRegistrationProfileState, setNationalTeamRegistrationProfileState] = useContext(NTRegistrationProfileContext);

  const getNTRegistrationProfile = (personId) => {
    return NTRegistrationProfileData.getNTRegistrationProfileData(personId, nationalTeamRegistrationProfileState, setNationalTeamRegistrationProfileState);
  };

  const putNTRegistrationProfile = (personId, personProfileObj) => {
    return NTRegistrationProfileData.putNTRegistrationProfileData(personId, personProfileObj, nationalTeamRegistrationProfileState, setNationalTeamRegistrationProfileState);
  };

  return {
    nationalTeamRegistrationProfileState,
    getNTRegistrationProfile,
    putNTRegistrationProfile
  };
};

export default useNTRegistrationProfileData;