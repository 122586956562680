import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const postNTCoachOfRecordData = (personId, coachObj, state, setState) => {
  if (coachObj) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
    const url = `/Person/${personIdForUrl}/PersonCoachOfRecord`;

    return api.executeObject ? api.executeObject(url, 'POST', coachObj) : null;
  }
};

const getNTCoachOfRecordData = (personId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);

  const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
  const url = `/Person/${personIdForUrl}/PersonCoachOfRecord`;

  return api.executeArray ? api.executeArray(url, 'GET') : null;
};

const INITIAL_NATIONAL_TEAM_COACH_OF_RECORD_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_COACH_OF_RECORD_ADD_STATE = {
  ...BASIC_INITIAL_STATE
};

const NTCoachOfRecordData = {
  INITIAL_NATIONAL_TEAM_COACH_OF_RECORD_STATE,
  INITIAL_COACH_OF_RECORD_ADD_STATE,
  postNTCoachOfRecordData,
  getNTCoachOfRecordData
};

export default NTCoachOfRecordData;