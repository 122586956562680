import { BLANK_DATE_STRING } from "../../../../../../common/utils/Constants";
import { isValidDate } from "../../../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};
  const today = new Date();
  const effectiveDate = new Date(formState.effectiveDate);
  const expirationDate = new Date(formState.expirationDate);

  // Coach
  if (formState.coachPersonId < 0) {
    errors.coachPersonId = 'Coach is required';
  }

  // Effective Date
  if (formState.effectiveDate === BLANK_DATE_STRING) {
    errors.effectiveDate = 'Effective Date is required';
  } else if (!isValidDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Effective Date must be a valid date';
  } else if (formState.hasNoExpirationDate !== true && formState.effectiveDate === formState.expirationDate) {
    errors.effectiveDate = 'Effective Date and Expiration Date cannot be the same date';
  } else if (formState.hasNoExpirationDate !== true && expirationDate < effectiveDate) {
    errors.effectiveDate = 'Effective Date must be before the Expiration Date';
  } else if (effectiveDate > today) {
    errors.effectiveDate = 'Effective Date cannot be in the future'
  }

  // Expiration Date
  if (formState.hasNoExpirationDate !== true) {
    if (formState.expirationDate === BLANK_DATE_STRING) {
      errors.expirationDate = 'Expiration Date is required';
    } else if (!isValidDate(formState.expirationDate)) {
      errors.expirationDate = 'Expiration Date must be a valid date';
    } else if (expirationDate < effectiveDate) {
      errors.expirationDate = 'Expiration Date must be after the Effective Date';
    } else if (expirationDate <= today) {
      errors.expirationDate = 'Expiration Date must be in the future';
    }
  }

  return errors;
};

const CoachPersonAddFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default CoachPersonAddFormValidation;