import { Fragment } from 'react';

import CoachPersonGridLarge from './CoachPersonGridLarge';
import CoachPersonGridSmall from './CoachPersonGridSmall';

const CoachPersonGrid = ({ isLoading, gridData }) => (
  <Fragment>
    <CoachPersonGridLarge
      gridData={gridData}
      isLoading={isLoading} />
    <CoachPersonGridSmall
      gridData={gridData}
      isLoading={isLoading} />
  </Fragment>
);

export default CoachPersonGrid;