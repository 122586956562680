import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const postBirthCertificateFileData = (file, state, setState) => {
  if (file) {
    const formData = new FormData();
    formData.append("name", file?.name || '');
    formData.append("file", file);

    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/birthCertificate`;
    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const getBirthCertificateFileData = (fileUrl, state, setState) => {
  if (fileUrl) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/birthCertificate?fileUrl=${fileUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const BASE_UPLOAD_URL = import.meta.env.VITE_MEMBER_DOCS_BIRTH_CERTIFICATES_BASE_UPLOAD_URL;

const INITIAL_BIRTH_CERTIFICATE_FILE_UPLOAD_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_BIRTH_CERTIFICATE_FILE_DOWNLOAD_STATE = {
  ...BASIC_INITIAL_STATE
};

const BirthCertificateData = {
  BASE_UPLOAD_URL,
  INITIAL_BIRTH_CERTIFICATE_FILE_UPLOAD_STATE,
  INITIAL_BIRTH_CERTIFICATE_FILE_DOWNLOAD_STATE,
  postBirthCertificateFileData,
  getBirthCertificateFileData
};

export default BirthCertificateData;
