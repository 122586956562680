import { Fragment } from 'react';

import useMemberPassportUpload from './UseMemberPassportUpload';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionButton from '../../../../common/components/buttons/ActionButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const MemberPassportUpload = () => {
  const {
    uiState,
    removeFileModalState,
    personNationalTeamAttachmentState,
    fileState,
    onUploadPassport,
    onViewSamplePassport,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled,
    onRemovePassportClicked,
    onDownloadUploadedFile,
    getFileNameFromUrl
  } = useMemberPassportUpload();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Passport Upload</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin" style={{ marginRight: 30, marginLeft: 5 }}>
        <div className="col-xs-12" style={{ backgroundColor: 'lightblue', marginLeft: 10, marginBottom: 10, paddingBottom: 5, borderRadius: 10 }}>
          <ul style={{ padding: 20, color: 'darkslateblue' }}>
            <li>If a copy of your passport is already in the SWIMS database, please download it and review before uploading a new one.</li>
            <li>Please make sure that the image or document that you upload displays the upper and lower sections of your passport.</li>
            <li>You can view the sample passport for an example of what you should upload.</li>
          </ul>
        </div>
      </div>
      <form>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-bottom-margin usas-extra-top-margin">
            {fileState.attachmentUrl === '' || fileState.attachmentUrl.includes("Bad Request") ?
              <Fragment>
                <label htmlFor="announcement-file-upload" className={global.FileUpload}>
                  Upload Passport File
                </label>
                <input hidden id="announcement-file-upload" type="file" onChange={onUploadPassport} />
                &nbsp;(*.pdf) or (*.jpg)
              </Fragment>
              :
              <Fragment>
                <SecondaryButton onClick={onOpenRemoveFileModalClicked}>Remove Uploaded File</SecondaryButton>&nbsp;
                <PrimaryButton type="button" onClick={(event) => onDownloadUploadedFile(event)}>Download Uploaded File</PrimaryButton>
              </Fragment>}
          </div>
          {fileState.attachmentUrl.includes("Bad Request") === false &&
            <div className="usas-extra-bottom-margin" hidden={fileState.attachmentUrl === ''}>
              <div className="col-xs-12">
                <p><b>Uploaded File Name: </b> {getFileNameFromUrl(fileState.attachmentUrl)}</p>
              </div>
            </div>
          }
          {fileState.attachmentUrl.includes("Bad Request") === true &&
            <div className="col-xs-12">
              <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
            </div>
          }
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <ActionButton type="button" onClick={onViewSamplePassport}>{uiState.passportVisible ? 'Hide' : 'View'} Sample Passport</ActionButton>
          </div>
        </div>
        <div className="row" style={{ display: uiState.passportVisible ? 'inline' : 'none' }} >
          <div className="col-xs-12">
            <img src={`${import.meta.env.VITE_CDN_URL}/SamplePassport.png`} alt="Passport"/>&nbsp;
          </div>
        </div>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={removeFileModalState.modalTitle}
          displayPopUp={removeFileModalState.displayPopUp}
          onModalCanceled={onRemoveFileModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              <p><b>File Type:</b> Passport</p>
              {removeFileModalState.fileName?.includes("Bad Request")
                ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
                : <p><b>Uploaded File Name:</b> {getFileNameFromUrl(removeFileModalState.fileName)}</p>
              }
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <PrimaryButton type="button" onClick={onRemovePassportClicked}>Yes, Remove</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onRemoveFileModalCanceled}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={personNationalTeamAttachmentState.isArrayLoading} />
      </form>
    </Fragment >
  );
}

export default MemberPassportUpload;