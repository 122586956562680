import { Fragment } from 'react';

import useNTRegistrationLeftNav from './UseNTRegistrationLeftNav';

import NTRegistrationLoadingModal from '../dialogs/NTRegistrationLoadingModal';

import LeftNav from '../../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../../common/components/headings/Headings';

import style from '../../NTRegistration.module.css';

const LeftNavigationTitle = ({ objData }) => (
  <div className={style.RegFor}>
    <Headings.H5>Member Name:</Headings.H5>
    <Headings.H6>{objData.memberName || ''}</Headings.H6>
    <Headings.H5>Group Name:</Headings.H5>
    <Headings.H6>{objData.nationalTeamGroupName || ''}</Headings.H6>
  </div>
);

const NTRegistrationLeftNav = ({ viewTitle, children }) => {
  const {
    nationalTeamRegistrationLeftNavState
  } = useNTRegistrationLeftNav();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <Headings.H2 excludeBar={true} className={style.CenterAlign}>National Team Registration</Headings.H2>
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      {nationalTeamRegistrationLeftNavState.isLoaded === true ? (
        <LeftNav
          formattedTitle={<LeftNavigationTitle objData={nationalTeamRegistrationLeftNavState.objData || {}} />}
          state={nationalTeamRegistrationLeftNavState.arrayData || []}
          validateBeforeRedirect={false}>
          {viewTitle &&
            <div className='row usas-extra-top-margin'>
              <div className='col-xs-12'>
                <Headings.H3 excludeBar={true}>{viewTitle}</Headings.H3>
              </div>
            </div>
          }
          <div className='row usas-extra-top-margin'>
            <div className='col-xs-12'>
              {children}
            </div>
          </div>
        </LeftNav>
      ) : <NTRegistrationLoadingModal isLoading={true} />}
    </Fragment>
  );
};

export default NTRegistrationLeftNav;