import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import { createNewPersonBiosFormState, createNewPersonBiosArray } from "../../../components/forms/personBioInfo/PersonBioInfoFormUtils";
import validate from "../../../components/forms/personBioInfo/PersonBioInfoFormValidation";

import useBioEntryData from "../../../state/bioEntry/UseBioEntryData";
import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationProfileData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationProfile/UseNTRegistrationProfileData";

import useStatesCombobox from "../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox";
import useCountryCombobox from "../../../../common/components/comboboxes/countryCombobox/UseCountryCombobox";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";
import useSecurityData from "../../../../common/state/security/UseSecurityData";

import { LEFT_NAV_ITEM_NAME_BIO_INFO } from "../utils/NTRegistrationConstants";

const useNTRegistrationBioInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { userInfo } = useSecurityData();
  const { onNTRegistrationError, resetNationalTeamRegistrationState } = useNTRegistrationData();
  const { bioEntryState, getBioEntries } = useBioEntryData();
  const { nationalTeamRegistrationLeftNavState, refreshLeftNavState, updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationProfileState, getNTRegistrationProfile, putNTRegistrationProfile
  } = useNTRegistrationProfileData();
  const { getStateIdByName } = useStatesCombobox();
  const { getCountryIdByName } = useCountryCombobox();
  const [displayExitModalState, setDisplayExitModalState] = useState(false);

  const onExitClicked = (event) => {
    try {
      event?.preventDefault();
      setDisplayExitModalState(true);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onExitModalCancelClicked = (event) => {
    try {
      event?.preventDefault();
      setDisplayExitModalState(false);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onExitModalConfirmClicked = (event) => {
    try {
      event?.preventDefault();

      resetNationalTeamRegistrationState();

      navigate(navRoutes.NT_REGISTRATION_ENTRY.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onSubmitBioInfoForm = async (formState) => {
    try {
      const personId = nationalTeamRegistrationProfileState.objData.personId;
      const newPersonProfileObj = JSON.parse(JSON.stringify(nationalTeamRegistrationProfileState.objData));
      newPersonProfileObj.bios = createNewPersonBiosArray(formState, personId, nationalTeamRegistrationProfileState.objData.bios, bioEntryState.arrayData);

      updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_BIO_INFO, true);

      const putNTRegistrationProfileResult = await putNTRegistrationProfile(personId, newPersonProfileObj);

      if (putNTRegistrationProfileResult) {
        navigate(navRoutes.NT_REGISTRATION_CONTACTS?.route);
      }
    } catch (e) {
      updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_BIO_INFO, false);
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    async function loadBioEntries() {
      try {
        if (bioEntryState.isArrayLoaded !== true) {
          await getBioEntries();
        }
      } catch (e) {
        onNTRegistrationError(e);
      }
    }
    loadBioEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bioEntryState.isArrayLoaded]);

  useEffect(() => {
    async function loadNTRegistrationProfile() {
      try {
        if (nationalTeamRegistrationLeftNavState.isLoaded !== true) {
          if (location.state?.groupMemberRegistrationObj) {
            refreshLeftNavState(LEFT_NAV_ITEM_NAME_BIO_INFO, location.state.groupMemberRegistrationObj, userInfo);
          } else {
            throw new Error('Failed to load National Team Registration context');
          }
        } else if (nationalTeamRegistrationProfileState.isObjLoading !== true) {
          if (nationalTeamRegistrationProfileState.isObjLoaded !== true) {
            const getNTRegistrationProfileResult = await getNTRegistrationProfile(0);
            const isComplete = await getBioInfoIsComplete(getNTRegistrationProfileResult.objData?.bios || []);

            updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_BIO_INFO, isComplete);
          } else {
            const isComplete = await getBioInfoIsComplete(nationalTeamRegistrationProfileState.objData?.bios || []);

            updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_BIO_INFO, isComplete);
          }
        }
      } catch (e) {
        onNTRegistrationError(e);
      }
    }
    loadNTRegistrationProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamRegistrationProfileState.isObjLoaded, nationalTeamRegistrationLeftNavState.isLoaded]);

  async function getBioInfoIsComplete(personBiosArray) {
    const newFormState = createNewPersonBiosFormState(personBiosArray, getStateIdByName, getCountryIdByName);
    const errors = await validate(newFormState) || {};

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  }

  return {
    isLoading: nationalTeamRegistrationProfileState.isObjLoading,
    displayExitModal: displayExitModalState === true,
    personBiosArray: nationalTeamRegistrationProfileState.objData?.bios || [],
    onExitClicked,
    onSubmitBioInfoForm,
    onExitModalCancelClicked,
    onExitModalConfirmClicked
  };
};

export default useNTRegistrationBioInfo;