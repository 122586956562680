import { Fragment } from 'react';

import useNTRegistrationSponsorsDetail from './UseNTRegistrationSponsorsDetail';

import SponsorsEditForm from '../components/forms/sponsors/SponsorsEditForm';
import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import { formatDate } from '../../../../common/utils/DateFunctions';

const NTRegistrationSponsorsDetail = () => {
  const {
    isLoading,
    sponsorObj,
    state,
    onCancelClicked,
    onDupPopupCloseClicked,
    onSubmitSponsorsEditForm
  } = useNTRegistrationSponsorsDetail();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Edit Sponsor'}>
        {sponsorObj &&
          <SponsorsEditForm sponsorObj={sponsorObj} onSubmitFormCallback={onSubmitSponsorsEditForm} onSecondaryButtonClicked={onCancelClicked} />
        }
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={'Duplicate Sponsor Error'}
          displayPopUp={state.displayDupSponsorModal === true}
          onModalCanceled={onDupPopupCloseClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>Selected dates conflict with an existing sponsor ({state.dupSponsorObj?.sponsor?.sponsorName}, {formatDate(state.dupSponsorObj?.effectiveDate)} - {formatDate(state.dupSponsorObj?.expirationDate)})</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onDupPopupCloseClicked}>Close</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationSponsorsDetail;