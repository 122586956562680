import { Fragment } from 'react';

import SponsorsGridLarge from './SponsorsGridLarge';
import SponsorsGridSmall from './SponsorsGridSmall';

const SponsorsGrid = ({ isLoading, gridData, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <SponsorsGridLarge
      gridData={gridData}
      isLoading={isLoading}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked} />
    <SponsorsGridSmall
      gridData={gridData}
      isLoading={isLoading}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default SponsorsGrid;