import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useProfilePhotoData from '../../../state/profilePhoto/UseProfilePhotoData';
import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import Constants from '../../../../common/utils/Constants';

const PROFILE_PICTURE_BIO_ENTRY_ID = 10;
const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.jpg) file.';

const useProfilePhotoManagementDetail = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { personProfileState, putPersonProfile } = usePersonProfileData();
  const { profilePhotoUploadState, postProfilePhotoFile } = useProfilePhotoData();
  const [uiState, setUiState] = useState(getInitialUiState());

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_PROFILE_PHOTO?.route);
  };

  const onUploadPhotoClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setUiState({ ...uiState, tryRedirect: false, error: '' });

    if (e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = e.target.files[0].type;
      if (fileType === Constants.FILE_TYPE_JPG) {
        postProfilePhotoFile(file);
      }
      else {
        setUiState({ tryRedirect: false, error: FILE_TYPE_ERROR_MSG });
      }
    }
  };

  useEffect(() => {
    if (uiState.tryRedirect === true && personProfileState.isObjLoading === false && personProfileState.isSaving === false) {
      navigate(navRoutes.MEMBER_PROFILE_PHOTO?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personProfileState, uiState.tryRedirect]);

  useEffect(() => {
    if (profilePhotoUploadState.isObjLoaded === true) {
      const putObj = createPutPersonObject();
      const bio = putObj.bios?.find(x => x.bioEntryId === 10)

      if (bio === undefined || bio.textValue.includes("Bad Request") === false) {
        putPersonProfile(personProfileState.objData.personId, putObj);

        setUiState({
          ...uiState,
          tryRedirect: true,
          error: ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePhotoUploadState]);

  function createPutPersonObject() {
    const personToPut = JSON.parse(JSON.stringify(personProfileState.objData));
    let personBioArray = personToPut.bios;
    const index = personBioArray?.findIndex(e => e.bioEntryId === PROFILE_PICTURE_BIO_ENTRY_ID);

    let personBioObj = {};

    if (index === Constants.DEFAULT_ID) {
      //add
      personBioObj = {
        personId: personToPut.personId,
        bioEntryId: PROFILE_PICTURE_BIO_ENTRY_ID,
        textValue: profilePhotoUploadState.objData.uploadUrl,
        bioEntry: null
      };

      personBioArray.push(personBioObj);
      personToPut.bios = personBioArray;
    } else {
      //change photo
      personToPut.bios[index] = {
        ...personToPut.bios[index],
        textValue: profilePhotoUploadState.objData.uploadUrl,
        bioEntry: null
      };
    }

    return personToPut;
  }

  function getInitialUiState() {
    return { tryRedirect: false, error: '' };
  }

  return {
    personProfileState,
    profilePhotoUploadState,
    uiState,
    onUploadPhotoClicked,
    onCancelClicked
  };
};

export default useProfilePhotoManagementDetail;