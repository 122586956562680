import { useContext } from "react";

import NTRegistrationPersonData from "./NTRegistrationPersonData";
import { NTRegistrationPersonContext } from "./NTRegistrationPersonContextProvider";

const useNTRegistrationPersonData = () => {
  const [nationalTeamRegistrationPersonState, setNationalTeamRegistrationPersonState] = useContext(NTRegistrationPersonContext);

  const getNTRegistrationPerson = (personId) => {
    return NTRegistrationPersonData.getNTRegistrationPersonData(personId, nationalTeamRegistrationPersonState, setNationalTeamRegistrationPersonState);
  };

  const putNTRegistrationPerson = (personId, personNTObj) => {
    return NTRegistrationPersonData.putNTRegistrationPersonData(personId, personNTObj, nationalTeamRegistrationPersonState, setNationalTeamRegistrationPersonState);
  };

  return {
    nationalTeamRegistrationPersonState,
    getNTRegistrationPerson,
    putNTRegistrationPerson
  };
};

export default useNTRegistrationPersonData;