import useSponsorsAddForm from "./UseSponsorsAddForm";

import Dropdown from "../../../../../../common/components/dropdowns/Dropdown";
import DatePicker from "../../../../../../common/components/datepickers/DatePicker";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";

import global from '../../../../../../common/components/GlobalStyle.module.css';

const SponsorsAddForm = ({ submitButtonText = 'Add Sponsor', onSubmitFormCallback }) => {
  const {
    sponsorOptions,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  } = useSponsorsAddForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Sponsor"
            name="sponsorId"
            value={formState.sponsorId}
            error={errorState.sponsorId}
            message={errorState.sponsorId}
            isLoading={sponsorOptions.length === 0}
            options={sponsorOptions}
            onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'sponsorId', newValueLabel, 'sponsorName'); }}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Effective Date"
            name="effectiveDate"
            value={formState.effectiveDate}
            error={errorState.effectiveDate}
            message={errorState.effectiveDate}
            countOfYears={150}
            startYearOffset={-149}
            onChange={(value) => { updateFormState('effectiveDate', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Expiration Date"
            name="expirationDate"
            value={formState.expirationDate}
            error={errorState.expirationDate}
            message={errorState.expirationDate}
            countOfYears={150}
            startYearOffset={-140}
            onChange={(value) => { updateFormState('expirationDate', value); }} />
        </div>
      </div>
      {errorState.errorOnSubmitAction &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
        </div>
      </div>
    </form>
  );
};

export default SponsorsAddForm;