import { useState } from 'react';

import ProfilePhotoData from './ProfilePhotoData';

const useProfilePhotoData = () => {
  const [profilePhotoUploadState, setProfilePhotoUploadState] = useState(ProfilePhotoData.PROFILE_PHOTO_UPLOAD_INITIAL_STATE);
  const [profilePhotoDownloadState, setProfilePhotoDownloadState] = useState(ProfilePhotoData.PROFILE_PHOTO_DOWNLOAD_INITIAL_STATE)

  const postProfilePhotoFile = (file) => {
    ProfilePhotoData.postProfilePhotoFileData(file, profilePhotoUploadState, setProfilePhotoUploadState);
  };

  const getProfilePhotoFile = (fileUrl) => {
    return ProfilePhotoData.getProfilePhotoFileData(fileUrl, profilePhotoDownloadState, setProfilePhotoDownloadState);
  };

  const getFileNameFromUrl = (url) => {
    try {
      const fileNameWithDateTime = url.replace(ProfilePhotoData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return decodeURI(fileName) || '';
    } catch (e) {
      return url;
    }
  };

  return {
    profilePhotoUploadState,
    profilePhotoDownloadState,
    postProfilePhotoFile,
    getProfilePhotoFile,
    getFileNameFromUrl
  };
};

export default useProfilePhotoData;