import useFunFactsEditForm from "./UseFunFactsEditForm";

import Textarea from "../../../../../../common/components/inputs/Textarea";
import ReadOnly from "../../../../../../common/components/readOnly/ReadOnly";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../common/components/buttons/SecondaryButton";

import global from '../../../../../../common/components/GlobalStyle.module.css';

const FunFactsEditForm = ({ funFactObj, submitButtonText = 'Save', secondaryButtonText = 'Cancel',
  onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  } = useFunFactsEditForm(funFactObj, onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label="Fun Fact Name"
            name="funFactsId"
            value={funFactObj.funFacts?.funFactsName} />
        </div>
      </div>
      <div className='row'>
        <div className="col-xs-12">
          <Textarea
            className={global.Textarea}
            label="Fun Fact Description*"
            name="funFactsDescription"
            value={formState.funFactsDescription}
            error={errorState.funFactsDescription}
            message={errorState.funFactsDescription}
            onChange={(value) => { updateFormState('funFactsDescription', value) }} />
        </div>
      </div>
      {errorState.errorOnSubmitAction &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type='button' onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default FunFactsEditForm;