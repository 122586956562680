import { useEffect } from 'react';

import validate from './SponsorsAddFormValidation';

import useSponsorData from '../../../../../state/sponsor/UseSponsorData';
import useNTRegistrationData from '../../../../../state/nationalTeamRegistration/UseNTRegistrationData';

import { BLANK_DATE_STRING, DEFAULT_ID } from '../../../../../../common/utils/Constants';
import useForm from "../../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  sponsorId: DEFAULT_ID,
  sponsorName: '',
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING
};

const useSponsorsAddForm = (onSubmitFormCallback) => {
  const { onNTRegistrationError } = useNTRegistrationData();
  const { sponsorState } = useSponsorData();
  const { formState, errorState, isDirty, isSubmitting, setFormData, handleSubmit, updateFormState, onValueTextPairChanged
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try { // reset form
      if (isDirty === false && isSubmitting === false) {
        setFormData(INITIAL_FORM_STATE, true);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isSubmitting]);

  return {
    sponsorOptions: sponsorState.dataAsOptionsWithNames || [],
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  };
};

export default useSponsorsAddForm;