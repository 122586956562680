import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

const getNTRegistrationProfileData = (personId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);

  const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
  const url = `/Person/${personIdForUrl}/profile`;

  return api.executeObject ? api.executeObject(url, 'GET') : null;
};

const putNTRegistrationProfileData = (personId, personProfileObj, state, setState) => {
  if (personProfileObj) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
    const url = `/Person/${personIdForUrl}/profile`;

    return api.executeObject ? api.executeObject(url, 'PUT', personProfileObj) : null;
  }
};

const INITIAL_NATIONAL_TEAM_REGISTRATION_PERSON_PROFILE_STATE = {
  ...BASIC_INITIAL_STATE
};

const NTRegistrationProfileData = {
  INITIAL_NATIONAL_TEAM_REGISTRATION_PERSON_PROFILE_STATE,
  getNTRegistrationProfileData,
  putNTRegistrationProfileData
};

export default NTRegistrationProfileData;