import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const getNTGroupMemberRegistrationData = (personId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);

  const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
  const url = `/PersonNationalTeamGroup/Person/${personIdForUrl}`;

  return api.executeObject ? api.executeObject(url, 'GET') : null;
};

const putNTGroupMemberRegistrationData = (personId, groupMemberRegistrationObj, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState, {}); //TODO fake endpoint

  const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
  const url = `/PersonNationalTeamGroup/Person/${personIdForUrl}`;

  return api.executeObject ? api.executeObject(url, 'PUT', groupMemberRegistrationObj) : null;
};

const INITIAL_NATIONAL_TEAM_REGISTRATION_STATE = {
  isError: false,
  error: {},
  errorTitle: '',
  errorMessage: ''
};

const INITIAL_GROUP_MEMBER_REGISTRATION_STATE = {
  ...BASIC_INITIAL_STATE
};

const NTRegistrationData = {
  INITIAL_NATIONAL_TEAM_REGISTRATION_STATE,
  INITIAL_GROUP_MEMBER_REGISTRATION_STATE,
  getNTGroupMemberRegistrationData,
  putNTGroupMemberRegistrationData
};

export default NTRegistrationData;