import { useEffect } from 'react';

import validate from './SocialMediaEditFormValidation';

import useNTRegistrationData from '../../../../../state/nationalTeamRegistration/UseNTRegistrationData';

import useForm from "../../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  socialHandle: ''
};

const useSocialMediaEditForm = (socialMediaObj, onSubmitFormCallback) => {
  const { onNTRegistrationError } = useNTRegistrationData();
  const { formState, errorState, setFormData, handleSubmit, updateFormState
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try {
      if (socialMediaObj) {
        setFormData({
          ...INITIAL_FORM_STATE,
          socialHandle: socialMediaObj.socialHandle
        }, true);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialMediaObj]);
  
  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  };
};

export default useSocialMediaEditForm;