import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationContactsData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationContacts/UseNTRegistrationContactsData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_CONTACTS } from "../utils/NTRegistrationConstants";

const INITIAL_STATE = {
  contactObj: null,
  contactAddressObj: null
};

const useNTRegistrationContactsAddressDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { onNTRegistrationError } = useNTRegistrationData();
  const { updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationContactsState, putNTRegistrationContacts } = useNTRegistrationContactsData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCancelClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_CONTACTS?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onSubmitContactForm = async (formState) => {
    try {
      const personId = nationalTeamRegistrationContactsState.objData.personId;
      const newPersonNTContactsObj = JSON.parse(JSON.stringify(nationalTeamRegistrationContactsState.objData));
      const targetContactIndex = newPersonNTContactsObj.personNationalTeamRegistrationContact.findIndex((x) => x.personNationalTeamRegistrationContactId === state.contactObj.personNationalTeamRegistrationContactId);

      if (state.contactAddressObj) {
        const targetAddressIndex = newPersonNTContactsObj.personNationalTeamRegistrationContact[targetContactIndex].personNationalTeamRegistrationContactAddress.findIndex((x) => x.personNationalTeamRegistrationContactAddressId === state.contactAddressObj.personNationalTeamRegistrationContactAddressId)

        newPersonNTContactsObj.personNationalTeamRegistrationContact[targetContactIndex].personNationalTeamRegistrationContactAddress[targetAddressIndex] = createPersonNationalTeamContactAddress(formState, newPersonNTContactsObj.personNationalTeamRegistrationContact[targetContactIndex].personNationalTeamRegistrationContactId, newPersonNTContactsObj.personNationalTeamRegistrationContact[targetContactIndex].personNationalTeamRegistrationContactAddress[targetAddressIndex]);
      } else {
        newPersonNTContactsObj.personNationalTeamRegistrationContact[targetContactIndex].personNationalTeamRegistrationContactAddress.push(createPersonNationalTeamContactAddress(formState, newPersonNTContactsObj.personNationalTeamRegistrationContact[targetContactIndex].personNationalTeamRegistrationContactId));
      }

      const putNTRegistrationPersonResult = await putNTRegistrationContacts(personId, newPersonNTContactsObj);

      if (putNTRegistrationPersonResult) {
        navigate(navRoutes.NT_REGISTRATION_CONTACTS?.route);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    try {
      updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_CONTACTS);

      if (location.state?.contactObj && location.state?.contactAddressObj) {
        setState({ ...state, contactObj: location.state.contactObj, contactAddressObj: location.state.contactAddressObj });
      } else if (location.state?.contactObj) {
        setState({ ...state, contactObj: location.state.contactObj });
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createPersonNationalTeamContactAddress(formState, personNationalTeamRegistrationContactId, existingPersonNationalTeamContactAddress = undefined) {
    if (existingPersonNationalTeamContactAddress) {
      return {
        ...existingPersonNationalTeamContactAddress,
        address: {
          ...existingPersonNationalTeamContactAddress.address,
          address1: formState.address1.trim(),
          address2: formState.address2.trim(),
          city: formState.city.trim(),
          postalCode: formState.postalCode.trim(),
          stateCode: formState.stateCode,
          countryCode: formState.countryCode,
        },
        personNationalTeamRegistrationContactAddressType: formState.addressType.map((x) => { return { personNationalTeamRegistrationContactAddressId: existingPersonNationalTeamContactAddress.personNationalTeamRegistrationContactAddressId, addressTypeId: x.id } })
      };
    } else {
      return {
        personNationalTeamRegistrationContactId,
        address: {
          address1: formState.address1.trim(),
          address2: formState.address2.trim(),
          city: formState.city.trim(),
          postalCode: formState.postalCode.trim(),
          stateCode: formState.stateCode,
          countryCode: formState.countryCode,
        },
        personNationalTeamRegistrationContactAddressType: formState.addressType.map((x) => { return { addressTypeId: x.id } })
      };
    }
  }

  return {
    isLoading: nationalTeamRegistrationContactsState.isObjLoading,
    contactName: state.contactObj ? `${state.contactObj.firstName} ${state.contactObj.lastName}` : '',
    contactAddressObj: state.contactAddressObj || null,
    onCancelClicked,
    onSubmitContactForm
  };
};

export default useNTRegistrationContactsAddressDetail;