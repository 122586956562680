import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const postNTAttachmentData = (attachmentObj, state, setState) => {
  if (attachmentObj) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonNationalAttachment`;

    return api?.executeObject ? api.executeObject(url, 'POST', attachmentObj) : null;
  }
};

const postNTAttachmentFileData = (file, state, setState) => {
  if (file) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/nationalTeamAttachment`;
    const formData = new FormData();
    formData.append("name", file?.name || '');
    formData.append("file", file);

    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const postNTAttachmentFileUpdateData = (file, oldAttachmentUrl, state, setState) => {
  if (file && oldAttachmentUrl) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/nationalTeamAttachment/update`;
    const formData = new FormData();
    formData.append("name", file?.name || '');
    formData.append("file", file);
    formData.append("oldFileUrl", oldAttachmentUrl);

    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const getNTAttachmentFileData = (attachmentUrl, state, setState) => {
  if (attachmentUrl) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/nationalTeamAttachment?fileUrl=${attachmentUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const getNTAttachmentsByPersonIdData = (personId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);

  const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
  const url = `/PersonNationalAttachment/Person/${personIdForUrl}`;

  return api.executeArray ? api.executeArray(url, 'GET') : null;
};

const putNTAttachmentData = (attachmentId, attachmentObj, state, setState) => {
  if (attachmentId > 0 && attachmentObj) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const attachmentIdForUrl = attachmentId ? encodeURIComponent(attachmentId) : 'NaN';
    const url = `/PersonNationalAttachment/${attachmentIdForUrl}`;

    return api.executeObject ? api.executeObject(url, 'PUT', attachmentObj) : null;
  }
};

const deleteNTAttachmentData = (attachmentId, state, setState) => {
  if (attachmentId > 0) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const attachmentIdForUrl = attachmentId ? encodeURIComponent(attachmentId) : 'NaN';
    const url = `/PersonNationalAttachment/${attachmentIdForUrl}`;

    return api.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const deleteNTAttachmentFileData = (attachmentUrl, state, setState) => {
  if (attachmentUrl) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const url = `/PersonFiles/nationalTeamAttachment?personNationalAttachmentUrl=${attachmentUrl}`;
    
    return api.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const INITIAL_NATIONAL_TEAM_ATTACHMENT_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_NATIONAL_TEAM_ATTACHMENT_UPLOAD_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_NATIONAL_TEAM_ATTACHMENT_DOWNLOAD_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_NATIONAL_TEAM_ATTACHMENT_UPDATE_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_NATIONAL_TEAM_ATTACHMENT_DELETE_STATE = {
  ...BASIC_INITIAL_STATE
};

const NTAttachmentData = {
  INITIAL_NATIONAL_TEAM_ATTACHMENT_STATE,
  INITIAL_NATIONAL_TEAM_ATTACHMENT_UPLOAD_STATE,
  INITIAL_NATIONAL_TEAM_ATTACHMENT_DOWNLOAD_STATE,
  INITIAL_NATIONAL_TEAM_ATTACHMENT_UPDATE_STATE,
  INITIAL_NATIONAL_TEAM_ATTACHMENT_DELETE_STATE,
  postNTAttachmentData,
  postNTAttachmentFileData,
  postNTAttachmentFileUpdateData,
  getNTAttachmentFileData,
  getNTAttachmentsByPersonIdData,
  putNTAttachmentData,
  deleteNTAttachmentData,
  deleteNTAttachmentFileData
};

export default NTAttachmentData;