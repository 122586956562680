
import { DEFAULT_ID } from "../../../../common/utils/Constants";
import { LEFT_NAV_ITEM_NAME_ATTACHMENTS, LEFT_NAV_ITEM_NAME_BIO_INFO, LEFT_NAV_ITEM_NAME_COACH, LEFT_NAV_ITEM_NAME_CONTACTS, LEFT_NAV_ITEM_NAME_FREQUENT_FLYER, LEFT_NAV_ITEM_NAME_FUN_FACTS, LEFT_NAV_ITEM_NAME_SOCIAL_MEDIA, LEFT_NAV_ITEM_NAME_SPONSORS, LEFT_NAV_ITEM_NAME_SUBMIT, LEFT_NAV_ITEM_NAME_SUPPORT_STAFF } from "../../../views/nationalTeamRegistration/utils/NTRegistrationConstants";

const INITIAL_OBJ_DATA = {
  personNationalTeamGroupId: DEFAULT_ID,
  nationalTeamGroupId: DEFAULT_ID,
  nationalTeamGroupName: '',
  nationalTeamGroupCode: '',
  groupAttendeeTypeId: DEFAULT_ID,
  attendeeType: '',
  groupRoleTypeId: DEFAULT_ID,
  roleType: '',
  memberName: '',
  inviteStatus: '',
  isConfirmed: true
};

const INITIAL_ARRAY_DATA = [
  {
    name: LEFT_NAV_ITEM_NAME_BIO_INFO,
    displayName: 'Biographical Info',
    path: '/nationalteamregistration/biographicalinfo',
    isCurrent: true,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true,
    prerequisites: []
  }, {
    name: LEFT_NAV_ITEM_NAME_CONTACTS,
    displayName: 'Contacts',
    path: '/nationalteamregistration/contacts',
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true,
    prerequisites: []
  }, {
    name: LEFT_NAV_ITEM_NAME_SUPPORT_STAFF,
    displayName: 'Support Staff',
    path: '/nationalteamregistration/supportstaff',
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true,
    prerequisites: []
  }, {
    name: LEFT_NAV_ITEM_NAME_FREQUENT_FLYER,
    displayName: 'Frequent Flyer',
    path: '/nationalteamregistration/frequentflyer',
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true,
    prerequisites: []
  }, {
    name: LEFT_NAV_ITEM_NAME_COACH,
    displayName: 'Coach',
    path: '/nationalteamregistration/coach',
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true,
    prerequisites: []
  }, {
    name: LEFT_NAV_ITEM_NAME_SPONSORS,
    displayName: 'Sponsors',
    path: '/nationalteamregistration/sponsors',
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true,
    prerequisites: []
  }, {
    name: LEFT_NAV_ITEM_NAME_SOCIAL_MEDIA,
    displayName: 'Social Media',
    path: '/nationalteamregistration/socialmedia',
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true,
    prerequisites: []
  }, {
    name: LEFT_NAV_ITEM_NAME_FUN_FACTS,
    displayName: 'Fun Facts',
    path: '/nationalteamregistration/funfacts',
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true,
    prerequisites: []
  }, {
    name: LEFT_NAV_ITEM_NAME_ATTACHMENTS,
    displayName: 'Attachments',
    path: '/nationalteamregistration/attachments',
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true,
    prerequisites: []
  }, {
    name: LEFT_NAV_ITEM_NAME_SUBMIT,
    displayName: 'Submit',
    path: '/nationalteamregistration/submit',
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false,
    prerequisites: [LEFT_NAV_ITEM_NAME_BIO_INFO, LEFT_NAV_ITEM_NAME_COACH, LEFT_NAV_ITEM_NAME_CONTACTS,
      LEFT_NAV_ITEM_NAME_FREQUENT_FLYER, LEFT_NAV_ITEM_NAME_FUN_FACTS, LEFT_NAV_ITEM_NAME_ATTACHMENTS,
      LEFT_NAV_ITEM_NAME_SOCIAL_MEDIA, LEFT_NAV_ITEM_NAME_SPONSORS, LEFT_NAV_ITEM_NAME_SUPPORT_STAFF
    ]
  }
];

const NTRegistrationLeftNavData = {
  INITIAL_OBJ_DATA,
  INITIAL_ARRAY_DATA
};

export default NTRegistrationLeftNavData;