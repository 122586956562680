import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationPersonData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationPerson/UseNTRegistrationPersonData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_SPONSORS } from "../utils/NTRegistrationConstants";

const INITIAL_STATE = {
  deleteSponsorObj: {},
  dupSponsorObj: {},
  displayDeletePopup: false,
  displayDupSponsorModal: false,
};

const useNTRegistrationSponsors = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { onNTRegistrationError } = useNTRegistrationData();
  const { updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationPersonState, getNTRegistrationPerson, putNTRegistrationPerson } = useNTRegistrationPersonData()
  const [state, setState] = useState(INITIAL_STATE);

  const onContinueClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_SOCIAL_MEDIA?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_COACH?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onEditSponsorClicked = (event, sponsorObj) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_SPONSORS_DETAIL?.route, { state: { sponsorObj } });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onSubmitSponsorsAddForm = async (formState) => {
    try {
      const dupSponsorObj = findDuplicateSponsor(formState, nationalTeamRegistrationPersonState.objData.sponsors);

      if (dupSponsorObj) {
        setState({
          ...state,
          displayDupSponsorModal: true,
          dupSponsorObj
        });
      } else {
        const personId = nationalTeamRegistrationPersonState.objData.personId;
        const newPersonNTObj = JSON.parse(JSON.stringify(nationalTeamRegistrationPersonState.objData));
        newPersonNTObj.sponsors.push({
          personId,
          sponsorId: formState.sponsorId,
          effectiveDate: formState.effectiveDate,
          expirationDate: formState.expirationDate
        });

        await putNTRegistrationPerson(personId, newPersonNTObj);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeleteSponsorClicked = (event, sponsorObj) => {
    try {
      event?.preventDefault();
      setState({
        ...state,
        displayDeletePopup: true,
        deleteSponsorObj: sponsorObj
      });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeletePopupConfirmClicked = async (event) => {
    try {
      event?.preventDefault();
      const personId = nationalTeamRegistrationPersonState.objData.personId;
      const newPersonNTObj = JSON.parse(JSON.stringify(nationalTeamRegistrationPersonState.objData));
      const targetIndex = newPersonNTObj.sponsors.findIndex((x) => x.personSponsorId === state.deleteSponsorObj.personSponsorId);
      newPersonNTObj.sponsors.splice(targetIndex, 1);

      setState({
        ...state,
        displayDeletePopup: false,
        deleteSponsorObj: {}
      });

      await putNTRegistrationPerson(personId, newPersonNTObj);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onPopupCloseClicked = (event) => {
    try {
      event?.preventDefault();
      setState({ ...INITIAL_STATE });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    async function loadNTRegistrationPerson() {
      try {
        updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_SPONSORS, true);

        if (nationalTeamRegistrationPersonState.isObjLoaded !== true) {
          await getNTRegistrationPerson(0);
        }
      } catch (e) {
        onNTRegistrationError(e);
      }
    }
    loadNTRegistrationPerson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamRegistrationPersonState.isObjLoaded]);

  function findDuplicateSponsor(formState, existingSponsors) {
    const effectiveDate = new Date(formState.effectiveDate);
    const expirationDate = new Date(formState.expirationDate);

    for (const sponsor of existingSponsors) {
      if (formState.sponsorId === sponsor.sponsorId) {
        const existingEffectiveDate = new Date(sponsor.effectiveDate);
        const existingExpirationDate = new Date(sponsor.expirationDate);

        if (effectiveDate <= existingExpirationDate && expirationDate >= existingEffectiveDate) {
          return sponsor;
        }
      }
    }

    return null;
  }

  return {
    isLoading: nationalTeamRegistrationPersonState.isObjLoading,
    gridData: nationalTeamRegistrationPersonState.objData?.sponsors || [],
    state,
    onContinueClicked,
    onBackClicked,
    onEditSponsorClicked,
    onSubmitSponsorsAddForm,
    onDeleteSponsorClicked,
    onDeletePopupConfirmClicked,
    onPopupCloseClicked
  };
};

export default useNTRegistrationSponsors;