import { Fragment } from "react";

import useNTContactAddressForm from "./UseNTContactAddressForm";

import AddressTypeMultiselect from "../../multiSelect/AddressTypeMultiselect";

import Input from "../../../../common/components/inputs/Input";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import AddressValidation from "../../../../common/components/addressValidation/AddressValidation";
import StatesCombobox from "../../../../common/components/comboboxes/statesCombobox/StatesCombobox";
import CountryCombobox from "../../../../common/components/comboboxes/countryCombobox/CountryCombobox";

import global from '../../../../common/components/GlobalStyle.module.css';

const NTContactAddressForm = ({ contactAddressObj = undefined, contactAddressTypesArray = undefined, submitButtonText = 'Save', secondaryButtonText = 'Cancel',
  onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    isValidating,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onCountryChanged,
    onValueTextPairChanged,
    setFormData,
    isCountryUSA,
    isCountryMexico,
    isCountryCanada
  } = useNTContactAddressForm(contactAddressObj, contactAddressTypesArray, onSubmitFormCallback, onSecondaryButtonClicked);

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <AddressTypeMultiselect
              label="Address Type*"
              name="addressType"
              value={formState.addressType}
              error={errorState.addressType}
              message={errorState.addressType}
              showOnlyNationalTeamTypes={true}
              onChange={(value) => { updateFormState('addressType', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Address Line 1*"
              name="address1"
              value={formState.address1}
              error={errorState.address1}
              message={errorState.address1}
              onChange={(value) => { updateFormState('address1', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Address Line 2"
              name="address2"
              value={formState.address2}
              error={errorState.address2}
              message={errorState.address2}
              onChange={(value) => { updateFormState('address2', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="City*"
              name="city"
              value={formState.city}
              error={errorState.city}
              message={errorState.city}
              onChange={(value) => { updateFormState('city', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Zip Code*"
              name="postalCode"
              value={formState.postalCode}
              error={errorState.postalCode}
              message={errorState.postalCode}
              onChange={(value) => { updateFormState('postalCode', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <CountryCombobox
              label="Country*"
              name="countryCode"
              valueToMatch={formState.countryName}
              error={errorState.countryCode}
              message={errorState.countryCode}
              onChange={(newValue, newValueLabel) => { onCountryChanged(newValue, newValueLabel) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            {(isCountryUSA === true || isCountryMexico === true || isCountryCanada === true) &&
              <StatesCombobox
                label="State*"
                name="stateCode"
                valueToMatch={formState.stateName}
                error={errorState.stateCode}
                message={errorState.stateCode}
                onChange={(id, name) => { onValueTextPairChanged(id, 'stateCode', name, 'stateName'); }}
                isUSA={isCountryUSA === true}
                isMexico={isCountryMexico === true}
                isCanada={isCountryCanada === true} />
            }
          </div>
        </div>
        <AddressValidation formState={formState} setFormData={setFormData} />
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>
        }
        {errorState.formError &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.formError || ''}</p>
            </div>
          </div>
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        title="Validating..."
        widthPct={50}
        maxWidth={250}
        displayPopUp={isValidating === true} />
    </Fragment>
  );
};

export default NTContactAddressForm;