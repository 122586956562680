import { Fragment } from 'react';

import HideIcon from '../../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../../common/components/icons/ShowIcon';
import EditIcon from '../../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const DetailTable = ({ funFactDescription }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Fun Fact Description</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{funFactDescription || ''}</td>
      </tr>
    </tbody>
  </table>
);

const GridRow = ({ funFact, expandedId, onEditClicked, onDeleteClicked, onExpandClicked }) => (
  <Fragment>
    <tr>
      <td>{funFact.funFacts?.funFactsName || ''}</td>
      <td className='pull-right'>
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditClicked(e, funFact)}>
          <EditIcon />
        </button>
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteClicked(e, funFact)}>
          <DeleteIcon />
        </button>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onExpandClicked(e, funFact.personFunFactsId)}>
          {expandedId === funFact.personFunFactsId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {expandedId === funFact.personFunFactsId &&
      <tr className={global.Expanded}>
        <td colSpan="2">
          <DetailTable funFactDescription={funFact.textValue} />
        </td>
      </tr>
    }
  </Fragment>
);

const FunFactsGridLarge = ({ gridData, isLoading, expandedId, onEditClicked, onDeleteClicked, onExpandClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Fun Fact Name</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="2">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((funFact, i) => <GridRow key={i} funFact={funFact} expandedId={expandedId} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} onExpandClicked={onExpandClicked} />)
          : <tr><td colSpan="2">No Fun Facts</td></tr>
      }
    </tbody>
  </table>
);

export default FunFactsGridLarge;