import BaseMemberContextProvider from '../../state/member/baseMember/BaseMemberContextProvider';

import BasePersonContextProvider from '../../../common/state/personBase/BasePersonContextProvider';
import AddressTypeContextProvider from '../../../common/state/addressType/AddressTypeContextProvider';
import SearchMemberContextProvider from '../../../common/state/searchMember/SearchMemberContextProvider';
import ReportPeriodSeasonContextProvider from '../../../common/state/reportPeriodSeason/ReportPeriodSeasonContextProvider';
import GroupPersonCoachesContextProvider from '../../../common/state/groupPersonCoaches/GroupPersonCoachesContextProvider';

const MemberContextProviders = ({ children }) => {
  return (
    <AddressTypeContextProvider>
      <SearchMemberContextProvider>
        <BasePersonContextProvider>
          <BaseMemberContextProvider>
            <ReportPeriodSeasonContextProvider>
              <GroupPersonCoachesContextProvider>
                {children}
              </GroupPersonCoachesContextProvider>
            </ReportPeriodSeasonContextProvider>
          </BaseMemberContextProvider>
        </BasePersonContextProvider>
      </SearchMemberContextProvider>
    </AddressTypeContextProvider>
  );
};

export default MemberContextProviders;