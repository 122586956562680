import useNTSupportStaffAddForm from './UseNTSupportStaffAddForm';

import Input from '../../../../common/components/inputs/Input';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import InternationalPhoneInput from '../../../../common/components/inputs/InternationalPhoneInput';

const NTSupportStaffAddForm = ({ submitButtonText = 'Add Support Staff', secondaryButtonText = 'Cancel',
  onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    supportStaffTypeOptions,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  } = useNTSupportStaffAddForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="First Name*"
            name="firstName"
            value={formState.firstName}
            error={errorState.firstName}
            message={errorState.firstName}
            onChange={(value) => { updateFormState('firstName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Last Name*"
            name="lastName"
            value={formState.lastName}
            error={errorState.lastName}
            message={errorState.lastName}
            onChange={(value) => { updateFormState('lastName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Support Staff Role*"
            name="supportStaffTypeId"
            value={formState.supportStaffTypeId}
            error={errorState.supportStaffTypeId}
            message={errorState.supportStaffTypeId}
            options={supportStaffTypeOptions}
            isLoading={supportStaffTypeOptions.length === 0}
            onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'supportStaffTypeId', newValueLabel, 'supportStaffTypeName') }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Email"
            name="email"
            value={formState.email}
            error={errorState.email}
            message={errorState.email}
            onChange={(value) => { updateFormState('email', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <InternationalPhoneInput
            label="Phone Number"
            name="phoneNumber"
            value={formState.phoneNumber}
            error={errorState.phoneNumber}
            message={errorState.phoneNumber}
            onChange={(value) => updateFormState('phoneNumber', value)} />
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Effective Date*"
            name="effectiveDate"
            value={formState.effectiveDate}
            error={errorState.effectiveDate}
            message={errorState.effectiveDate}
            countOfYears={150}
            startYearOffset={-149}
            onChange={(value) => { updateFormState('effectiveDate', value); }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <YesNoSwitch
            label={'No Expiration Date?'}
            name={'hasNoExpirationDate'}
            checked={formState.hasNoExpirationDate === true}
            error={formState.hasNoExpirationDate}
            message={formState.hasNoExpirationDate}
            onChange={(value) => updateFormState('hasNoExpirationDate', value)} />
        </div>
        {formState.hasNoExpirationDate === false &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Expiration Date*"
              name="expirationDate"
              value={formState.expirationDate}
              error={errorState.expirationDate}
              message={errorState.expirationDate}
              countOfYears={150}
              startYearOffset={-140}
              onChange={(value) => { updateFormState('expirationDate', value); }} />
          </div>
        }
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
}

export default NTSupportStaffAddForm;
