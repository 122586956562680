import { Fragment } from 'react';

import useNTRegistrationSubmit from './UseNTRegistrationSubmit';

import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

const NTRegistrationSubmit = () => {
  const {
    isLoading,
    displayExitModal,
    acknowledgementText,
    onExitRegistrationClicked,
    onSubmitClicked,
    onBackClicked
  } = useNTRegistrationSubmit();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Submit'}>
        <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
          <div className='col-xs-12'>
            <b>By submitting this National Team Registration you agree to the following:</b>
            <p>{acknowledgementText}</p>
          </div>
        </div>
        <div className="row pull-right usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={onSubmitClicked}>Submit</PrimaryButton>
          </div>
        </div>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={'Registration Submitted'}
          displayPopUp={displayExitModal === true}
          onModalCanceled={onExitRegistrationClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>Your registration was successfully submitted.</p>
            </div>
            <div className="col-xs-12">
              <p>Click &apos;Exit Registration&apos; to return to your dashboard.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onExitRegistrationClicked}>Exit Registration</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationSubmit;