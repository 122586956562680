import { useState } from 'react';

import ErrorLoggerData from './ErrorLoggerData';

const useErrorLoggerData = () => {
  const [errorLoggerState, setErrorLoggerState] = useState(ErrorLoggerData.INITIAL_STATE);

  const postErrorLog = (errorObj) => {
    ErrorLoggerData.postErrorLogData(errorObj, errorLoggerState, setErrorLoggerState);
  };

  return {
    errorLoggerState,
    postErrorLog
  };
};

export default useErrorLoggerData;