import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationProfileData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationProfile/UseNTRegistrationProfileData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_SOCIAL_MEDIA } from "../utils/NTRegistrationConstants";

const INITIAL_STATE = {
  deleteSocialMediaObj: {},
  displayDeletePopup: false
};

const useNTRegistrationSocialMedia = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { onNTRegistrationError } = useNTRegistrationData();
  const { updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationProfileState, getNTRegistrationProfile, putNTRegistrationProfile } = useNTRegistrationProfileData()
  const [state, setState] = useState(INITIAL_STATE);

  const onContinueClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_FUN_FACTS?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_SPONSORS?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onEditSocialMediaClicked = (event, socialMediaObj) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_SOCIAL_MEDIA_DETAIL?.route, { state: { socialMediaObj } });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onSubmitSocialMediaAddForm = async (formState) => {
    try {
      const personId = nationalTeamRegistrationProfileState.objData.personId;
      const newPersonProfileObj = JSON.parse(JSON.stringify(nationalTeamRegistrationProfileState.objData));
      newPersonProfileObj.socialMedia.push({
        personId,
        socialMediaTypeId: formState.socialMediaTypeId,
        socialHandle: formState.socialHandle.trim()
      });

      await putNTRegistrationProfile(personId, newPersonProfileObj);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeleteSocialMediaClicked = (event, socialMediaObj) => {
    try {
      event?.preventDefault();
      setState({
        ...state,
        displayDeletePopup: true,
        deleteSocialMediaObj: socialMediaObj
      });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeletePopupConfirmClicked = async (event) => {
    try {
      event?.preventDefault();
      const personId = nationalTeamRegistrationProfileState.objData.personId;
      const newPersonProfileObj = JSON.parse(JSON.stringify(nationalTeamRegistrationProfileState.objData));
      const targetIndex = newPersonProfileObj.socialMedia.findIndex((x) => x.personSocialMediaId === state.deleteSocialMediaObj.personSocialMediaId);
      newPersonProfileObj.socialMedia.splice(targetIndex, 1);

      setState({
        ...state,
        displayDeletePopup: false,
        deleteSocialMediaObj: {}
      });

      await putNTRegistrationProfile(personId, newPersonProfileObj);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeletePopupCancelClicked = (event) => {
    try {
      event?.preventDefault();
      setState({
        ...state,
        displayDeletePopup: false,
        deleteSocialMediaObj: {}
      });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    async function loadNTRegistrationProfile() {
      try {
        updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_SOCIAL_MEDIA, true);

        if (nationalTeamRegistrationProfileState.isObjLoaded !== true) {
          await getNTRegistrationProfile(0);
        }
      } catch (e) {
        onNTRegistrationError(e);
      }
    }
    loadNTRegistrationProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamRegistrationProfileState.isObjLoaded]);

  return {
    isLoading: nationalTeamRegistrationProfileState.isObjLoading,
    gridData: nationalTeamRegistrationProfileState.objData?.socialMedia || [],
    state,
    onContinueClicked,
    onBackClicked,
    onEditSocialMediaClicked,
    onSubmitSocialMediaAddForm,
    onDeleteSocialMediaClicked,
    onDeletePopupConfirmClicked,
    onDeletePopupCancelClicked
  };
};

export default useNTRegistrationSocialMedia;