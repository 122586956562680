import { useEffect } from 'react';

import validate from './SponsorsEditFormValidation';

import useNTRegistrationData from '../../../../../state/nationalTeamRegistration/UseNTRegistrationData';

import useForm from "../../../../../../common/utils/UseForm";
import { formatDate } from '../../../../../../common/utils/DateFunctions';
import { BLANK_DATE_STRING } from '../../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING
};

const useSponsorsEditForm = (sponsorObj, onSubmitFormCallback) => {
  const { onNTRegistrationError } = useNTRegistrationData();
  const { formState, errorState, setFormData, handleSubmit, updateFormState
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try {
      if (sponsorObj) {
        setFormData({
          ...INITIAL_FORM_STATE,
          effectiveDate: sponsorObj.effectiveDate ? formatDate(sponsorObj.effectiveDate) : BLANK_DATE_STRING,
          expirationDate: sponsorObj.expirationDate ? formatDate(sponsorObj.expirationDate) : BLANK_DATE_STRING
        }, true);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorObj]);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  };
};

export default useSponsorsEditForm;