import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const BASE_UPLOAD_URL = import.meta.env.VITE_MEMBER_DOCS_PROFILE_PICS_BASE_UPLOAD_URL;

const postProfilePhotoFileData = (file, state, setState) => {
  if (file) {
    const formData = new FormData();
    formData.append("name", file?.name || '');
    formData.append("file", file);
    
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/profilePhoto`;
    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const getProfilePhotoFileData = (fileUrl, state, setState) => {
  if (fileUrl) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/profilePhoto?fileUrl=${fileUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const PROFILE_PHOTO_UPLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PROFILE_PHOTO_DOWNLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonFilesProfilePhotoData = {
  BASE_UPLOAD_URL,
  PROFILE_PHOTO_UPLOAD_INITIAL_STATE,
  PROFILE_PHOTO_DOWNLOAD_INITIAL_STATE,
  postProfilePhotoFileData,
  getProfilePhotoFileData
};

export default PersonFilesProfilePhotoData;