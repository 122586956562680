const DeleteAttachmentModal = ({ modalState }) => {
  return (
    <div className='row'>
      <p className='col-xs-12 col-sm-6'><strong>File Type: </strong> &nbsp;{modalState?.fileTypeName}</p>
      <p className='col-xs-12 col-sm-6'><strong>File Name:</strong>&nbsp;{modalState?.fileName}</p>
      <p className='col-xs-12'><strong>File Description: </strong> &nbsp;{modalState?.attachmentDescription}</p>
      <p className='col-xs-12'>Are you sure you want to delete this attachment?</p>
    </div>
  );
};

export default DeleteAttachmentModal;