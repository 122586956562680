import { isValidName, isValidDate, isValidUSOrCanadianPhoneNumber, isValidMexicanPhoneNumber, isValidEmail } from '../../../../common/utils/validation';
import { BLANK_DATE_STRING, DEFAULT_PHONE_NUMBER_AREA_CODE, DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO } from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};
  const effectiveDate = new Date(formState.effectiveDate);
  const expirationDate = new Date(formState.expirationDate);

  // Support Staff Role
  if (formState.supportStaffTypeId < 0) {
    errors.supportStaffTypeId = 'Support Staff Role is required';
  }

  // First Name
  if (!formState.firstName.trim()) {
    errors.firstName = 'First Name is required'
  } else if (!isValidName(formState.firstName.trim())) {
    errors.firstName = 'First Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  // Last Name
  if (!formState.lastName.trim()) {
    errors.lastName = 'Last Name is required'
  } else if (!isValidName(formState.lastName.trim())) {
    errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  // Email
  if (formState.email.trim() !== '') {
    if (!isValidEmail(formState.email.trim())) {
      errors.email = 'Email must be in a valid format';
    } else if (formState.email.trim().length > 512) {
      errors.email = 'Email cannot exceed 512 characters'
    }
  }

  // Phone Number
  if (formState.phoneNumber.trim() !== '' && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE
    && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO) {
    if (!isValidUSOrCanadianPhoneNumber(formState.phoneNumber) && !isValidMexicanPhoneNumber(formState.phoneNumber)) {
      errors.phoneNumber = 'Must enter a valid US, Canadian, or Mexican phone number in +1/+52 (XXX) XXX-XXXX format'
    }
  }

  // Effective Date
  if (formState.effectiveDate === BLANK_DATE_STRING) {
    errors.effectiveDate = 'Effective Date is required';
  } else if (!isValidDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Effective Date must be a valid date';
  } else if (formState.hasNoExpirationDate !== true && formState.effectiveDate === formState.expirationDate) {
    errors.effectiveDate = 'Effective Date and Expiration Date cannot be the same date';
  } else if (formState.hasNoExpirationDate !== true && expirationDate < effectiveDate) {
    errors.effectiveDate = 'Effective Date must be before the Expiration Date';
  }

  // Expiration Date
  if (formState.hasNoExpirationDate !== true) {
    if (formState.expirationDate === BLANK_DATE_STRING) {
      errors.expirationDate = 'Expiration Date is required';
    } else if (!isValidDate(formState.expirationDate)) {
      errors.expirationDate = 'Expiration Date must be a valid date';
    } else if (expirationDate < effectiveDate) {
      errors.expirationDate = 'Expiration Date must be after the Effective Date';
    }
  }

  return errors;
};

const NTSupportStaffAddFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default NTSupportStaffAddFormValidation;