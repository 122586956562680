import useNTRegistrationLeftNavData from "../../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";

const useNTRegistrationLeftNav = () => {
  const { nationalTeamRegistrationLeftNavState } = useNTRegistrationLeftNavData();

  return {
    nationalTeamRegistrationLeftNavState
  };
};

export default useNTRegistrationLeftNav;