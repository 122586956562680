export const FIELD_NAME_PAIR_HEIGHT = { formStateName: 'height', bioEntryName: 'Height' };
export const FIELD_NAME_PAIR_WEIGHT = { formStateName: 'weight', bioEntryName: 'Weight' };
export const FIELD_NAME_PAIR_COLLEGE = { formStateName: 'college', bioEntryName: 'College' };
export const FIELD_NAME_PAIR_COLLEGE_GRADUATION = { formStateName: 'collegeGraduationYear', bioEntryName: 'College Graduation' };
export const FIELD_NAME_PAIR_HIGH_SCHOOL = { formStateName: 'highSchool', bioEntryName: 'High School' };
export const FIELD_NAME_PAIR_HIGH_SCHOOL_GRADUATION = { formStateName: 'highSchoolGraduationYear', bioEntryName: 'High School Graduation' };
export const FIELD_NAME_PAIR_HIGH_SCHOOL_CITY = { formStateName: 'highSchoolCity', bioEntryName: 'High School City' };
export const FIELD_NAME_PAIR_HIGH_SCHOOL_STATE = { formStateName: 'highSchoolState', bioEntryName: 'High School State' };
export const FIELD_NAME_PAIR_HOMETOWN = { formStateName: 'hometown', bioEntryName: 'Hometown' };
export const FIELD_NAME_PAIR_HOMETOWN_STATE = { formStateName: 'hometownState', bioEntryName: 'Hometown State' };
export const FIELD_NAME_PAIR_RESIDENCE = { formStateName: 'residence', bioEntryName: 'Residence' };
export const FIELD_NAME_PAIR_RESIDENCE_STATE = { formStateName: 'residenceState', bioEntryName: 'Residence State' };
export const FIELD_NAME_PAIR_BIRTHPLACE = { formStateName: 'birthplace', bioEntryName: 'Birthplace' };
export const FIELD_NAME_PAIR_BIRTHPLACE_COUNTRY = { formStateName: 'birthplaceCountry', bioEntryName: 'Birth Country' };
export const FIELD_NAME_PAIR_BIRTHPLACE_STATE = { formStateName: 'birthplaceState', bioEntryName: 'Birthplace State' };

export const FIELD_NAME_PAIRS = [
  FIELD_NAME_PAIR_HEIGHT,
  FIELD_NAME_PAIR_WEIGHT,
  FIELD_NAME_PAIR_COLLEGE,
  FIELD_NAME_PAIR_COLLEGE_GRADUATION,
  FIELD_NAME_PAIR_HIGH_SCHOOL,
  FIELD_NAME_PAIR_HIGH_SCHOOL_GRADUATION,
  FIELD_NAME_PAIR_HIGH_SCHOOL_CITY,
  FIELD_NAME_PAIR_HIGH_SCHOOL_STATE,
  FIELD_NAME_PAIR_HOMETOWN,
  FIELD_NAME_PAIR_HOMETOWN_STATE,
  FIELD_NAME_PAIR_RESIDENCE,
  FIELD_NAME_PAIR_RESIDENCE_STATE,
  FIELD_NAME_PAIR_BIRTHPLACE,
  FIELD_NAME_PAIR_BIRTHPLACE_COUNTRY,
  FIELD_NAME_PAIR_BIRTHPLACE_STATE,
];

const PersonBioInfoFormConstants = {
  FIELD_NAME_PAIR_HEIGHT,
  FIELD_NAME_PAIR_WEIGHT,
  FIELD_NAME_PAIR_COLLEGE,
  FIELD_NAME_PAIR_COLLEGE_GRADUATION,
  FIELD_NAME_PAIR_HIGH_SCHOOL,
  FIELD_NAME_PAIR_HIGH_SCHOOL_GRADUATION,
  FIELD_NAME_PAIR_HIGH_SCHOOL_CITY,
  FIELD_NAME_PAIR_HIGH_SCHOOL_STATE,
  FIELD_NAME_PAIR_HOMETOWN,
  FIELD_NAME_PAIR_HOMETOWN_STATE,
  FIELD_NAME_PAIR_RESIDENCE,
  FIELD_NAME_PAIR_RESIDENCE_STATE,
  FIELD_NAME_PAIR_BIRTHPLACE,
  FIELD_NAME_PAIR_BIRTHPLACE_COUNTRY,
  FIELD_NAME_PAIR_BIRTHPLACE_STATE,
  FIELD_NAME_PAIRS
};

export default PersonBioInfoFormConstants;