import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationPersonData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationPerson/UseNTRegistrationPersonData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_SUPPORT_STAFF } from "../utils/NTRegistrationConstants";
import { DEFAULT_MAX_DATE } from "../../../../common/utils/Constants";

const INITIAL_STATE = {
  supportStaffObj: null,
  supportStaffRoleObj: null,
  displayDupRoleModal: false,
  dupRoleObj: {}
};

const useNTRegistrationSupportStaffRoleDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { onNTRegistrationError } = useNTRegistrationData();
  const { updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationPersonState, putNTRegistrationPerson } = useNTRegistrationPersonData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCancelClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_SUPPORT_STAFF?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDupPopupCloseClicked = (event) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDupRoleModal: false,
        dupRoleObj: {}
      });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onSubmitSupportStaffRoleAddForm = async (formState) => {
    try {
      const dupRoleObj = findDuplicateRole(formState, state.supportStaffObj.nationalTeamSupportStaffType);

      if (dupRoleObj) {
        setState({
          ...state,
          displayDupRoleModal: true,
          dupRoleObj
        });
      } else {
        const personId = nationalTeamRegistrationPersonState.objData.personId;
        const newNTPersonObj = JSON.parse(JSON.stringify(nationalTeamRegistrationPersonState.objData));
        const targetStaffIndex = newNTPersonObj.supportStaffMembers.findIndex((x) => x.nationalTeamSupportStaffId === state.supportStaffObj.nationalTeamSupportStaffId);
        newNTPersonObj.supportStaffMembers[targetStaffIndex].nationalTeamSupportStaffType.push({
          nationalTeamSupportStaffId: state.supportStaffObj.nationalTeamSupportStaffId,
          effectiveDate: formState.effectiveDate,
          expirationDate: formState.hasNoExpirationDate === true ? DEFAULT_MAX_DATE : formState.expirationDate,
          supportStaffTypeId: formState.supportStaffTypeId,
        });

        const putNTRegistrationPersonResult = await putNTRegistrationPerson(personId, newNTPersonObj);

        if (putNTRegistrationPersonResult) {
          navigate(navRoutes.NT_REGISTRATION_SUPPORT_STAFF?.route);
        }
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onSubmitSupportStaffRoleEditForm = async (formState) => {
    try {
      const dupRoleObj = findDuplicateRole({...formState, supportStaffTypeId: state.supportStaffRoleObj.supportStaffTypeId }, state.supportStaffObj.nationalTeamSupportStaffType, state.supportStaffRoleObj.nationalTeamSupportStaffTypeId);

      if (dupRoleObj) {
        setState({
          ...state,
          displayDupRoleModal: true,
          dupRoleObj
        });
      } else {
        const personId = nationalTeamRegistrationPersonState.objData.personId;
        const newNTPersonObj = JSON.parse(JSON.stringify(nationalTeamRegistrationPersonState.objData));
        const targetStaffIndex = newNTPersonObj.supportStaffMembers.findIndex((x) => x.nationalTeamSupportStaffId === state.supportStaffObj.nationalTeamSupportStaffId);
        const targetRoleIndex = newNTPersonObj.supportStaffMembers[targetStaffIndex].nationalTeamSupportStaffType.findIndex((x) => x.nationalTeamSupportStaffTypeId === state.supportStaffRoleObj.nationalTeamSupportStaffTypeId)

        newNTPersonObj.supportStaffMembers[targetStaffIndex].nationalTeamSupportStaffType[targetRoleIndex] = {
          ...newNTPersonObj.supportStaffMembers[targetStaffIndex].nationalTeamSupportStaffType[targetRoleIndex],
          effectiveDate: formState.effectiveDate,
          expirationDate: formState.hasNoExpirationDate === true ? DEFAULT_MAX_DATE : formState.expirationDate
        };

        const putNTRegistrationPersonResult = await putNTRegistrationPerson(personId, newNTPersonObj);

        if (putNTRegistrationPersonResult) {
          navigate(navRoutes.NT_REGISTRATION_SUPPORT_STAFF?.route);
        }
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    try {
      if (location.state?.supportStaffObj) {
        updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_SUPPORT_STAFF);

        if (location.state?.supportStaffRoleObj) {
          setState({ ...state, supportStaffObj: location.state.supportStaffObj, supportStaffRoleObj: location.state.supportStaffRoleObj });
        } else {
          setState({ ...state, supportStaffObj: location.state.supportStaffObj });
        }
      } else {
        throw new Error('Failed to load Support Staff Role Detail view');
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function findDuplicateRole(formState, nationalTeamSupportStaffType, nationalTeamSupportStaffTypeId = undefined) {
    const existingRoles = nationalTeamSupportStaffType.filter((x) => x.nationalTeamSupportStaffTypeId !== nationalTeamSupportStaffTypeId);
    const effectiveDate = new Date(formState.effectiveDate);
    const expirationDate = formState.hasNoExpirationDate === true ? new Date(DEFAULT_MAX_DATE) : new Date(formState.expirationDate);

    for (const role of existingRoles) {
      if (formState.supportStaffTypeId === role.supportStaffTypeId) {
        const existingEffectiveDate = new Date(role.effectiveDate);
        const existingExpirationDate = new Date(role.expirationDate);

        if (effectiveDate <= existingExpirationDate && expirationDate >= existingEffectiveDate) {
          return role;
        }
      }
    }

    return null;
  }

  return {
    isLoading: nationalTeamRegistrationPersonState.isObjLoading,
    state,
    supportStaffObj: state.supportStaffObj || null,
    supportStaffRoleObj: state.supportStaffRoleObj || null,
    onCancelClicked,
    onDupPopupCloseClicked,
    onSubmitSupportStaffRoleAddForm,
    onSubmitSupportStaffRoleEditForm
  };
};

export default useNTRegistrationSupportStaffRoleDetail;