import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const getBioEntryData = (state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const url = `/BioEntry`;

  return api.executeArray ? api.executeArray(url, 'GET') : null;
};

const INITIAL_BIO_ENTRY_STATE = {
  ...BASIC_INITIAL_STATE
};

const BioEntryData = {
  INITIAL_BIO_ENTRY_STATE,
  getBioEntryData
};

export default BioEntryData;