import { useEffect } from 'react';

import validate from './SocialMediaAddFormValidation';

import useSocialMediaTypeData from '../../../../../state/socialMediaType/UseSocialMediaTypeData';
import useNTRegistrationData from '../../../../../state/nationalTeamRegistration/UseNTRegistrationData';

import { DEFAULT_ID } from '../../../../../../common/utils/Constants';
import useForm from "../../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  socialMediaTypeId: DEFAULT_ID,
  socialMediaTypeName: '',
  socialHandle: ''
};

const useSocialMediaAddForm = (onSubmitFormCallback) => {
  const { onNTRegistrationError } = useNTRegistrationData();
  const { socialMediaTypeState } = useSocialMediaTypeData();
  const { formState, errorState, isDirty, isSubmitting, setFormData, handleSubmit, updateFormState, onValueTextPairChanged
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try { // reset form
      if (isDirty === false && isSubmitting === false) {
        setFormData(INITIAL_FORM_STATE, true);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isSubmitting]);

  return {
    socialMediaOptions: socialMediaTypeState.dataAsOptionsWithNames || [],
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  };
};

export default useSocialMediaAddForm;