import { createContext, useState } from 'react';

import NTContactsData from './NTContactsData';

export const NTContactsContext = createContext();

const NTContactsContextProvider = ({ children }) => {
  const stateHook = useState(NTContactsData.INITIAL_NATIONAL_TEAM_CONTACTS_STATE);

  return (
    <NTContactsContext.Provider value={stateHook}>
      {children}
    </NTContactsContext.Provider>
  );
};

export default NTContactsContextProvider;