import { useContext, useState } from "react";

import NTCoachOfRecordData from "./NTCoachOfRecordData";
import { NTCoachOfRecordContext } from "./NTCoachOfRecordContextProvider";

const useNTCoachOfRecordData = () => {
  const [nationalTeamCoachOfRecordState, setNationalTeamCoachOfRecordState] = useContext(NTCoachOfRecordContext);
  const [coachOfRecordAddState, setCoachOfRecordAddState] = useState(NTCoachOfRecordData.INITIAL_COACH_OF_RECORD_ADD_STATE)

  const postNTCoachOfRecord = (personId, coachObj) => {
    return NTCoachOfRecordData.postNTCoachOfRecordData(personId, coachObj, coachOfRecordAddState, setCoachOfRecordAddState);
  };

  const getNTCoachOfRecord = (personId) => {
    return NTCoachOfRecordData.getNTCoachOfRecordData(personId, nationalTeamCoachOfRecordState, setNationalTeamCoachOfRecordState);
  };

  return {
    nationalTeamCoachOfRecordState,
    coachOfRecordAddState,
    postNTCoachOfRecord,
    getNTCoachOfRecord
  };
};

export default useNTCoachOfRecordData;