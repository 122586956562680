import useNTRegistrationLoadingModal from "./UseNTRegistrationLoadingModal";

import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";

import { LOADING_MSG } from "../../../../../common/utils/Constants";

const NTRegistrationLoadingModal = ({ isLoading, loadingMessage = LOADING_MSG}) => {
  const {
    nationalTeamRegistrationState,
    onReloadClicked
  } = useNTRegistrationLoadingModal();

  if (nationalTeamRegistrationState.isError === true) {
    return (
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={nationalTeamRegistrationState.errorTitle}
        displayPopUp={true}
        onModalCanceled={onReloadClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{nationalTeamRegistrationState.errorMessage}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onReloadClicked}>Reload National Team Registration</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
    );
  } else {
    return (
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={loadingMessage}
        displayPopUp={isLoading === true} />
    );
  }
};

export default NTRegistrationLoadingModal;