import { Fragment } from 'react';

import useNTRegistrationSocialMedia from './UseNTRegistrationSocialMedia';

import SocialMediaGrid from '../components/grids/socialMedia/SocialMediaGrid';
import SocialMediaAddForm from '../components/forms/socialMedia/SocialMediaAddForm';
import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

const NTRegistrationSocialMedia = () => {
  const {
    isLoading,
    gridData,
    state,
    onContinueClicked,
    onBackClicked,
    onSubmitSocialMediaAddForm,
    onEditSocialMediaClicked,
    onDeleteSocialMediaClicked,
    onDeletePopupCancelClicked,
    onDeletePopupConfirmClicked
  } = useNTRegistrationSocialMedia();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Social Media'}>
        <SocialMediaAddForm onSubmitFormCallback={onSubmitSocialMediaAddForm} />
        <SocialMediaGrid
          gridData={gridData}
          isLoading={isLoading}
          onEditClicked={onEditSocialMediaClicked}
          onDeleteClicked={onDeleteSocialMediaClicked} />
        <div className="row pull-right usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>
          </div>
        </div>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={'Delete Social Media?'}
          displayPopUp={state.displayDeletePopup === true}
          onModalCanceled={onDeletePopupCancelClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p><b>{state.deleteSocialMediaObj?.socialMediaType?.typeName || ''}</b></p>
            </div>
            <div className="col-xs-12">
              <p>{state.deleteSocialMediaObj?.socialHandle || ''}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onDeletePopupConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onDeletePopupCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationSocialMedia;