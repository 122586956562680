import { Fragment } from 'react';

import useFrequentFlyerFormGrid from './UseFrequentFlyerFormGrid';

import FrequentFlyerFormGridLarge from './FrequentFlyerFormGridLarge';
import FrequentFlyerFormGridSmall from './FrequentFlyerFormGridSmall';

import PrimaryButton from '../../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../../common/components/buttons/SecondaryButton';
import Spinner from '../../../../../../common/components/spinners/Spinner';

const FrequentFlyerFormGrid = ({ gridData, personId, primaryButtonText = 'Continue',
  secondaryButtonText = 'Back', onSubmitFormCallbackPrimary, onSubmitFormCallbackSecondary }) => {
  const {
    isLoading,
    // canSaveState,
    formGridState,
    onUpdateFormGridState,
    onSubmitFormGrid
  } = useFrequentFlyerFormGrid(gridData, personId);

  if (isLoading === true) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <FrequentFlyerFormGridLarge formGridState={formGridState} onUpdateFormGridState={onUpdateFormGridState} />
      <FrequentFlyerFormGridSmall formGridState={formGridState} onUpdateFormGridState={onUpdateFormGridState} />
      <div className="row pull-right usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <SecondaryButton type="button" onClick={(e) => onSubmitFormGrid(e, onSubmitFormCallbackSecondary)}>{secondaryButtonText}</SecondaryButton>&nbsp;
          <PrimaryButton type="button" onClick={(e) => onSubmitFormGrid(e, onSubmitFormCallbackPrimary)}>{primaryButtonText}</PrimaryButton>
        </div>
      </div>
    </Fragment>
  );
};

export default FrequentFlyerFormGrid;