import { createContext, useState } from 'react';

import NTRegistrationProfileData from './NTRegistrationProfileData';

export const NTRegistrationProfileContext = createContext();

const NTRegistrationProfileContextProvider = ({ children }) => {
  const stateHook = useState(NTRegistrationProfileData.INITIAL_NATIONAL_TEAM_REGISTRATION_PERSON_PROFILE_STATE);

  return (
    <NTRegistrationProfileContext.Provider value={stateHook}>
      {children}
    </NTRegistrationProfileContext.Provider>
  );
};

export default NTRegistrationProfileContextProvider;