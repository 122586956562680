import { useContext, useState } from "react";

import NTAttachmentData from "./NTAttachmentData";
import { NTAttachmentContext } from "./NTAttachmentContextProvider";

import { API_ERROR_MESSAGE, NO_DATA_MESSAGE } from "../../../common/utils/HttpHelper";

const useNTAttachmentData = () => {
  const [nationalTeamAttachmentState, setNationalTeamAttachmentState] = useContext(NTAttachmentContext);
  const [nationalTeamAttachmentDownloadState, setNationalTeamAttachmentDownloadState] = useState(NTAttachmentData.INITIAL_NATIONAL_TEAM_ATTACHMENT_DOWNLOAD_STATE);
  const [nationalTeamAttachmentUploadState, setNationalTeamAttachmentUploadState] = useState(NTAttachmentData.INITIAL_NATIONAL_TEAM_ATTACHMENT_UPLOAD_STATE);
  const [nationalTeamAttachmentUpdateState, setNationalTeamAttachmentUpdateState] = useState(NTAttachmentData.INITIAL_NATIONAL_TEAM_ATTACHMENT_UPDATE_STATE);
  const [nationalTeamAttachmentDeleteState, setNationalTeamAttachmentDeleteState] = useState(NTAttachmentData.INITIAL_NATIONAL_TEAM_ATTACHMENT_DELETE_STATE);

  /**
   * Uploads a file to blob storage and then posts an object to the db
   * @param {File} file 
   * @param {Object} attachmentObj 
   * @returns promise
   */
  const postNTAttachment = async (file, attachmentObj) => {
    const postFileResultState = await NTAttachmentData.postNTAttachmentFileData(file, nationalTeamAttachmentUploadState, setNationalTeamAttachmentUploadState);

    if (postFileResultState.objData?.uploadUrl) {
      const attachmentUrl = postFileResultState.objData.uploadUrl;

      return await NTAttachmentData.postNTAttachmentData({ ...attachmentObj, attachmentUrl }, postFileResultState, setNationalTeamAttachmentUploadState);
    } else {
      throw new Error(NO_DATA_MESSAGE);
    }
  };

  const getNTAttachment = (attachmentUrl) => {
    return NTAttachmentData.getNTAttachmentFileData(attachmentUrl, nationalTeamAttachmentDownloadState, setNationalTeamAttachmentDownloadState);
  };

  const getNTAttachmentsByPersonId = (personId) => {
    return NTAttachmentData.getNTAttachmentsByPersonIdData(personId, nationalTeamAttachmentState, setNationalTeamAttachmentState);
  };

  /**
   * Updates an existing db object. 
   * If a newFile is provided, overwrites the previous file in blob storage and then updates the db object
   * @param {Number} attachmentId 
   * @param {Object} attachmentObj 
   * @param {File} newFile 
   * @returns promise
   */
  const putNTAttachment = async (attachmentId, attachmentObj, newFile = undefined) => {
    if (newFile) {
      const oldAttachmentUrl = attachmentObj.attachmentUrl;

      const postFileResultState = await NTAttachmentData.postNTAttachmentFileUpdateData(newFile, oldAttachmentUrl, nationalTeamAttachmentUpdateState, setNationalTeamAttachmentUpdateState);

      if (postFileResultState.objData?.uploadUrl) {
        const attachmentUrl = postFileResultState.objData.uploadUrl; // should be the same as the oldAttachmentUrl but just to be safe

        return await NTAttachmentData.putNTAttachmentData(attachmentId, { ...attachmentObj, attachmentUrl }, nationalTeamAttachmentUpdateState, setNationalTeamAttachmentUpdateState);
      } else {
        throw new Error(NO_DATA_MESSAGE);
      }
    } else {
      return await NTAttachmentData.putNTAttachmentData(attachmentId, attachmentObj, nationalTeamAttachmentUpdateState, setNationalTeamAttachmentUpdateState);
    }
  };

  /**
   * Deletes the object from the db and then deletes the associated file from blob storage
   * @param {Number} attachmentId 
   * @param {String} attachmentUrl 
   * @returns promise
   */
  const deleteNTAttachment = async (attachmentId, attachmentUrl) => {
    const deleteResultState = await NTAttachmentData.deleteNTAttachmentData(attachmentId, nationalTeamAttachmentDeleteState, setNationalTeamAttachmentDeleteState);

    if (deleteResultState.isObjLoaded === true) {
      return await NTAttachmentData.deleteNTAttachmentFileData(attachmentUrl, nationalTeamAttachmentDeleteState, setNationalTeamAttachmentDeleteState);
    } else {
      throw new Error(API_ERROR_MESSAGE);
    }
  };

  return {
    nationalTeamAttachmentState,
    nationalTeamAttachmentUploadState,
    nationalTeamAttachmentDeleteState,
    nationalTeamAttachmentUpdateState,
    nationalTeamAttachmentDownloadState,
    postNTAttachment,
    getNTAttachment,
    getNTAttachmentsByPersonId,
    putNTAttachment,
    deleteNTAttachment
  };
};

export default useNTAttachmentData;