import { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import PlusIcon from '../../../../common/components/icons/PlusIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import { formatPhoneNumber } from '../../../../common/utils/validation';
import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGridRow = ({ role, onEditRoleClicked, onDeleteRoleClicked }) => (
  <tr>
    <td>{role.supportStaffType?.typeName || ''}</td>
    <td>{role.effectiveDate ? formatDate(role.effectiveDate) : ''}</td>
    <td>{role.expirationDate ? formatDate(role.expirationDate) : ''}</td>
    <td className='pull-right'>
      <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditRoleClicked(e, role)}>
        <EditIcon toolTipText='Edit Support Staff Role' />
      </button>
      <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteRoleClicked(e, role)}>
        <DeleteIcon toolTipText='Delete Support Staff Role' />
      </button>
    </td>
  </tr>
);

const DetailGrid = ({ nationalTeamSupportStaffType, onEditRoleClicked, onDeleteRoleClicked }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Staff Role</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(nationalTeamSupportStaffType) && nationalTeamSupportStaffType.length > 0
        ? nationalTeamSupportStaffType.map((role, i) => <DetailGridRow key={i} role={role} onEditRoleClicked={onEditRoleClicked} onDeleteRoleClicked={onDeleteRoleClicked} />)
        : <tr><td colSpan='4'>No Roles</td></tr>
      }
    </tbody>
  </table>
);

const GridRow = ({ supportStaff, onAddRoleClicked, onEditClicked, onEditRoleClicked, onDeleteClicked, onDeleteRoleClicked }) => (
  <Fragment>
    <tr>
      <td>{supportStaff.firstName || ''} {supportStaff.lastName || ''}</td>
      <td>{supportStaff.email || ''}</td>
      <td>{supportStaff.phoneNumber ? formatPhoneNumber(supportStaff.phoneNumber) : ''}</td>
      <td className='pull-right'>
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onAddRoleClicked(e, supportStaff)}>
          <PlusIcon toolTipText="Add Support Staff Role" />
        </button>
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditClicked(e, supportStaff)}>
          <EditIcon toolTipText='Edit Support Staff' />
        </button>
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteClicked(e, supportStaff)}>
          <DeleteIcon toolTipText='Delete Support Staff' />
        </button>
      </td>
    </tr>
    <tr className={global.Expanded}>
      <td colSpan="4">
        <DetailGrid nationalTeamSupportStaffType={supportStaff.nationalTeamSupportStaffType} onEditRoleClicked={onEditRoleClicked} onDeleteRoleClicked={onDeleteRoleClicked} />
      </td>
    </tr>
  </Fragment>
);

const NTSupportStaffGridLarge = ({ gridData, isLoading, onAddRoleClicked, onEditClicked, onEditRoleClicked, onDeleteClicked, onDeleteRoleClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Phone Number</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((supportStaff, i) => <GridRow key={i} supportStaff={supportStaff} onAddRoleClicked={onAddRoleClicked} onEditClicked={onEditClicked} onEditRoleClicked={onEditRoleClicked} onDeleteClicked={onDeleteClicked} onDeleteRoleClicked={onDeleteRoleClicked} />)
          : <tr><td colSpan="4">No Support Staff</td></tr>
      }
    </tbody>
  </table>
);

export default NTSupportStaffGridLarge;