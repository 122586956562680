import { Route, Routes } from "react-router-dom";
import { Navigate } from "../../../common/wrappers/ReactRouterDom";

import NTRegistrationEntry from "./nationalTeamRegistrationEntry/NTRegistrationEntry";
import NTRegistrationCoach from "./nationalTeamRegistrationCoach/NTRegistrationCoach";
import NTRegistrationBioInfo from "./nationalTeamRegistrationBioInfo/NTRegistrationBioInfo";
import NTRegistrationContacts from "./nationalTeamRegistrationContacts/NTRegistrationContacts";
import NTRegistrationContactsDetail from "./nationalTeamRegistrationContacts/NTRegistrationContactsDetail";
import NTRegistrationContactsAddressDetail from "./nationalTeamRegistrationContacts/NTRegistrationContactsAddressDetail";
import NTRegistrationSponsors from "./nationalTeamRegistrationSponsors/NTRegistrationSponsors";
import NTRegistrationSponsorsDetail from "./nationalTeamRegistrationSponsors/NTRegistrationSponsorsDetail";
import NTRegistrationFunFacts from "./nationalTeamRegistrationFunFacts/NTRegistrationFunFacts";
import NTRegistrationFunFactsDetail from "./nationalTeamRegistrationFunFacts/NTRegistrationFunFactsDetail";
import NTRegistrationAttachments from "./nationalTeamRegistrationAttachments/NTRegistrationAttachments";
import NTRegistrationSocialMedia from "./nationalTeamRegistrationSocialMedia/NTRegistrationSocialMedia";
import NTRegistrationSocialMediaDetail from "./nationalTeamRegistrationSocialMedia/NTRegistrationSocialMediaDetail";
import NTRegistrationSupportStaff from "./nationalTeamRegistrationSupportStaff/NTRegistrationSupportStaff";
import NTRegistrationSupportStaffDetail from "./nationalTeamRegistrationSupportStaff/NTRegistrationSupportStaffDetail";
import NTRegistrationSupportStaffRoleDetail from "./nationalTeamRegistrationSupportStaff/NTRegistrationSupportStaffRoleDetail";
import NTRegistrationFrequentFlyer from "./nationalTeamRegistrationFrequentFlyer/NTRegistrationFrequentFlyer";
import NTRegistrationSubmit from "./nationalTeamRegistrationSubmit/NTRegistrationSubmit";

import BioEntryContextProvider from "../../state/bioEntry/BioEntryContextProvider";
import NTAttachmentContextProvider from "../../state/nationalTeamAttachment/NTAttachmentContextProvider";
import NTCoachOfRecordContextProvider from "../../state/nationalTeamCoachOfRecord/NTCoachOfRecordContextProvider";
import NTRegistrationContextProvider from "../../state/nationalTeamRegistration/NTRegistrationContextProvider";
import NTRegistrationPersonContextProvider from "../../state/nationalTeamRegistration/nationalTeamRegistrationPerson/NTRegistrationPersonContextProvider";
import NTRegistrationLeftNavContextProvider from "../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/NTRegistrationLeftNavContextProvider";
import NTRegistrationProfileContextProvider from "../../state/nationalTeamRegistration/nationalTeamRegistrationProfile/NTRegistrationProfileContextProvider";
import PersonNationalTeamFileTypeContextProvider from "../../state/personNationalTeamFileType/PersonNationalTeamFileTypeContextProvider";
import NTRegistrationContactsContextProvider from "../../state/nationalTeamRegistration/nationalTeamRegistrationContacts/NTRegistrationContactsContextProvider";

import LoadingModal from "../../../common/components/dialogs/LoadingModal";
import ErrorBoundary from "../../../common/components/errors/ErrorBoundary";
import SecuredRouteElement from "../../../common/components/security/SecuredRouteElement";
import ErrorBoundaryFallback from "../../../common/components/errors/ErrorBoundaryFallback";

import useNavRoutes from "../../../common/state/security/UseNavRoutes";
import AddressTypeContextProvider from "../../../common/state/addressType/AddressTypeContextProvider";
import LeftNavModalContextProvider from "../../../common/state/leftNavModal/LeftNavModalContextProvider";
import GroupPersonCoachesContextProvider from "../../../common/state/groupPersonCoaches/GroupPersonCoachesContextProvider";
import ContactRelationshipTypesContextProvider from "../../../common/state/contactRelationshipType/ContactRelationshipTypesContextProvider";

const NTRegistrationContextRoutesContextProviders = ({ children }) => (
  <LeftNavModalContextProvider>
    <BioEntryContextProvider>
      <GroupPersonCoachesContextProvider>
        <PersonNationalTeamFileTypeContextProvider>
          <NTRegistrationLeftNavContextProvider>
            <NTRegistrationPersonContextProvider>
              <NTRegistrationProfileContextProvider>
                <NTRegistrationContactsContextProvider>
                  <NTCoachOfRecordContextProvider>
                    <NTAttachmentContextProvider>
                      {children}
                    </NTAttachmentContextProvider>
                  </NTCoachOfRecordContextProvider>
                </NTRegistrationContactsContextProvider>
              </NTRegistrationProfileContextProvider>
            </NTRegistrationPersonContextProvider>
          </NTRegistrationLeftNavContextProvider>
        </PersonNationalTeamFileTypeContextProvider>
      </GroupPersonCoachesContextProvider>
    </BioEntryContextProvider>
  </LeftNavModalContextProvider>
);

const NTRegistrationBasicRoutesContextProviders = ({ children }) => (
  <ContactRelationshipTypesContextProvider>
    <AddressTypeContextProvider>
      <NTRegistrationContextProvider>
        {children}
      </NTRegistrationContextProvider>
    </AddressTypeContextProvider>
  </ContactRelationshipTypesContextProvider>
);

const NTRegistrationContextRoutes = ({ navRoutes }) => (
  <NTRegistrationContextRoutesContextProviders>
    <Routes>
      <Route path={navRoutes.NT_REGISTRATION_BIO_INFO?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_BIO_INFO}
            editElement={<NTRegistrationBioInfo />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_CONTACTS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_CONTACTS}
            editElement={<NTRegistrationContacts />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_CONTACTS_DETAIL?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_CONTACTS_DETAIL}
            editElement={<NTRegistrationContactsDetail />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_CONTACTS_ADDRESS_DETAIL?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_CONTACTS_ADDRESS_DETAIL}
            editElement={<NTRegistrationContactsAddressDetail />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_SUPPORT_STAFF?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_SUPPORT_STAFF}
            editElement={<NTRegistrationSupportStaff />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_SUPPORT_STAFF_DETAIL?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_SUPPORT_STAFF_DETAIL}
            editElement={<NTRegistrationSupportStaffDetail />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_SUPPORT_STAFF_ROLE_DETAIL?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_SUPPORT_STAFF_ROLE_DETAIL}
            editElement={<NTRegistrationSupportStaffRoleDetail />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_COACH?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_COACH}
            editElement={<NTRegistrationCoach />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_FREQUENT_FLYER?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_FREQUENT_FLYER}
            editElement={<NTRegistrationFrequentFlyer />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_SPONSORS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_SPONSORS}
            editElement={<NTRegistrationSponsors />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_SPONSORS_DETAIL?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_SPONSORS_DETAIL}
            editElement={<NTRegistrationSponsorsDetail />} />
        } />

      <Route path={navRoutes.NT_REGISTRATION_SOCIAL_MEDIA?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_SOCIAL_MEDIA}
            editElement={<NTRegistrationSocialMedia />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_SOCIAL_MEDIA_DETAIL?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_SOCIAL_MEDIA_DETAIL}
            editElement={<NTRegistrationSocialMediaDetail />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_FUN_FACTS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_FUN_FACTS}
            editElement={<NTRegistrationFunFacts />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_FUN_FACTS_DETAIL?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_FUN_FACTS_DETAIL}
            editElement={<NTRegistrationFunFactsDetail />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_ATTACHMENTS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_ATTACHMENTS}
            editElement={<NTRegistrationAttachments />} />
        } />
      <Route path={navRoutes.NT_REGISTRATION_SUBMIT?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_SUBMIT}
            editElement={<NTRegistrationSubmit />} />
        } />
    </Routes>
  </NTRegistrationContextRoutesContextProviders>
);

const NTRegistrationBasicRoutes = ({ navRoutes }) => (
  <NTRegistrationBasicRoutesContextProviders>
    <Routes>
      <Route path={navRoutes.NT_REGISTRATION_ENTRY?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_REGISTRATION_ENTRY}
            editElement={<NTRegistrationEntry />} />
        } />

      <Route path={'/*'} element={<NTRegistrationContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes.NT_REGISTRATION_ENTRY?.path} replace />} />
    </Routes>
  </NTRegistrationBasicRoutesContextProviders>
);

const NTRegistration = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <NTRegistrationBasicRoutes navRoutes={navRoutes} />
    </ErrorBoundary>
  );
};

export default NTRegistration;