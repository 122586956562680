import { useEffect } from 'react';

import validate from './NTSupportStaffEditRoleFormValidation';

import useForm from '../../../../common/utils/UseForm';
import { BLANK_DATE_STRING, DEFAULT_MAX_DATE } from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING,
  hasNoExpirationDate: false
};

const useNTSupportStaffEditRoleForm = (supportStaffRoleObj, onSubmitFormCallback) => {
  const { formState, errorState, handleSubmit, updateFormState, setFormData
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    if (supportStaffRoleObj) {
      const hasNoExpirationDate = supportStaffRoleObj.expirationDate
      ? formatDate(supportStaffRoleObj.expirationDate) === DEFAULT_MAX_DATE
      : false;

      setFormData({
        ...INITIAL_FORM_STATE,
        effectiveDate: supportStaffRoleObj.effectiveDate ? formatDate(supportStaffRoleObj.effectiveDate) : BLANK_DATE_STRING,
        expirationDate: supportStaffRoleObj.expirationDate ? formatDate(supportStaffRoleObj.expirationDate) : BLANK_DATE_STRING,
        hasNoExpirationDate
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportStaffRoleObj]);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  };
};

export default useNTSupportStaffEditRoleForm;