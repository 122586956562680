import useFunFactsAddForm from "./UseFunFactsAddForm";

import Textarea from "../../../../../../common/components/inputs/Textarea";
import Dropdown from "../../../../../../common/components/dropdowns/Dropdown";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";

import global from '../../../../../../common/components/GlobalStyle.module.css';
import style from '../../../NTRegistration.module.css';

const FunFactsAddForm = ({ submitButtonText = 'Add Fun Fact', onSubmitFormCallback }) => {
  const {
    funFactsOptions,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  } = useFunFactsAddForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <Dropdown
            label="Fun Fact Name"
            name="funFactsId"
            value={formState.funFactsId}
            error={errorState.funFactsId}
            message={errorState.funFactsId}
            isLoading={funFactsOptions.length === 0}
            options={funFactsOptions}
            onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'funFactsId', newValueLabel, 'funFactsName'); }}
          />
        </div>
      </div>
      <div className='row'>
        <div className="col-xs-12">
          <Textarea
            className={style.SmallTextArea}
            label="Fun Fact Description"
            name="funFactsDescription"
            value={formState.funFactsDescription}
            error={errorState.funFactsDescription}
            message={errorState.funFactsDescription}
            onChange={(value) => { updateFormState('funFactsDescription', value) }} />
        </div>
      </div>
      {errorState.errorOnSubmitAction &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
        </div>
      </div>
    </form>
  );
};

export default FunFactsAddForm;