import { useEffect } from 'react';

import validate from './FunFactsAddFormValidation';

import useFunFactsTypeData from '../../../../../state/funFactsType/UseFunFactsTypeData';
import useNTRegistrationData from '../../../../../state/nationalTeamRegistration/UseNTRegistrationData';

import { DEFAULT_ID } from '../../../../../../common/utils/Constants';
import useForm from "../../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  funFactsId: DEFAULT_ID,
  funFactsName: '',
  funFactsDescription: ''
};

const useFunFactsAddForm = (onSubmitFormCallback) => {
  const { onNTRegistrationError } = useNTRegistrationData();
  const { funFactsTypeState } = useFunFactsTypeData();
  const { formState, errorState, isDirty, isSubmitting, setFormData, handleSubmit, updateFormState, onValueTextPairChanged
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try { // reset form
      if (isDirty === false && isSubmitting === false) {
        setFormData(INITIAL_FORM_STATE, true);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isSubmitting]);

  return {
    funFactsOptions: funFactsTypeState.dataAsOptionsWithNames || [], //TODO make fun facts dropdown component?
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  };
};

export default useFunFactsAddForm;