import { Fragment } from 'react';
import useNationalTeamContactAddressDetail from './UseNationalTeamContactAddressDetail';

import Headings from '../../../../common/components/headings/Headings';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import NTContactAddressForm from '../../../components/forms/nationalTeamContacts/NTContactAddressForm';

import { LOADING_MSG } from '../../../../common/utils/Constants';

const NationalTeamContactAddressDetail = () => {
  const {
    isLoading,
    contactName,
    contactAddressObj,
    onCancelClicked,
    onSubmitContactForm
  } = useNationalTeamContactAddressDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{contactAddressObj ? 'Edit Address' : 'Add Address'}</Headings.H3>
        </div>
      </div>
      {contactName &&
        <div className="row">
          <div className="col-xs-12">
            <p><b>Contact Name:</b> {contactName}</p>
          </div>
        </div>
      }
      {contactAddressObj !== undefined && (
        <NTContactAddressForm
          contactAddressObj={contactAddressObj}
          contactAddressTypesArray={contactAddressObj?.personNationalTeamContactAddressType}
          submitButtonText={contactAddressObj ? 'Save' : 'Add Address'}
          onSubmitFormCallback={onSubmitContactForm}
          onSecondaryButtonClicked={onCancelClicked} />
      )}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment >
  );
};

export default NationalTeamContactAddressDetail;