import { Fragment } from 'react';

import useNTRegistrationFrequentFlyer from './UseNTRegistrationFrequentFlyer';

import FrequentFlyerFormGrid from '../components/grids/frequentFlyer/FrequentFlyerFormGrid';
import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';

const NTRegistrationFrequentFlyer = () => {
  const {
    isLoading,
    personId,
    gridData,
    onContinueClicked,
    onBackClicked,
  } = useNTRegistrationFrequentFlyer();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Frequent Flyer'}>
        <FrequentFlyerFormGrid
          gridData={gridData}
          personId={personId}
          onSubmitFormCallbackPrimary={onContinueClicked}
          onSubmitFormCallbackSecondary={onBackClicked} />
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationFrequentFlyer;