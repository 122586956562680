import { Fragment } from 'react';

import useNTRegistrationSupportStaffRoleDetail from './UseNTRegistrationSupportStaffRoleDetail';

import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';
import NTSupportStaffAddRoleForm from '../../../components/forms/nationalTeamSupportStaff/NTSupportStaffAddRoleForm';
import NTSupportStaffEditRoleForm from '../../../components/forms/nationalTeamSupportStaff/NTSupportStaffEditRoleForm';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import { formatDate } from '../../../../common/utils/DateFunctions';

const NTRegistrationSupportStaffRoleDetail = () => {
  const {
    isLoading,
    state,
    supportStaffObj,
    supportStaffRoleObj,
    onCancelClicked,
    onDupPopupCloseClicked,
    onSubmitSupportStaffRoleAddForm,
    onSubmitSupportStaffRoleEditForm
  } = useNTRegistrationSupportStaffRoleDetail();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={supportStaffRoleObj ? 'Edit Support Staff Role' : 'Add Support Staff Role'}>
        {supportStaffObj &&
          <Fragment>
            {supportStaffRoleObj
              ? <NTSupportStaffEditRoleForm supportStaffRoleObj={supportStaffRoleObj} onSubmitFormCallback={onSubmitSupportStaffRoleEditForm} onSecondaryButtonClicked={onCancelClicked} />
              : <NTSupportStaffAddRoleForm supportStaffObj={supportStaffObj} onSubmitFormCallback={onSubmitSupportStaffRoleAddForm} onSecondaryButtonClicked={onCancelClicked} />
            }
          </Fragment>
        }
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={'Duplicate Role Error'}
          displayPopUp={state.displayDupRoleModal === true}
          onModalCanceled={onDupPopupCloseClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>Selected dates conflict with an existing role ({state.dupRoleObj?.supportStaffType?.typeName}, {formatDate(state.dupRoleObj?.effectiveDate)} - {formatDate(state.dupRoleObj?.expirationDate)})</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onDupPopupCloseClicked}>Close</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationSupportStaffRoleDetail;