import { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import PlusIcon from '../../../../common/components/icons/PlusIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';
import { formatPhoneNumber } from '../../../../common/utils/validation';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGridRow = ({ role, onEditRoleClicked, onDeleteRoleClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{role.supportStaffType?.typeName || ''}</span>&nbsp;
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onDeleteRoleClicked(e, role)}>
        <DeleteIcon toolTipText="Delete Support Staff Role" />
      </button>
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEditRoleClicked(e, role)}>
        <EditIcon toolTipText="Edit Support Staff Role" />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Staff Role
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {role.supportStaffType?.typeName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Effective Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {role.effectiveDate ? formatDate(role.effectiveDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Expiration Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {role.expirationDate ? formatDate(role.expirationDate) : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const DetailGrid = ({ nationalTeamSupportStaffType, onEditRoleClicked, onDeleteRoleClicked }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(nationalTeamSupportStaffType) && nationalTeamSupportStaffType.length > 0 &&
      nationalTeamSupportStaffType.map((role, i) => <DetailGridRow key={i} role={role} onEditRoleClicked={onEditRoleClicked} onDeleteRoleClicked={onDeleteRoleClicked} />)
    }
  </div>
);

const GridRowIcons = ({ supportStaff, onAddRoleClicked, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onDeleteClicked(e, supportStaff)}>
      <DeleteIcon toolTipText='Delete Support Staff' />
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEditClicked(e, supportStaff)}>
      <EditIcon toolTipText='Edit Support Staff' />
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onAddRoleClicked(e, supportStaff)}>
      <PlusIcon toolTipText="Add Support Staff Role" />
    </button>
  </Fragment>
);

const GridRow = ({ supportStaff, onAddRoleClicked, onEditClicked, onEditRoleClicked, onDeleteClicked, onDeleteRoleClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{supportStaff.firstName || ''} {supportStaff.lastName || ''}</span>&nbsp;
        <GridRowIcons supportStaff={supportStaff} onAddRoleClicked={onAddRoleClicked} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Name
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {supportStaff.firstName || supportStaff.lastName ? `${supportStaff.firstName || ''} ${supportStaff.lastName || ''}` : <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Email
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {supportStaff.email || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Phone Number
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {supportStaff.phoneNumber ? formatPhoneNumber(supportStaff.phoneNumber) : <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
    <DetailGrid nationalTeamSupportStaffType={supportStaff.nationalTeamSupportStaffType} onEditRoleClicked={onEditRoleClicked} onDeleteRoleClicked={onDeleteRoleClicked} />
  </Fragment>
);

const NTSupportStaffGridSmall = ({ gridData, isLoading, onAddRoleClicked, onEditClicked, onEditRoleClicked, onDeleteClicked, onDeleteRoleClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((supportStaff, i) => <GridRow key={i} supportStaff={supportStaff} onAddRoleClicked={onAddRoleClicked} onEditClicked={onEditClicked} onEditRoleClicked={onEditRoleClicked} onDeleteClicked={onDeleteClicked} onDeleteRoleClicked={onDeleteRoleClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Support Staff</div>
            </div>
          </div>
        )
    }
  </div>
);

export default NTSupportStaffGridSmall;