import { useContext } from "react";

import BioEntryData from "./BioEntryData";
import { BioEntryContext } from "./BioEntryContextProvider";

const useBioEntryData = () => {
  const [bioEntryState, setBioEntryState] = useContext(BioEntryContext);

  const getBioEntries = () => {
    return BioEntryData.getBioEntryData(bioEntryState, setBioEntryState);
  };

  const resetBioEntryState = () => {
    setBioEntryState({
      ...BioEntryData.INITIAL_BIO_ENTRY_STATE
    });
  };

  return {
    bioEntryState,
    getBioEntries,
    resetBioEntryState
  };
};

export default useBioEntryData;