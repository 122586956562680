import EditIcon from '../../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ socialMedia, onEditClicked, onDeleteClicked }) => (
  <tr>
    <td>{socialMedia.socialMediaType?.typeName || ''}</td>
    <td>{socialMedia.socialHandle || ''}</td>
    <td className='pull-right'>
      <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditClicked(e, socialMedia)}>
        <EditIcon />
      </button>
      <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteClicked(e, socialMedia)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const SocialMediaGridLarge = ({ gridData, isLoading, onEditClicked, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Social Media Platform</th>
        <th>Handle / Username / Url</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="3">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((socialMedia, i) => <GridRow key={i} socialMedia={socialMedia} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />)
          : <tr><td colSpan="3">No Socials</td></tr>
      }
    </tbody>
  </table>
);

export default SocialMediaGridLarge;