import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";
import useSecurityData from "../../../../common/state/security/UseSecurityData";

const INITIAL_STATE = {
  hasPendingInvite: null
};

const useNTRegistrationEntry = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { userInfo, login } = useSecurityData();
  const { groupMemberRegistrationState, getNTGroupMemberRegistration, onNTRegistrationError } = useNTRegistrationData();
  const [state, setState] = useState(INITIAL_STATE);

  const onLoginClicked = (event) => {
    try {
      event?.preventDefault();

      login();
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onEnterClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(navRoutes.NT_REGISTRATION_BIO_INFO?.route, { state: { groupMemberRegistrationObj: groupMemberRegistrationState.objData } });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onGoToLandingPageClicked = (event) => {
    try {
      event?.preventDefault();

      window.location.href = `${import.meta.env.VITE_LOGIN_URL}`;
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    async function loadNTGroupMemberRegistration() {
      try {
        if (userInfo.isLoggedIn === true) {
          const getNTGroupMemberRegistrationResult = await getNTGroupMemberRegistration(0);

          if (getNTGroupMemberRegistrationResult.objData?.personNationalTeamGroupId > 0) {
            setState({
              ...state,
              hasPendingInvite: true
            });
          } else {
            setState({
              ...state,
              hasPendingInvite: false
            });
          }
        }
      } catch (e) {
        if (e.code === 404 || e.code === 403) {
          setState({
            ...state,
            hasPendingInvite: false
          });
        } else {
          onNTRegistrationError(e);
        }
      }
    }
    loadNTGroupMemberRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: groupMemberRegistrationState.isObjLoading,
    isLoggedIn: userInfo.isLoggedIn === true,
    hasPendingInvite: state.hasPendingInvite === true,
    onLoginClicked,
    onEnterClicked,
    onGoToLandingPageClicked
  };
};

export default useNTRegistrationEntry;