import { Fragment } from 'react';

import HideIcon from '../../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../../common/components/icons/ShowIcon';
import EditIcon from '../../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const DetailTable = ({ funFactDescription }) => (
  <div className={global.SmallTableDetailTable}>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableDetailRowHead}>
        Fun Fact Description
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {funFactDescription || <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const GridRow = ({ funFact, expandedId, onEditClicked, onDeleteClicked, onExpandClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span>&nbsp;</span>&nbsp;
        <div className={global.FloatRight}>
          <button className={global.IconButtonMobileMargin}
            type="button"
            onClick={(e) => onExpandClicked(e, funFact.personFunFactsId)}>
            {expandedId === funFact.personFunFactsId ? <HideIcon /> : <ShowIcon />}
          </button>
          <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onDeleteClicked(e, funFact)}>
            <DeleteIcon />
          </button>
          <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEditClicked(e, funFact)}>
            <EditIcon />
          </button>
        </div>
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Fun Fact Name
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {funFact.funFacts?.funFactsName || <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
    {expandedId === funFact.personFunFactsId &&
      <DetailTable funFactDescription={funFact.textValue} />
    }
  </Fragment>
);

const FunFactsGridSmall = ({ gridData, isLoading, expandedId, onEditClicked, onDeleteClicked, onExpandClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((funFact, i) => <GridRow key={i} funFact={funFact} expandedId={expandedId} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} onExpandClicked={onExpandClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Fun Facts</div>
            </div>
          </div>
        )
    }
  </div>
);

export default FunFactsGridSmall;