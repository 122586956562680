import { createContext, useState } from 'react';

import NTRegistrationLeftNavData from './NTRegistrationLeftNavData';

export const NTRegistrationLeftNavStateContext = createContext();

const INITIAL_STATE = {
  isLoaded: false,
  arrayData: NTRegistrationLeftNavData.INITIAL_ARRAY_DATA,
  objData: NTRegistrationLeftNavData.INITIAL_OBJ_DATA
};

const NTRegistrationLeftNavContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <NTRegistrationLeftNavStateContext.Provider value={stateHook}>
      {children}
    </NTRegistrationLeftNavStateContext.Provider>
  );
};

export default NTRegistrationLeftNavContextProvider;