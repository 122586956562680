import { useEffect } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationPersonData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationPerson/UseNTRegistrationPersonData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_FREQUENT_FLYER } from "../utils/NTRegistrationConstants";

const useNTRegistrationFrequentFlyer = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { onNTRegistrationError } = useNTRegistrationData();
  const { updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationPersonState, getNTRegistrationPerson, putNTRegistrationPerson } = useNTRegistrationPersonData()

  const onContinueClicked = async (frequentFlyerDetails) => {
    try {
      const personId = nationalTeamRegistrationPersonState.objData.personId;
      const newPersonNTObj = JSON.parse(JSON.stringify(nationalTeamRegistrationPersonState.objData));
      newPersonNTObj.frequentFlyerDetails = frequentFlyerDetails;

      const putNTRegistrationPersonResult = await putNTRegistrationPerson(personId, newPersonNTObj);

      if (putNTRegistrationPersonResult) {
        navigate(navRoutes.NT_REGISTRATION_COACH?.route);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onBackClicked = async (frequentFlyerDetails) => {
    try {
      const personId = nationalTeamRegistrationPersonState.objData.personId;
      const newPersonNTObj = JSON.parse(JSON.stringify(nationalTeamRegistrationPersonState.objData));
      newPersonNTObj.frequentFlyerDetails = frequentFlyerDetails;

      const putNTRegistrationPersonResult = await putNTRegistrationPerson(personId, newPersonNTObj);

      if (putNTRegistrationPersonResult) {
        navigate(navRoutes.NT_REGISTRATION_SUPPORT_STAFF?.route);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    async function loadNTRegistrationPerson() {
      try {
        updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_FREQUENT_FLYER, true);

        if (nationalTeamRegistrationPersonState.isObjLoaded !== true) {
          await getNTRegistrationPerson(0);
        }
      } catch (e) {
        onNTRegistrationError(e);
      }
    }
    loadNTRegistrationPerson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamRegistrationPersonState.isObjLoaded]);

  return {
    isLoading: nationalTeamRegistrationPersonState.isObjLoading,
    personId: nationalTeamRegistrationPersonState.objData?.personId,
    gridData: nationalTeamRegistrationPersonState.objData?.frequentFlyerDetails || [],
    onContinueClicked,
    onBackClicked
  };
};

export default useNTRegistrationFrequentFlyer;