import { Fragment } from 'react';

import NTSupportStaffGridLarge from './NTSupportStaffGridLarge';
import NTSupportStaffGridSmall from './NTSupportStaffGridSmall';

const NTSupportStaffGrid = ({ gridData, isLoading, onAddRoleClicked, onEditClicked, onEditRoleClicked, onDeleteClicked, onDeleteRoleClicked }) => (
  <Fragment>
    <NTSupportStaffGridLarge
      gridData={gridData}
      isLoading={isLoading}
      onAddRoleClicked={onAddRoleClicked}
      onEditClicked={onEditClicked}
      onEditRoleClicked={onEditRoleClicked}
      onDeleteClicked={onDeleteClicked}
      onDeleteRoleClicked={onDeleteRoleClicked} />
    <NTSupportStaffGridSmall
      gridData={gridData}
      isLoading={isLoading}
      onAddRoleClicked={onAddRoleClicked}
      onEditClicked={onEditClicked}
      onEditRoleClicked={onEditRoleClicked}
      onDeleteClicked={onDeleteClicked}
      onDeleteRoleClicked={onDeleteRoleClicked} />
  </Fragment>
);

export default NTSupportStaffGrid;