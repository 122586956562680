import { useState } from 'react';

import BirthCertificateData from './BirthCertificateData';

const useBirthCertificateData = () => {
  const [birthCertificateFileUploadState, setBirthCertificateFileUploadState] = useState(BirthCertificateData.INITIAL_BIRTH_CERTIFICATE_FILE_UPLOAD_STATE);
  const [birthCertificateFileDownloadState, setBirthCertificateFileDownloadState] = useState(BirthCertificateData.INITIAL_BIRTH_CERTIFICATE_FILE_DOWNLOAD_STATE);

  const postBirthCertificateFile = (file) => {
    BirthCertificateData.postBirthCertificateFileData(file, birthCertificateFileUploadState, setBirthCertificateFileUploadState);
  };

  const getBirthCertificateFile = (fileUrl) => {
    return BirthCertificateData.getBirthCertificateFileData(fileUrl, birthCertificateFileDownloadState, setBirthCertificateFileDownloadState);
  };

  const getFileNameFromSavedUploadUrl = () => {
    if (birthCertificateFileUploadState.isObjLoaded === true) {
      const fileNameWithDateTime = birthCertificateFileUploadState.objData.uploadUrl.replace(BirthCertificateData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return birthCertificateFileUploadState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    try {
      const fileNameWithDateTime = url.replace(BirthCertificateData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return decodeURI(fileName) || '';
    } catch (e) {
      return url;
    }
  };

  return {
    birthCertificateFileUploadState,
    birthCertificateFileDownloadState,
    postBirthCertificateFile,
    getBirthCertificateFile,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl
  };
};

export default useBirthCertificateData;