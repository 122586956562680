/* eslint-disable react/no-unescaped-entities */
import { Fragment } from "react";

import useNTRegistrationEntry from "./UseNTRegistrationEntry";

import NTRegistrationLoadingModal from "../components/dialogs/NTRegistrationLoadingModal";

import Headings from "../../../../common/components/headings/Headings";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";

import style from '../NTRegistration.module.css';

const NTRegistrationEntry = () => {
  const {
    isLoading,
    isLoggedIn,
    hasPendingInvite,
    onLoginClicked,
    onEnterClicked,
    onGoToLandingPageClicked
  } = useNTRegistrationEntry();

  return (
    <Fragment>
      <div className='row'>
        <div className="col-xs-12">
          <Headings.H2 excludeBar={true} className={style.CenterAlign}>National Team Registration</Headings.H2>
          <hr className="usas-bar-turmeric" />
          <Headings.H3 excludeBar={true} className={style.CenterAlign}>Welcome</Headings.H3>
        </div>
      </div>
      <div className="row">
        {isLoggedIn !== true
          ? (
            <Fragment>
              <div className='col-xs-12 usas-extra-top-margin usas-extra-bottom-margin'>
                <div className={style.CenterAlign}>
                  You are currently not logged in to the USA Swimming site.
                  <br /><br />
                  Please click 'Login' to continue.
                </div>
              </div>
              <div className='col-xs-12 usas-extra-top-margin'>
                <div className={style.CenterAlign}>
                  <PrimaryButton type='button' onClick={onLoginClicked}>Login</PrimaryButton>
                </div>
              </div>
            </Fragment>
          ) : hasPendingInvite === true
            ? (
              <Fragment>
                <div className='col-xs-12 usas-extra-top-margin usas-extra-bottom-margin'>
                  <div className={style.CenterAlign}>
                    Click 'Enter' to begin the National Team Registration process.
                  </div>
                </div>
                <div className='col-xs-12 usas-extra-top-margin'>
                  <div className={style.CenterAlign}>
                    <PrimaryButton type='button' onClick={onEnterClicked}>Enter</PrimaryButton>
                  </div>
                </div>
              </Fragment>
            ) : hasPendingInvite === false
              ? (
                <Fragment>
                  <div className='col-xs-12 usas-extra-top-margin usas-extra-bottom-margin'>
                    <div className={style.CenterAlign}>
                      No active National Team invites found.
                    </div>
                  </div>
                  <div className='col-xs-12 usas-extra-top-margin'>
                    <div className={style.CenterAlign}>
                      <SecondaryButton type='button' onClick={onGoToLandingPageClicked}>Back To Landing Page</SecondaryButton>
                    </div>
                  </div>
                </Fragment>
              ) : <Fragment />
        }
      </div>
      <NTRegistrationLoadingModal isLoading={isLoading} />
    </Fragment>
  );
};

export default NTRegistrationEntry;