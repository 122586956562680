import { Fragment } from 'react';

import useNTRegistrationFunFactsDetail from './UseNTRegistrationFunFactsDetail';

import FunFactsEditForm from '../components/forms/funFacts/FunFactsEditForm';
import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';

const NTRegistrationFunFactsDetail = () => {
  const {
    isLoading,
    funFactObj,
    onCancelClicked,
    onSubmitFunFactsEditForm
  } = useNTRegistrationFunFactsDetail();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Edit Fun Fact'}>
        {funFactObj &&
          <FunFactsEditForm funFactObj={funFactObj} onSubmitFormCallback={onSubmitFunFactsEditForm} onSecondaryButtonClicked={onCancelClicked} />
        }
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationFunFactsDetail;