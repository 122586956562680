import { createContext, useState } from 'react';

import NTCoachOfRecordData from './NTCoachOfRecordData';

export const NTCoachOfRecordContext = createContext();

const NTCoachOfRecordContextProvider = ({ children }) => {
  const stateHook = useState(NTCoachOfRecordData.INITIAL_NATIONAL_TEAM_COACH_OF_RECORD_STATE);

  return (
    <NTCoachOfRecordContext.Provider value={stateHook}>
      {children}
    </NTCoachOfRecordContext.Provider>
  );
};

export default NTCoachOfRecordContextProvider;