import { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Outlet } from '../common/wrappers/ReactRouterDom';

import Member from './views/member/Member';
import MemberAdministration from './views/memberAdministration/MemberAdministration';
import HqOfferingManagement from './views/offeringManagement/offeringManagementHq/HqOfferingMangement';
import LscOfferingManagement from './views/offeringManagement/offeringManagementLsc/LscOfferingMangement';
import QuickAdd from './views/quickAddAthlete/QuickAdd';
import OfficialsTrackingSystem from './views/officialsTrackingSystem/OfficialsTrackingSystem';
import MiniMemberMerge from './views/miniMemberMerge/MiniMemberMerge';
import MemberSummaryDashboard from './views/memberSummaryDashboard/MemberSummaryDashboard';
import LSCReporting from './views/lscReporting/LSCReporting';
import HQReporting from './views/hqReporting/HqReporting';
import MemberCardLookup from './views/member/memberCardLookup/MemberCardLookup';
import EducationReporting from './views/educationReporting/EducationReporting';
import NTRegistration from './views/nationalTeamRegistration/NTRegistration';
import CommitteeReporting from './views/committeeReporting/CommitteeReporting';

import useNavRoutes from '../common/state/security/UseNavRoutes';
import PageNotFound from '../common/components/PageNotFound';
import UnexpectedError from '../common/components/UnexpectedError';
import LoadingModal from '../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../common/components/security/SecuredRouteElement';
import ErrorBoundary from '../common/components/errors/ErrorBoundary';
import ErrorBoundaryFallback from '../common/components/errors/ErrorBoundaryFallback';

const AppRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <Routes>
        <Route path={navRoutes['HOME']?.path} element={<Fragment><Outlet /></Fragment>}>
          <Route path={navRoutes['MEMBER_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEMBER_ROOT']}
                editElement={<Member />} />
            }
          />
          <Route path={navRoutes['MEMBER_ADMIN_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEMBER_ADMIN_ROOT']}
                editElement={<MemberAdministration />} />
            }
          />
          <Route path={navRoutes['OFFERING_MANAGMENT_ROOT_HQ']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['OFFERING_MANAGMENT_ROOT_HQ']}
                editElement={<HqOfferingManagement />} />
            }
          />
          <Route path={navRoutes['OFFERING_MANAGMENT_ROOT_LSC']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['OFFERING_MANAGMENT_ROOT_LSC']}
                editElement={<LscOfferingManagement />} />
            }
          />
          <Route path={navRoutes['QUICK_ADD_ATHLETE_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['QUICK_ADD_ATHLETE_ROOT']}
                editElement={<QuickAdd />} />
            }
          />
          <Route path={navRoutes['OTS_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['OTS_ROOT']}
                editElement={<OfficialsTrackingSystem />} />
            }
          />
          <Route path={navRoutes['MEMBER_MERGE']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEMBER_MERGE']}
                editElement={<MiniMemberMerge />} />
            }
          />
          <Route path={navRoutes['MEMBERSHIP_SUMMARY_DASHBOARD']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEMBERSHIP_SUMMARY_DASHBOARD']}
                editElement={<MemberSummaryDashboard />} />
            }
          />
          <Route path={navRoutes['MEMBER_CARD_SEARCH']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEMBER_CARD_SEARCH']}
                editElement={<MemberCardLookup />} />
            }
          />
          <Route path={navRoutes['LSC_REPORTING_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['LSC_REPORTING_ROOT']}
                editElement={<LSCReporting />} />
            }
          />
          <Route path={navRoutes['USA_SWIMMING_HQ_REPORTING']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['USA_SWIMMING_HQ_REPORTING']}
                editElement={<HQReporting />} />
            }
          />
          <Route path={navRoutes['EDUCATION_REPORTING']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['EDUCATION_REPORTING']}
                editElement={<EducationReporting />} />
            }
          />
          <Route path={navRoutes['NT_REGISTRATION_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['NT_REGISTRATION_ROOT']}
                editElement={<NTRegistration />} />
            } />
          <Route path={navRoutes['COMMITTEE_REPORTING']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['COMMITTEE_REPORTING']}
                editElement={<CommitteeReporting />} />
            } />
          <Route path="500" element={<UnexpectedError />} />
          <Route path="/*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;