import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const postNationalTeamAttachmentFileData = (file, state, setState) => {
  if (file) {
    const formData = new FormData();
    formData.append("name", file?.name || '');
    formData.append("file", file);

    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/nationalTeamAttachment`;
    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const getNationalTeamAttachmentFileData = (fileUrl, state, setState) => {
  if (fileUrl) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/nationalTeamAttachment?fileUrl=${fileUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const postNationalTeamAttachmentFileUpdateData = (file, oldFileUrl, state, setState) => {
  if (file && oldFileUrl) {
    const formData = new FormData();
    formData.append("name", file?.name || '');
    formData.append("file", file);
    formData.append("oldFileUrl", oldFileUrl);

    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/nationalTeamAttachment/update`;

    return api.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const deleteNationalTeamAttachmentFileData = (personNationalAttachmentUrl, state, setState) => {
  if (personNationalAttachmentUrl) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/nationalTeamAttachment?personNationalAttachmentUrl=${personNationalAttachmentUrl}`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const BASE_UPLOAD_URL = import.meta.env.VITE_MEMBER_DOCS_NATIONAL_TEAM_ATTACHMENTS_BASE_UPLOAD_URL;

const INITIAL_NATIONAL_TEAM_ATTACHMENT_FILE_UPLOAD_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_NATIONAL_TEAM_ATTACHMENT_FILE_DOWNLOAD_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_NATIONAL_TEAM_ATTACHMENT_FILE_UPDATE_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_NATIONAL_TEAM_ATTACHMENT_FILE_DELETE_STATE = {
  ...BASIC_INITIAL_STATE
};

const NationalTeamAttachmentFileData = {
  BASE_UPLOAD_URL,
  INITIAL_NATIONAL_TEAM_ATTACHMENT_FILE_UPLOAD_STATE,
  INITIAL_NATIONAL_TEAM_ATTACHMENT_FILE_DOWNLOAD_STATE,
  INITIAL_NATIONAL_TEAM_ATTACHMENT_FILE_UPDATE_STATE,
  INITIAL_NATIONAL_TEAM_ATTACHMENT_FILE_DELETE_STATE,
  postNationalTeamAttachmentFileData,
  getNationalTeamAttachmentFileData,
  postNationalTeamAttachmentFileUpdateData,
  deleteNationalTeamAttachmentFileData
};

export default NationalTeamAttachmentFileData;