import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationPersonData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationPerson/UseNTRegistrationPersonData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_SUPPORT_STAFF } from "../utils/NTRegistrationConstants";

const INITIAL_STATE = {
  deleteSupportStaffObj: {},
  deleteSupportStaffRoleObj: {},
  isLastRole: false,
  displayDeleteStaffPopup: false,
  displayDeleteStaffRolePopup: false
};

const useNTRegistrationSupportStaff = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { onNTRegistrationError } = useNTRegistrationData();
  const { updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationPersonState, getNTRegistrationPerson, putNTRegistrationPerson } = useNTRegistrationPersonData();
  const [state, setState] = useState(INITIAL_STATE);

  const onContinueClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_FREQUENT_FLYER?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_CONTACTS?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onAddSupportStaffClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(navRoutes.NT_REGISTRATION_SUPPORT_STAFF_DETAIL?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onAddSupportStaffRoleClicked = (event, supportStaffObj) => {
    try {
      event?.preventDefault();

      navigate(navRoutes.NT_REGISTRATION_SUPPORT_STAFF_ROLE_DETAIL?.route, { state: { supportStaffObj } });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onEditSupportStaffClicked = (event, supportStaffObj) => {
    try {
      event?.preventDefault();

      navigate(navRoutes.NT_REGISTRATION_SUPPORT_STAFF_DETAIL?.route, { state: { supportStaffObj } });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onEditSupportStaffRoleClicked = (event, supportStaffRoleObj) => {
    try {
      event?.preventDefault();
      const supportStaffObj = nationalTeamRegistrationPersonState.objData?.supportStaffMembers.find((x) => x.nationalTeamSupportStaffId === supportStaffRoleObj.nationalTeamSupportStaffId);

      navigate(navRoutes.NT_REGISTRATION_SUPPORT_STAFF_ROLE_DETAIL?.route, { state: { supportStaffObj, supportStaffRoleObj } });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeleteSupportStaffClicked = (event, supportStaffObj) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDeleteStaffPopup: true,
        deleteSupportStaffObj: supportStaffObj
      });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeleteSupportStaffRoleClicked = (event, supportStaffRoleObj) => {
    try {
      event?.preventDefault();
      const targetSupportStaff = nationalTeamRegistrationPersonState.objData?.supportStaffMembers.find((x) => x.nationalTeamSupportStaffId === supportStaffRoleObj.nationalTeamSupportStaffId);

      setState({
        ...state,
        displayDeleteStaffRolePopup: true,
        deleteSupportStaffObj: targetSupportStaff,
        deleteSupportStaffRoleObj: supportStaffRoleObj,
        isLastRole: targetSupportStaff.nationalTeamSupportStaffType.length === 1
      });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeleteStaffPopupConfirmClicked = async (event) => {
    try {
      event?.preventDefault();
      const personId = nationalTeamRegistrationPersonState.objData.personId;
      const newNTPersonObj = JSON.parse(JSON.stringify(nationalTeamRegistrationPersonState.objData));
      const targetIndex = newNTPersonObj.supportStaffMembers.findIndex((x) => x.nationalTeamSupportStaffId === state.deleteSupportStaffObj.nationalTeamSupportStaffId);
      newNTPersonObj.supportStaffMembers.splice(targetIndex, 1);

      setState({ ...INITIAL_STATE });

      await putNTRegistrationPerson(personId, newNTPersonObj);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeleteStaffRolePopupConfirmClicked = async (event) => {
    try {
      event?.preventDefault();
      const personId = nationalTeamRegistrationPersonState.objData.personId;
      const newNTPersonObj = JSON.parse(JSON.stringify(nationalTeamRegistrationPersonState.objData));
      const targetStaffIndex = newNTPersonObj.supportStaffMembers.findIndex((x) => x.nationalTeamSupportStaffId === state.deleteSupportStaffObj.nationalTeamSupportStaffId);

      if (state.isLastRole === true) {
        newNTPersonObj.supportStaffMembers.splice(targetStaffIndex, 1);
      } else {
        const targetRoleIndex = newNTPersonObj.supportStaffMembers[targetStaffIndex].nationalTeamSupportStaffType.findIndex((x) => x.nationalTeamSupportStaffTypeId === state.deleteSupportStaffRoleObj.nationalTeamSupportStaffTypeId)
        newNTPersonObj.supportStaffMembers[targetStaffIndex].nationalTeamSupportStaffType.splice(targetRoleIndex, 1);
      }

      setState({ ...INITIAL_STATE });

      await putNTRegistrationPerson(personId, newNTPersonObj);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeletePopupCancelClicked = (event) => {
    try {
      event?.preventDefault();

      setState({ ...INITIAL_STATE });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    async function loadNTRegistrationPerson() {
      try {
        updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_SUPPORT_STAFF, true);

        if (nationalTeamRegistrationPersonState.isObjLoaded !== true) {
          await getNTRegistrationPerson(0);
        }
      } catch (e) {
        onNTRegistrationError(e);
      }
    }
    loadNTRegistrationPerson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamRegistrationPersonState.isObjLoaded])

  return {
    isLoading: nationalTeamRegistrationPersonState.isObjLoading,
    gridData: nationalTeamRegistrationPersonState.objData?.supportStaffMembers || [],
    state,
    onContinueClicked,
    onBackClicked,
    onAddSupportStaffClicked,
    onAddSupportStaffRoleClicked,
    onEditSupportStaffClicked,
    onEditSupportStaffRoleClicked,
    onDeleteSupportStaffClicked,
    onDeleteSupportStaffRoleClicked,
    onDeleteStaffPopupConfirmClicked,
    onDeleteStaffRolePopupConfirmClicked,
    onDeletePopupCancelClicked
  };
};

export default useNTRegistrationSupportStaff;