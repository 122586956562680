import { Fragment } from 'react';

import FunFactsGridLarge from './FunFactsGridLarge';
import FunFactsGridSmall from './FunFactsGridSmall';

import useExpandedRow from '../../../../../../common/components/grids/UseExpandedRow';

const FunFactsGrid = ({ isLoading, gridData, onEditClicked, onDeleteClicked }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <FunFactsGridLarge
        gridData={gridData}
        isLoading={isLoading}
        expandedId={expandedId}
        onEditClicked={onEditClicked}
        onDeleteClicked={onDeleteClicked}
        onExpandClicked={onClick} />
      <FunFactsGridSmall
        gridData={gridData}
        isLoading={isLoading}
        expandedId={expandedId}
        onEditClicked={onEditClicked}
        onDeleteClicked={onDeleteClicked}
        onExpandClicked={onClick} />
    </Fragment>
  );
};

export default FunFactsGrid;