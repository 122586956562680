import { Fragment } from 'react';

import NTContactsGridLarge from './NTContactsGridLarge';
import NTContactsGridSmall from './NTContactsGridSmall';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

const NTContactsGrid = ({ gridData, isLoading, onAddAddressClicked, onEditClicked, onEditAddressClicked,
  onDeleteClicked, onDeleteAddressClicked }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <NTContactsGridLarge
        gridData={gridData}
        isLoading={isLoading}
        expandedId={expandedId}
        onAddAddressClicked={onAddAddressClicked}
        onEditClicked={onEditClicked}
        onDeleteClicked={onDeleteClicked}
        onEditAddressClicked={onEditAddressClicked}
        onDeleteAddressClicked={onDeleteAddressClicked}
        onExpandClicked={onClick} />
      <NTContactsGridSmall
        gridData={gridData}
        isLoading={isLoading}
        expandedId={expandedId}
        onAddAddressClicked={onAddAddressClicked}
        onEditClicked={onEditClicked}
        onDeleteClicked={onDeleteClicked}
        onEditAddressClicked={onEditAddressClicked}
        onDeleteAddressClicked={onDeleteAddressClicked}
        onExpandClicked={onClick} />
    </Fragment>
  );
};

export default NTContactsGrid;