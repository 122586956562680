import { DEFAULT_PHONE_NUMBER_AREA_CODE, DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO } from '../../../../common/utils/Constants';
import { isValidName, isValidEmail, isValidUSOrCanadianPhoneNumber, isValidMexicanPhoneNumber } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  // First Name
  if (formState.firstName.trim() === '') {
    errors.firstName = 'First Name is required';
  } else if (!isValidName(formState.firstName.trim())) {
    errors.firstName = 'First Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  // Last Name
  if (formState.lastName.trim() === '') {
    errors.lastName = 'Last Name is required';
  } else if (!isValidName(formState.lastName.trim())) {
    errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  // Relationship
  if (formState.contactRelationshipTypeId < 0) {
    errors.contactRelationshipTypeId = 'Relationship is required';
  }

  // Email
  if (formState.email.trim() !== '') {
    if (!isValidEmail(formState.email.trim())) {
      errors.email = 'Email must be in a valid format';
    } else if (formState.email.trim().length > 512) {
      errors.email = 'Email cannot exceed 512 characters'
    }
  }

  // Phone Number
  if (formState.phoneNumber.trim() !== '' && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE
    && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO) {
    if (!isValidUSOrCanadianPhoneNumber(formState.phoneNumber) && !isValidMexicanPhoneNumber(formState.phoneNumber)) {
      errors.phoneNumber = 'Must enter a valid US, Canadian, or Mexican phone number in +1/+52 (XXX) XXX-XXXX format'
    }
  }

  return errors;
};

const NTContactFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default NTContactFormValidation;