export const localValidate = (formState) => {
  let errors = {};

  if (!formState.funFactsDescription.trim()) {
    errors.funFactsDescription = 'Fun Fact Description is required';
  } else if (formState.funFactsDescription.trim().length > 7000) {
    errors.funFactsDescription = 'Fun Fact Description cannot exceed 7000 characters';
  }

  return errors;
};

const FunFactsEditFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default FunFactsEditFormValidation;