import { Fragment } from 'react';

import useNTRegistrationContactsDetail from './UseNTRegistrationContactsDetail';

import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';
import NTContactForm from '../../../components/forms/nationalTeamContacts/NTContactForm';

const NTRegistrationContactsDetail = () => {
  const {
    isLoading,
    contactObj,
    isSelfContact,
    hasOtherSelfContact,
    onCancelClicked,
    onSubmitContactForm
  } = useNTRegistrationContactsDetail();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={contactObj ? 'Edit Contact' : 'Add Contact'}>
        <NTContactForm
          contactObj={contactObj}
          contactPhonesArray={contactObj?.personNationalTeamRegistrationContactPhone}
          submitButtonText={contactObj ? 'Save' : 'Add Contact'}
          isRelationshipTypeReadonly={isSelfContact === true}
          excludeSelfRelationshipType={hasOtherSelfContact === true}
          onSubmitFormCallback={onSubmitContactForm}
          onSecondaryButtonClicked={onCancelClicked} />
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationContactsDetail;