import { Fragment } from 'react';

import useMemberInfo from './UseMemberInfo';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const MemberInfoRead = () => {
  const {
    basePersonState,
    personGeneralState,
    state,
    formState
  } = useMemberInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Info</Headings.H3>
        </div>
      </div>
      <div className={[global.HeaderText, 'row'].join(' ')}>
        <div className="col-xs-6 col-md-3">
          <p><b>First Name:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p >{formState.firstName || <span>&nbsp;</span>}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Middle Name:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p >{formState?.middleName || <span>&nbsp;</span>}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Last Name:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p >{formState.lastName || <span>&nbsp;</span>}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Preferred Name:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p >{formState?.preferredName || <span>&nbsp;</span>}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Suffix:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p >{formState?.suffix || <span>&nbsp;</span>}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Maiden Name:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p >{formState?.maidenName || <span>&nbsp;</span>}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Email:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p>{state.showEmail === true ? formState.email : 'Not collected for members under 16'}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Birth Date:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p>{formState.birthDate || <span>&nbsp;</span>}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>US Citizen:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p>{formState.isUsCitizen === true ? 'Yes' : formState.isUsCitizen === false ? 'No' : 'No Info'}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Competition Category:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p>{formState.competitionGender || <span>&nbsp;</span>}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <span className={formState.genderIdentity === '' ? global.HideComponent : global.DisplayComponent}>
            <p><b>Gender Identity:</b></p>
          </span>
        </div>
        <div className="col-xs-6 col-md-3">
          <span className={formState.genderIdentity === '' ? global.HideComponent : global.DisplayComponent}>
            <p>{formState.genderIdentity || <span>&nbsp;</span>}</p>
          </span>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || personGeneralState.isObjLoading} />
    </Fragment>
  );
};

export default MemberInfoRead;