import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationContactsData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationContacts/UseNTRegistrationContactsData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";
import useAddressTypeData from "../../../../common/state/addressType/UseAddressTypeData";

import { LEFT_NAV_ITEM_NAME_CONTACTS } from "../utils/NTRegistrationConstants";
import { formatPhoneNumber } from "../../../../common/utils/validation";
import { DEFAULT_ID } from "../../../../common/utils/Constants";

const SELF_CONTACT_NAME = 'Self';

const INITIAL_STATE = {
  displayDeleteContactModal: false,
  displayDeleteAddressModal: false,
  deleteContactObj: {},
  deleteAddressObj: {}
};

const INITIAL_REQUIREMENTS_STATE = {
  areAllRequirementsMet: true,
  requirements: []
};

const useNTRegistrationContacts = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { addressTypeState, getAddressTypes } = useAddressTypeData();
  const { onNTRegistrationError } = useNTRegistrationData();
  const { updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationContactsState, getNTRegistrationContacts, putNTRegistrationContacts
  } = useNTRegistrationContactsData();
  const [requirementsState, setRequirementsState] = useState(INITIAL_REQUIREMENTS_STATE);
  const [gridState, setGridState] = useState([]);
  const [state, setState] = useState(INITIAL_STATE);

  const onContinueClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_SUPPORT_STAFF?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_BIO_INFO?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onAddContactClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_CONTACTS_DETAIL?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onAddAddressClicked = (event, personNationalTeamRegistrationContactId) => {
    try {
      event?.preventDefault();
      const contactObj = nationalTeamRegistrationContactsState.objData.personNationalTeamRegistrationContact.find((x) => x.personNationalTeamRegistrationContactId === personNationalTeamRegistrationContactId);

      navigate(navRoutes.NT_REGISTRATION_CONTACTS_ADDRESS_DETAIL?.route, { state: { contactObj } });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onEditContactClicked = (event, personNationalTeamRegistrationContactId) => {
    try {
      event?.preventDefault();
      const contactObj = nationalTeamRegistrationContactsState.objData.personNationalTeamRegistrationContact.find((x) => x.personNationalTeamRegistrationContactId === personNationalTeamRegistrationContactId);

      navigate(navRoutes.NT_REGISTRATION_CONTACTS_DETAIL?.route, { state: { contactObj } });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onEditAddressClicked = (event, personNationalTeamRegistrationContactId, personNationalTeamRegistrationContactAddressId) => {
    try {
      event?.preventDefault();
      const contactObj = nationalTeamRegistrationContactsState.objData.personNationalTeamRegistrationContact.find((x) => x.personNationalTeamRegistrationContactId === personNationalTeamRegistrationContactId);
      const contactAddressObj = contactObj.personNationalTeamRegistrationContactAddress.find((x) => x.personNationalTeamRegistrationContactAddressId === personNationalTeamRegistrationContactAddressId);

      navigate(navRoutes.NT_REGISTRATION_CONTACTS_ADDRESS_DETAIL?.route, { state: { contactObj, contactAddressObj } });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeleteContactClicked = (event, contactObj) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDeleteContactModal: true,
        deleteContactObj: contactObj
      });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeleteAddressClicked = (event, contactAddressObj) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDeleteAddressModal: true,
        deleteAddressObj: contactAddressObj
      });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeleteContactModalConfirmClicked = async (event) => {
    try {
      event?.preventDefault();
      const personId = nationalTeamRegistrationContactsState.objData.personId;
      const newPersonNTContactsObj = JSON.parse(JSON.stringify(nationalTeamRegistrationContactsState.objData));
      const targetIndex = newPersonNTContactsObj.personNationalTeamRegistrationContact.findIndex((x) => x.personNationalTeamRegistrationContactId === state.deleteContactObj.contactId);
      newPersonNTContactsObj.personNationalTeamRegistrationContact.splice(targetIndex, 1);

      setState({ ...INITIAL_STATE });

      const putNTRegistrationContactsResult = await putNTRegistrationContacts(personId, newPersonNTContactsObj);

      if (putNTRegistrationContactsResult) {
        const newRequirementsState = createNewRequirementsState(putNTRegistrationContactsResult.objData.personNationalTeamRegistrationContact);

        updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_CONTACTS, newRequirementsState.areAllRequirementsMet);
        setRequirementsState(newRequirementsState);
        setGridState(createNewGridState(putNTRegistrationContactsResult.objData.personNationalTeamRegistrationContact));
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeleteAddressModalConfirmClicked = async (event) => {
    try {
      event?.preventDefault();
      const personId = nationalTeamRegistrationContactsState.objData.personId;
      const newPersonNTContactsObj = JSON.parse(JSON.stringify(nationalTeamRegistrationContactsState.objData));
      const targetContactIndex = newPersonNTContactsObj.personNationalTeamRegistrationContact.findIndex((x) => x.personNationalTeamRegistrationContactId === state.deleteAddressObj.contactId);
      const targetAddressIndex = newPersonNTContactsObj.personNationalTeamRegistrationContact[targetContactIndex].personNationalTeamRegistrationContactAddress.findIndex((x) => x.personNationalTeamRegistrationContactAddressId === state.deleteAddressObj.addressId);
      newPersonNTContactsObj.personNationalTeamRegistrationContact[targetContactIndex].personNationalTeamRegistrationContactAddress.splice(targetAddressIndex, 1);

      setState({ ...INITIAL_STATE });

      const putNTRegistrationContactsResult = await putNTRegistrationContacts(personId, newPersonNTContactsObj);

      if (putNTRegistrationContactsResult) {
        const newRequirementsState = createNewRequirementsState(putNTRegistrationContactsResult.objData.personNationalTeamRegistrationContact);

        updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_CONTACTS, newRequirementsState.areAllRequirementsMet);
        setRequirementsState(newRequirementsState);
        setGridState(createNewGridState(putNTRegistrationContactsResult.objData.personNationalTeamRegistrationContact));
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDeleteModalCancelClicked = (event) => {
    try {
      event?.preventDefault();

      setState({ ...INITIAL_STATE });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    async function loadNTRegistrationContacts() {
      try {
        if (addressTypeState.areOptionsLoaded !== true) {
          updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_CONTACTS);

          await getAddressTypes();
        } else if (nationalTeamRegistrationContactsState.isObjLoaded !== true) {
          updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_CONTACTS);

          await getNTRegistrationContacts(0);
        } else {
          const newRequirementsState = createNewRequirementsState(nationalTeamRegistrationContactsState.objData.personNationalTeamRegistrationContact);
          updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_CONTACTS, newRequirementsState.areAllRequirementsMet);

          setRequirementsState(newRequirementsState);
          setGridState(createNewGridState(nationalTeamRegistrationContactsState.objData.personNationalTeamRegistrationContact))
        }
      } catch (e) {
        onNTRegistrationError(e);
      }
    }
    loadNTRegistrationContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamRegistrationContactsState.isObjLoaded, addressTypeState.areOptionsLoaded]);

  function createNewGridState(personNationalTeamRegistrationContact) {
    const newGridState = [];

    if (Array.isArray(personNationalTeamRegistrationContact) && personNationalTeamRegistrationContact.length > 0) {
      for (const contact of personNationalTeamRegistrationContact) {
        if (contact.contactRelationshipType?.typeName === SELF_CONTACT_NAME && personNationalTeamRegistrationContact.filter((x) => x.contactRelationshipType?.typeName === SELF_CONTACT_NAME).length === 1) {
          newGridState.unshift({
            contactId: contact.personNationalTeamRegistrationContactId || DEFAULT_ID,
            name: `${contact.firstName || ''} ${contact.lastName || ''}`,
            relationship: contact.contactRelationshipType?.typeName || '',
            email: contact.emailAddress || '',
            phoneNumber: contact.personNationalTeamRegistrationContactPhone[0]?.phoneNumber ? formatPhoneNumber(contact.personNationalTeamRegistrationContactPhone[0]?.phoneNumber) : '',
            canDelete: false,
            contactAddresses: formatAddressesForGrid(contact.personNationalTeamRegistrationContactAddress)
          });
        } else {
          newGridState.push({
            contactId: contact.personNationalTeamRegistrationContactId || DEFAULT_ID,
            name: `${contact.firstName || ''} ${contact.lastName || ''}`,
            relationship: contact.contactRelationshipType?.typeName || '',
            email: contact.emailAddress || '',
            phoneNumber: contact.personNationalTeamRegistrationContactPhone[0]?.phoneNumber ? formatPhoneNumber(contact.personNationalTeamRegistrationContactPhone[0]?.phoneNumber) : '',
            canDelete: true,
            contactAddresses: formatAddressesForGrid(contact.personNationalTeamRegistrationContactAddress)
          });
        }
      }
    }

    return newGridState;
  }

  function formatAddressesForGrid(personNationalTeamRegistrationContactAddress) {
    const newContactAddresses = [];

    if (Array.isArray(personNationalTeamRegistrationContactAddress) && personNationalTeamRegistrationContactAddress.length > 0) {
      for (const contactAddress of personNationalTeamRegistrationContactAddress) {
        let addressTypeNames = '';

        for (const addressType of contactAddress.personNationalTeamRegistrationContactAddressType) {
          if (addressType.addressType?.typeName) {
            if (addressTypeNames) {
              addressTypeNames += `, ${addressType.addressType?.typeName}`;
            } else {
              addressTypeNames = addressType.addressType?.typeName;
            }
          }
        }

        newContactAddresses.push({
          addressId: contactAddress.personNationalTeamRegistrationContactAddressId || DEFAULT_ID,
          contactId: contactAddress.personNationalTeamRegistrationContactId || DEFAULT_ID,
          address: `${contactAddress.address?.address1 || ''}${contactAddress.address?.address2 ? ` ${contactAddress.address.address2}` : ''}`,
          city: contactAddress.address?.city || '',
          stateCode: contactAddress.address?.stateCode || '',
          postalCode: contactAddress.address?.postalCode || '',
          addressTypeNames
        });
      }
    }

    return newContactAddresses;
  }

  function createNewRequirementsState(personNationalTeamRegistrationContact) {
    const newRequirementsState = { areAllRequirementsMet: true, requirements: [] };

    for (const address of addressTypeState.arrayData) {
      if (address.isNationalTeamOnly === true) {
        const isRequirementMet = getIsRequirementMet(address.addressTypeId, personNationalTeamRegistrationContact);

        newRequirementsState.requirements.push({
          requirementText: `Must have a ${address.typeName} Address`,
          isRequirementMet
        });

        if (isRequirementMet !== true) {
          newRequirementsState.areAllRequirementsMet = false;
        }
      }
    }

    return newRequirementsState;
  }

  function getIsRequirementMet(addressTypeId, personNationalTeamRegistrationContact) {
    if (Array.isArray(personNationalTeamRegistrationContact) && personNationalTeamRegistrationContact.length > 0) {
      for (const contact of personNationalTeamRegistrationContact) {
        if (Array.isArray(contact.personNationalTeamRegistrationContactAddress) && contact.personNationalTeamRegistrationContactAddress.length > 0) {
          for (const contactAddress of contact.personNationalTeamRegistrationContactAddress) {
            if (Array.isArray(contactAddress.personNationalTeamRegistrationContactAddressType) && contactAddress.personNationalTeamRegistrationContactAddressType.length > 0) {
              if (contactAddress.personNationalTeamRegistrationContactAddressType.find((X) => X.addressTypeId === addressTypeId)) {
                return true;
              }
            }
          }
        }
      }
    }

    return false;
  }

  return {
    isLoading: nationalTeamRegistrationContactsState.isObjLoading || addressTypeState.isArrayLoading,
    gridData: gridState,
    requirements: requirementsState.requirements,
    state,
    onContinueClicked,
    onBackClicked,
    onAddAddressClicked,
    onAddContactClicked,
    onEditContactClicked,
    onEditAddressClicked,
    onDeleteContactClicked,
    onDeleteAddressClicked,
    onDeleteContactModalConfirmClicked,
    onDeleteAddressModalConfirmClicked,
    onDeleteModalCancelClicked
  };
};

export default useNTRegistrationContacts;