import { Fragment } from 'react';

import useNTRegistrationSocialMediaDetail from './UseNTRegistrationSocialMediaDetail';

import SocialMediaEditForm from '../components/forms/socialMedia/SocialMediaEditForm';
import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';

const NTRegistrationSocialMediaDetail = () => {
  const {
    isLoading,
    socialMediaObj,
    onCancelClicked,
    onSubmitSocialMediaEditForm
  } = useNTRegistrationSocialMediaDetail();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Edit Social Media'}>
        {socialMediaObj &&
          <SocialMediaEditForm socialMediaObj={socialMediaObj} onSubmitFormCallback={onSubmitSocialMediaEditForm} onSecondaryButtonClicked={onCancelClicked} />
        }
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationSocialMediaDetail;