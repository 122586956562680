import { Fragment } from 'react';

import useNTRegistrationFunFacts from './UseNTRegistrationFunFacts';

import FunFactsGrid from '../components/grids/funFacts/FunFactsGrid';
import FunFactsAddForm from '../components/forms/funFacts/FunFactsAddForm';
import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

const NTRegistrationFunFacts = () => {
  const {
    isLoading,
    gridData,
    state,
    onContinueClicked,
    onBackClicked,
    onEditFunFactClicked,
    onDeleteFunFactClicked,
    onDeletePopupCancelClicked,
    onDeletePopupConfirmClicked,
    onSubmitFunFactsAddForm
  } = useNTRegistrationFunFacts();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Fun Facts'}>
        <FunFactsAddForm onSubmitFormCallback={onSubmitFunFactsAddForm} />
        <FunFactsGrid
          gridData={gridData}
          isLoading={isLoading}
          onEditClicked={onEditFunFactClicked}
          onDeleteClicked={onDeleteFunFactClicked} />
        <div className="row pull-right usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>
          </div>
        </div>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={'Delete Fun Fact?'}
          displayPopUp={state.displayDeletePopup === true}
          onModalCanceled={onDeletePopupCancelClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p><b>{state.deleteFunFactObj?.funFacts?.funFactsName || ''}</b></p>
            </div>
            <div className="col-xs-12">
              <p>
                {state.deleteFunFactObj?.textValue?.length > 80
                  ? state.deleteFunFactObj?.textValue?.substring(0, 80) + '...'
                  : state.deleteFunFactObj?.textValue
                }
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onDeletePopupConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onDeletePopupCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationFunFacts;