export const SUPPORT_STAFF_TYPE_DEFAULT_NAME = '--';
export const SUPPORT_STAFF_TYPE_AGENT_NAME = 'Agent';
export const SUPPORT_STAFF_TYPE_MANAGER_NAME = 'Manager';

export const REGISTRATION_SUPPORT_STAFF_TYPE_NAMES = [
  SUPPORT_STAFF_TYPE_DEFAULT_NAME,
  SUPPORT_STAFF_TYPE_AGENT_NAME,
  SUPPORT_STAFF_TYPE_MANAGER_NAME
];

const PersonBioInfoFormConstants = {
  SUPPORT_STAFF_TYPE_DEFAULT_NAME,
  SUPPORT_STAFF_TYPE_AGENT_NAME,
  SUPPORT_STAFF_TYPE_MANAGER_NAME,
  REGISTRATION_SUPPORT_STAFF_TYPE_NAMES
};

export default PersonBioInfoFormConstants;