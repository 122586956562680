import { useContext } from "react";

import NTRegistrationContactsData from "./NTRegistrationContactsData";
import { NTRegistrationContactsContext } from "./NTRegistrationContactsContextProvider";

const useNTRegistrationContactsData = () => {
  const [nationalTeamRegistrationContactsState, setNationalTeamRegistrationContactsState] = useContext(NTRegistrationContactsContext);

  const getNTRegistrationContacts = (personId) => {
    return NTRegistrationContactsData.getNTRegistrationContactsData(personId, nationalTeamRegistrationContactsState, setNationalTeamRegistrationContactsState);
  };

  const putNTRegistrationContacts = (personId, personNTContactsObj) => {
    return NTRegistrationContactsData.putNTRegistrationContactsData(personId, personNTContactsObj, nationalTeamRegistrationContactsState, setNationalTeamRegistrationContactsState);
  };

  return {
    nationalTeamRegistrationContactsState,
    getNTRegistrationContacts,
    putNTRegistrationContacts
  };
};

export default useNTRegistrationContactsData;