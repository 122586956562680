import { Fragment } from 'react';

import useNTRegistrationSupportStaffDetail from './UseNTRegistrationSupportStaffDetail';

import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';
import NTSupportStaffAddForm from '../../../components/forms/nationalTeamSupportStaff/NTSupportStaffAddForm';
import NTSupportStaffEditForm from '../../../components/forms/nationalTeamSupportStaff/NTSupportStaffEditForm';

const NTRegistrationSupportStaffDetail = () => {
  const {
    isLoading,
    supportStaffObj,
    onCancelClicked,
    onSubmitSupportStaffAddForm,
    onSubmitSupportStaffEditForm
  } = useNTRegistrationSupportStaffDetail();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={supportStaffObj ? 'Edit Support Staff' : 'Add Support Staff'}>
        {supportStaffObj
          ? <NTSupportStaffEditForm supportStaffObj={supportStaffObj} onSubmitFormCallback={onSubmitSupportStaffEditForm} onSecondaryButtonClicked={onCancelClicked} />
          : <NTSupportStaffAddForm onSubmitFormCallback={onSubmitSupportStaffAddForm} onSecondaryButtonClicked={onCancelClicked} />
        }
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationSupportStaffDetail;