import { Fragment } from 'react';

import useNTRegistrationContactsAddressDetail from './UseNTRegistrationContactsAddressDetail';

import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';
import NTContactAddressForm from '../../../components/forms/nationalTeamContacts/NTContactAddressForm';

const NTRegistrationContactsAddressDetail = () => {
  const {
    isLoading,
    contactName,
    contactAddressObj,
    onCancelClicked,
    onSubmitContactForm
  } = useNTRegistrationContactsAddressDetail();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={contactAddressObj ? 'Edit Address' : 'Add Address'}>
        {contactName &&
          <div className="row">
            <div className="col-xs-12">
              <p><b>Contact Name:</b> {contactName}</p>
            </div>
          </div>
        }
        <NTContactAddressForm
          contactAddressObj={contactAddressObj}
          contactAddressTypesArray={contactAddressObj?.personNationalTeamRegistrationContactAddressType}
          submitButtonText={contactAddressObj ? 'Save' : 'Add Address'}
          onSubmitFormCallback={onSubmitContactForm}
          onSecondaryButtonClicked={onCancelClicked} />
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationContactsAddressDetail;