import useFeetInchesInput from './UseFeetInchesInput';

import useInput from '../../../common/components/inputs/UseInput';
import ReadOnly from '../../../common/components/readOnly/ReadOnly';

import global from '../../../common/components/GlobalStyle.module.css';

const FeetInchesInput = ({ className, label, name, value, error, message, onChange }) => {
  const { fullClassAttribute, isReadWrite } = useInput(className, global, error);
  const { displayFormattedValueState, setDisplayFormattedValueState, formatValueForDisplay } = useFeetInchesInput();

  if (isReadWrite === true) {
    return (
      <div className={global.ComponentFlex}>
        <label className={global.UsasLabel} htmlFor={name}>{label}</label>
        <div>
          <input
            className={fullClassAttribute}
            type={'text'}
            value={displayFormattedValueState ? formatValueForDisplay(value) : value}
            id={name}
            name={name}
            onBlur={() => { setDisplayFormattedValueState(true); }}
            onFocus={() => { setDisplayFormattedValueState(false); }}
            onChange={(e) => { onChange(e.target.value); }} />
        </div>
        {error && <p className={global.ErrorMessage}>{message}</p>}
      </div>
    );
  } else {
    return (
      <ReadOnly
        label={label}
        name={name}
        value={formatValueForDisplay(value)} />
    );
  }
};

export default FeetInchesInput;