import { Fragment } from 'react';

import useNationalTeamAttachments from './UseNationalTeamAttachments';
import NationalTeamAttachmentsGrid from './NationalTeamAttachmentsGrid';

import DeleteAttachmentModal from './DeleteAttachmentModal';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../common/utils/Constants';

const NationalTeamAttachments = () => {
  const {
    title,
    onAddAttachment,
    onEditAttachment,
    onOpenDeleteAttachmentModal,
    personNationalTeamAttachmentState,
    deleteAttachmentModalState,
    onDeleteAttachmentModalCanceled,
    onDeletePersonNationalTeamAttachment
  } = useNationalTeamAttachments();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{title}</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={(e) => onAddAttachment(e)}>Add Attachment</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <NationalTeamAttachmentsGrid
            state={personNationalTeamAttachmentState}
            onEditAttachment={onEditAttachment}
            onOpenDeleteAttachmentModal={onOpenDeleteAttachmentModal} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={deleteAttachmentModalState.modalTitle}
        displayPopUp={deleteAttachmentModalState.displayPopUp}
        onModalCanceled={onDeleteAttachmentModalCanceled}>
        <DeleteAttachmentModal modalState={deleteAttachmentModalState} />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onDeletePersonNationalTeamAttachment(e, deleteAttachmentModalState?.personAttachmentId)}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteAttachmentModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personNationalTeamAttachmentState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personNationalTeamAttachmentState.isObjLoading || personNationalTeamAttachmentState.isArrayLoading} />
    </Fragment>
  );
};

export default NationalTeamAttachments;