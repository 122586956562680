import { useState } from 'react';

import NationalTeamAttachmentFileData from './NationalTeamAttachmentFileData';

const useNationalTeamAttachmenFileData = () => {
  const [nationalTeamAttachmentFileUploadState, setNationalTeamAttachmentFileUploadState] = useState(NationalTeamAttachmentFileData.INITIAL_NATIONAL_TEAM_ATTACHMENT_FILE_UPLOAD_STATE);
  const [nationalTeamAttachmentFileDownloadState, setNationalTeamAttachmentFileDownloadState] = useState(NationalTeamAttachmentFileData.INITIAL_NATIONAL_TEAM_ATTACHMENT_FILE_DOWNLOAD_STATE);
  const [nationalTeamAttachmentFileUpdateState, setNationalTeamAttachmentFileUpdateState] = useState(NationalTeamAttachmentFileData.INITIAL_NATIONAL_TEAM_ATTACHMENT_FILE_UPDATE_STATE);
  const [nationalTeamAttachmentFileDeleteState, setNationalTeamAttachmentFileDeleteState] = useState(NationalTeamAttachmentFileData.INITIAL_NATIONAL_TEAM_ATTACHMENT_FILE_DELETE_STATE);

  const postNationalTeamAttachmentFile = (file) => {
    NationalTeamAttachmentFileData.postNationalTeamAttachmentFileData(file, nationalTeamAttachmentFileUploadState, setNationalTeamAttachmentFileUploadState);
  };

  const getNationalTeamAttachmentFile = (fileUrl) => {
    return NationalTeamAttachmentFileData.getNationalTeamAttachmentFileData(fileUrl, nationalTeamAttachmentFileDownloadState, setNationalTeamAttachmentFileDownloadState);
  };

  const putNationalTeamAttachmentFile = (file, oldFileUrl) => {
    NationalTeamAttachmentFileData.postNationalTeamAttachmentFileUpdateData(file, oldFileUrl, nationalTeamAttachmentFileUpdateState, setNationalTeamAttachmentFileUpdateState);
  };

  const deleteNationalTeamAttachmentFile = (personNationalAttachmentUrl) => {
    NationalTeamAttachmentFileData.deleteNationalTeamAttachmentFileData(personNationalAttachmentUrl, nationalTeamAttachmentFileDeleteState, setNationalTeamAttachmentFileDeleteState);
  }

  const getFileNameFromSavedUploadUrl = () => {
    if (nationalTeamAttachmentFileUploadState.isObjLoaded === true) {
      const fileNameWithDateTime = nationalTeamAttachmentFileUploadState.objData.uploadUrl.replace(NationalTeamAttachmentFileData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return nationalTeamAttachmentFileUploadState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    try {
      const fileNameWithDateTime = url.replace(NationalTeamAttachmentFileData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);

      return decodeURI(fileName) || '';
    } catch (e) {
      return url;
    }
  };

  return {
    nationalTeamAttachmentFileUploadState,
    nationalTeamAttachmentFileDownloadState,
    nationalTeamAttachmentFileUpdateState,
    getNationalTeamAttachmentFile,
    postNationalTeamAttachmentFile,
    putNationalTeamAttachmentFile,
    deleteNationalTeamAttachmentFile,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl
  };
};

export default useNationalTeamAttachmenFileData;