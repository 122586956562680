import { useEffect, useState } from 'react';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';
import useNationalTeamAttachmentFileData from '../../../state/nationalTeamAttachmentFile/UseNationalTeamAttachmentFileData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

import Constants from '../../../../common/utils/Constants';

const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf) or (*.jpg) file.';
const SAVING_ERROR_MSG = 'The last action is still saving. Please try again.';

const INITIAL_REMOVE_FILE_MODAL_STATE = {
  displayPopUp: false,
  fileName: '',
  modalTitle: 'Remove Uploaded File?'
};

const INITIAL_FILE_STATE = {
  attachmentUrl: '',
  attachmentDescription: '',
  attachmentName: '',
  personNationalAttachmentFileTypeId: Constants.DEFAULT_ID,
  personNationalAttachmentFileTypeName: '',
  personNationalAttachmentId: Constants.DEFAULT_ID
};

const useMemberPassportUpload = () => {
  const { basePersonState } = useBasePersonData();
  const { nationalTeamAttachmentFileUploadState, nationalTeamAttachmentFileUpdateState, getNationalTeamAttachmentFile,
    postNationalTeamAttachmentFile, putNationalTeamAttachmentFile, getFileNameFromUrl } = useNationalTeamAttachmentFileData();
  const { personNationalTeamAttachmentState, postPersonNationalTeamAttachment, getPersonNationalTeamAttachmentsByPersonId,
    deletePersonNationalTeamAttachmentById } = usePersonNationalTeamData();
  const [uiState, setUiState] = useState({ passportVisible: false });
  const [fileState, setFileState] = useState(INITIAL_FILE_STATE);
  const [removeFileModalState, setRemoveFileModalState] = useState(INITIAL_REMOVE_FILE_MODAL_STATE);
  const [state, setState] = useState({ error: '' });
  const [previousFileState, setPreviousFileState] = useState({ previousFileUrl: '' });

  const onUploadPassport = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (personNationalTeamAttachmentState.isSaving === true || nationalTeamAttachmentFileUpdateState.isObjLoading === true) {
      setState({ ...state, error: SAVING_ERROR_MSG });
    }
    else {
      setState({ ...state, error: '' });
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const fileType = e.target.files[0].type;
        if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_JPG) {
          if (previousFileState.previousFileUrl) {
            putNationalTeamAttachmentFile(file, previousFileState.previousFileUrl);
          }
          else {
            postNationalTeamAttachmentFile(file);
          }
          setState({ ...state, error: '' });
        }
        else {
          setState({ ...state, error: FILE_TYPE_ERROR_MSG });
        }
      }
    }
  };

  const onDownloadUploadedFile = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    showUploadedFile(fileState.attachmentUrl);
  };

  const onViewSamplePassport = () => {
    setUiState({ passportVisible: !uiState.passportVisible })
  };

  const onOpenRemoveFileModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...removeFileModalState, fileName: fileState.attachmentUrl, displayPopUp: true });
  };

  const onRemoveFileModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...INITIAL_REMOVE_FILE_MODAL_STATE });
  };

  const onRemovePassportClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    onRemoveFileModalCanceled();

    if (personNationalTeamAttachmentState.isSaving === true) {
      setState({ ...state, error: SAVING_ERROR_MSG });
    }
    else {
      setState({ ...state, error: '' });
      setPreviousFileState({ ...previousFileState, previousFileUrl: fileState.attachmentUrl });
      setFileState({ ...fileState, attachmentUrl: '' });

      deletePersonNationalTeamAttachmentById(fileState.personNationalAttachmentId);
    }
  };

  useEffect(() => {
    if (basePersonState?.isObjLoading === false && basePersonState?.isObjLoaded === true) {
      getPersonNationalTeamAttachmentsByPersonId(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  useEffect(() => {
    if ((nationalTeamAttachmentFileUploadState?.isObjLoading === false && nationalTeamAttachmentFileUploadState?.isObjLoaded === true) ||
      (nationalTeamAttachmentFileUpdateState?.isObjLoading === false && nationalTeamAttachmentFileUpdateState?.isObjLoaded === true)) {
      const postObj = createPostObject();

      if (postObj.attachmentUrl.includes("Bad Request") === false) {
        postPersonNationalTeamAttachment(createPostObject());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamAttachmentFileUploadState, nationalTeamAttachmentFileUpdateState]);

  useEffect(() => {
    if (personNationalTeamAttachmentState?.isArrayLoading === false && personNationalTeamAttachmentState?.isArrayLoaded === true &&
      personNationalTeamAttachmentState?.arrayData.length > 0 && personNationalTeamAttachmentState?.isObjLoaded === false) {
      const passport = personNationalTeamAttachmentState?.arrayData.findLast(x => x.personNationalAttachmentFileTypeId === 3);

      setFileState({
        ...fileState,
        attachmentUrl: passport === undefined ? "" : passport.attachmentUrl,
        attachmentDescription: passport === undefined ? "" : passport.attachmentDescription,
        attachmentName: passport === undefined ? "" : passport.attachmentName,
        personNationalAttachmentFileTypeId: passport === undefined ? -1 : passport.personNationalAttachmentFileTypeId,
        personNationalAttachmentFileTypeName: passport === undefined ? "" : passport.personNationalAttachmentFileTypeName,
        personNationalAttachmentId: passport === undefined ? -1 : passport.personNationalAttachmentId
      });
    }
    else if (personNationalTeamAttachmentState.isObjLoaded) {
      setFileState({
        ...fileState,
        attachmentUrl: personNationalTeamAttachmentState.objData.attachmentUrl,
        attachmentDescription: personNationalTeamAttachmentState.objData.attachmentDescription,
        attachmentName: personNationalTeamAttachmentState.objData.attachmentName,
        personNationalAttachmentFileTypeId: personNationalTeamAttachmentState.objData.personNationalAttachmentFileTypeId,
        personNationalAttachmentFileTypeName: personNationalTeamAttachmentState.objData.personNationalAttachmentFileTypeName,
        personNationalAttachmentId: personNationalTeamAttachmentState.objData.personNationalAttachmentId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personNationalTeamAttachmentState]);

  async function showUploadedFile(fileUrl) {
    if (fileUrl) {
      const getFileResult = await getNationalTeamAttachmentFile(fileUrl);

      if (getFileResult) {
        if (getFileResult.objData?.blobFile?.size > 0) {
          var file = window.URL.createObjectURL(getFileResult.objData?.blobFile);
          const link = document.createElement('a');

          link.href = file;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
    }
  }

  function createPostObject() {
    const personId = basePersonState?.objData?.personId;
    let postObj = {};
    postObj = {
      attachmentDescription: "Passport Copy",
      attachmentName: "Passport",
      attachmentUrl: nationalTeamAttachmentFileUpdateState.objData.uploadUrl || nationalTeamAttachmentFileUploadState.objData.uploadUrl,
      personNationalAttachmentFileTypeId: 3,
      personNationalAttachmentFileTypeName: "Passport Copy",
      personId: personId
    };
    return postObj;
  }

  return {
    uiState,
    removeFileModalState,
    personNationalTeamAttachmentState,
    fileState,
    onUploadPassport,
    onViewSamplePassport,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled,
    onRemovePassportClicked,
    onDownloadUploadedFile,
    getFileNameFromUrl
  }
}

export default useMemberPassportUpload;