import { createContext, useState } from 'react';

import BioEntryData from './BioEntryData';

export const BioEntryContext = createContext();

const BioEntryContextProvider = ({ children }) => {
  const stateHook = useState(BioEntryData.INITIAL_BIO_ENTRY_STATE);

  return (
    <BioEntryContext.Provider value={stateHook}>
      {children}
    </BioEntryContext.Provider>
  );
};

export default BioEntryContextProvider;