import { createContext, useState } from 'react';

import NTRegistrationContactsData from './NTRegistrationContactsData';

export const NTRegistrationContactsContext = createContext();

const NTRegistrationContactsContextProvider = ({ children }) => {
  const stateHook = useState(NTRegistrationContactsData.INITIAL_NATIONAL_TEAM_REGISTRATION_CONTACTS_STATE);

  return (
    <NTRegistrationContactsContext.Provider value={stateHook}>
      {children}
    </NTRegistrationContactsContext.Provider>
  );
};

export default NTRegistrationContactsContextProvider;