import { useEffect } from 'react';

import validate from './NTSupportStaffEditFormValidation';

import useForm from '../../../../common/utils/UseForm';
import { DEFAULT_PHONE_NUMBER_AREA_CODE } from '../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: DEFAULT_PHONE_NUMBER_AREA_CODE
};

const useNTSupportStaffEditForm = (supportStaffObj, onSubmitFormCallback) => {
  const { formState, errorState, handleSubmit, updateFormState, setFormData
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    if (supportStaffObj) {
      setFormData({
        ...INITIAL_FORM_STATE,
        firstName: supportStaffObj.firstName || '',
        lastName: supportStaffObj.lastName || '',
        email: supportStaffObj.email || '',
        phoneNumber: supportStaffObj.phoneNumber || DEFAULT_PHONE_NUMBER_AREA_CODE
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportStaffObj]);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  };
};

export default useNTSupportStaffEditForm;

