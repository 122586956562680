import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ coachPerson }) => (
  <tr>
    <td>{coachPerson.preferredName || coachPerson.firstName} {coachPerson.lastName}</td>
    <td>{coachPerson.effectiveDate ? formatDate(coachPerson.effectiveDate) : ''}</td>
    <td>{coachPerson.expirationDate ? formatDate(coachPerson.expirationDate) : ''}</td>
  </tr>
);

const CoachPersonGridLarge = ({ gridData, isLoading }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Coach Name</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="3">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((coachPerson, i) => <GridRow key={i} coachPerson={coachPerson} />)
          : <tr><td colSpan="3">No Coaches</td></tr>
      }
    </tbody>
  </table>
);

export default CoachPersonGridLarge;