import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTContactsData from "../../../state/nationalTeamContacts/UseNTContactsData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

const INITIAL_STATE = {
  contactObj: undefined,
  contactAddressObj: undefined
};

const useNationalTeamContactAddressDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { nationalTeamContactsState, putNTContacts } = useNTContactsData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCancelClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.MEMBER_NT_CONTACTS?.route);
    } catch (e) {
      console.log(e); //TODO: Add error handling
    }
  };

  const onSubmitContactForm = async (formState) => {
    try {
      const personId = nationalTeamContactsState.objData.personId;
      const newPersonNTContactsObj = JSON.parse(JSON.stringify(nationalTeamContactsState.objData));
      const targetContactIndex = newPersonNTContactsObj.personNationalTeamContact.findIndex((x) => x.personNationalTeamContactId === state.contactObj.personNationalTeamContactId);

      if (state.contactAddressObj) {
        const targetAddressIndex = newPersonNTContactsObj.personNationalTeamContact[targetContactIndex].personNationalTeamContactAddress.findIndex((x) => x.personNationalTeamContactAddressId === state.contactAddressObj.personNationalTeamContactAddressId);

        newPersonNTContactsObj.personNationalTeamContact[targetContactIndex].personNationalTeamContactAddress[targetAddressIndex] = createPersonNationalTeamContactAddress(formState, newPersonNTContactsObj.personNationalTeamContact[targetContactIndex].personNationalTeamContactId, newPersonNTContactsObj.personNationalTeamContact[targetContactIndex].personNationalTeamContactAddress[targetAddressIndex]);
      } else {
        newPersonNTContactsObj.personNationalTeamContact[targetContactIndex].personNationalTeamContactAddress.push(createPersonNationalTeamContactAddress(formState, newPersonNTContactsObj.personNationalTeamContact[targetContactIndex].personNationalTeamContactId));
      }

      const putNTContactsResult = await putNTContacts(personId, newPersonNTContactsObj);

      if (putNTContactsResult) {
        navigate(navRoutes.MEMBER_NT_CONTACTS?.route);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    try {
      if (location.state?.contactObj && location.state?.contactAddressObj) {
        setState({ ...state, contactObj: location.state.contactObj, contactAddressObj: location.state.contactAddressObj });
      } else if (location.state?.contactObj) {
        setState({ ...state, contactObj: location.state.contactObj, contactAddressObj: null });
      }
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createPersonNationalTeamContactAddress(formState, personNationalTeamContactId, existingPersonNationalTeamContactAddress = undefined) {
    if (existingPersonNationalTeamContactAddress) {
      return {
        ...existingPersonNationalTeamContactAddress,
        address: {
          ...existingPersonNationalTeamContactAddress.address,
          address1: formState.address1.trim(),
          address2: formState.address2.trim(),
          city: formState.city.trim(),
          postalCode: formState.postalCode.trim(),
          stateCode: formState.stateCode,
          countryCode: formState.countryCode,
        },
        personNationalTeamContactAddressType: formState.addressType.map((x) => { return { personNationalTeamContactAddressId: existingPersonNationalTeamContactAddress.personNationalTeamContactAddressId, addressTypeId: x.id } })
      };
    } else {
      return {
        personNationalTeamContactId,
        address: {
          address1: formState.address1.trim(),
          address2: formState.address2.trim(),
          city: formState.city.trim(),
          postalCode: formState.postalCode.trim(),
          stateCode: formState.stateCode,
          countryCode: formState.countryCode,
        },
        personNationalTeamContactAddressType: formState.addressType.map((x) => { return { addressTypeId: x.id } })
      };
    }
  }

  return {
    isLoading: nationalTeamContactsState.isObjLoading,
    contactName: state.contactObj ? `${state.contactObj.firstName} ${state.contactObj.lastName}` : '',
    contactAddressObj: state.contactAddressObj || null,
    onCancelClicked,
    onSubmitContactForm
  };
};

export default useNationalTeamContactAddressDetail;