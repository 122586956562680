import useNTContactForm from "./UseNTContactForm";

import Input from "../../../../common/components/inputs/Input";
import ReadOnly from "../../../../common/components/readOnly/ReadOnly";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import InternationalPhoneInput from "../../../../common/components/inputs/InternationalPhoneInput";
import ContactRelationshipTypeDropdown from "../../../../common/components/dropdowns/contactRelationshipTypeDropdown/ContactRelationshipTypeDropdown";
import NoSelfContactRelationshipTypeDropdown from "../../../../common/components/dropdowns/noSelfContactTypeDropdown/NoSelfContactRelationshipTypeDropdown";

const NTContactForm = ({ contactObj = undefined, contactPhonesArray = undefined, submitButtonText = 'Save', secondaryButtonText = 'Cancel',
  isRelationshipTypeReadonly = false, excludeSelfRelationshipType = false, onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged,
  } = useNTContactForm(contactObj, contactPhonesArray, onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="First Name*"
            name="firstName"
            value={formState.firstName}
            error={errorState.firstName}
            message={errorState.firstName}
            onChange={(value) => { updateFormState('firstName', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Last Name*"
            name="lastName"
            value={formState.lastName}
            error={errorState.lastName}
            message={errorState.lastName}
            onChange={(value) => { updateFormState('lastName', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {isRelationshipTypeReadonly === true ? (
            <ReadOnly
              label="Relationship*"
              name="contactRelationshipTypeName"
              value={formState.contactRelationshipTypeName} />
          ) : excludeSelfRelationshipType === true ? (
            <NoSelfContactRelationshipTypeDropdown
              label="Relationship*"
              name="contactRelationshipTypeId"
              value={formState.contactRelationshipTypeId}
              error={errorState.contactRelationshipTypeId}
              message={errorState.contactRelationshipTypeId}
              onChange={(newValue, newValueLabel, e) => { e && e.target && e.target.value && onValueTextPairChanged(newValue, 'contactRelationshipTypeId', newValueLabel, 'contactRelationshipTypeName'); }}
              showNationalTeamOptions={true} />
          ) : (
            <ContactRelationshipTypeDropdown
              label="Relationship*"
              name="contactRelationshipTypeId"
              value={formState.contactRelationshipTypeId}
              error={errorState.contactRelationshipTypeId}
              message={errorState.contactRelationshipTypeId}
              onChange={(newValue, newValueLabel, e) => { e && e.target && e.target.value && onValueTextPairChanged(newValue, 'contactRelationshipTypeId', newValueLabel, 'contactRelationshipTypeName'); }}
              showNationalTeamOptions={true} />
          )}
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Email"
            name="email"
            value={formState.email}
            error={errorState.email}
            message={errorState.email}
            onChange={(value) => { updateFormState('email', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <InternationalPhoneInput
            label="Phone Number"
            name="phoneNumber"
            value={formState.phoneNumber}
            error={errorState.phoneNumber}
            message={errorState.phoneNumber}
            onChange={(value) => updateFormState('phoneNumber', value)} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default NTContactForm;