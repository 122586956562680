import { Fragment } from 'react';

import useNationalTeamAttachmentDetail from './UseNationalTeamAttachmentDetail';

import FileTypeDropdown from '../../../components/dropdowns/fileTypeDropdown/FileTypeDropdown';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Textarea from '../../../../common/components/inputs/Textarea';
import Input from '../../../../common/components/inputs/Input';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const ErrorHandle = ({ error, message }) => (
  <Fragment>
    {error && <p className={global.ErrorMessage}>{message}</p>}
  </Fragment>
)

const FileUpload = ({
  state,
  formState,
  fileState,
  errorState,
  onUploadTemplateClicked,
  onOpenRemoveFileModalClicked,
  onDownloadUploadedFile,
  removeFileModalState,
  onRemoveFileModalCanceled,
  onRemoveTemplateClicked
}) => {

  return (
    <Fragment>
      {fileState.templateFileUrl === ''
        ? <Fragment>
          <label htmlFor="file-upload" className={global.FileUpload}>
            Upload File
          </label>
          <input hidden id="file-upload" type="file" onChange={onUploadTemplateClicked} />
          &nbsp;(*.jpg) , (*.pdf), (*.doc), or (*.docx)
        </Fragment>
        : <Fragment>
          <SecondaryButton onClick={onOpenRemoveFileModalClicked}>
            {fileState.templateFileName.includes("Bad Request") ? "Clear Invalid" : "Remove Uploaded"} File
          </SecondaryButton>&nbsp;
          {fileState.templateFileName.includes("Bad Request") === false &&
            <PrimaryButton type="button" onClick={(event) => onDownloadUploadedFile(event)}>Download Uploaded File</PrimaryButton>
          }
        </Fragment>
      }
      <div hidden={fileState.templateFileUrl === ''}>
        <div className='row'>
          <div className="col-xs-12">
            {fileState.templateFileName.includes("Bad Request")
              ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
              : <p><b>Uploaded File Name:</b> {fileState.templateFileName}</p>
            }
          </div>
        </div>
      </div>
      <div className="row">
        <div className='col-xs-12 usas-extra-top-margin usas-extra-bottom-margin'>
          <ErrorHandle error={errorState?.attachmentError || state.error} message={errorState?.attachmentError || state.error} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={removeFileModalState.modalTitle}
        displayPopUp={removeFileModalState.displayPopUp}
        onModalCanceled={onRemoveFileModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>File Type:</b> {formState.fileTypeName}</p>
            {removeFileModalState.fileName.includes("Bad Request")
              ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
              : <p><b>Uploaded File Name:</b> {removeFileModalState.fileName}</p>
            }
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onRemoveTemplateClicked}>Yes, Remove</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onRemoveFileModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

const NationalTeamAttachmentDetail = () => {
  const {
    formState,
    errorState,
    fileState,
    state,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    onDownloadUploadedFile,
    onUploadTemplateClicked,
    onOpenRemoveFileModalClicked,
    removeFileModalState,
    onRemoveFileModalCanceled,
    onRemoveTemplateClicked,
    isSaving
  } = useNationalTeamAttachmentDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{state.attachmentId > 0 ? "Edit" : "Add"} Attachment</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <FileUpload
              state={state}
              formState={formState}
              fileState={fileState}
              errorState={errorState}
              onUploadTemplateClicked={onUploadTemplateClicked}
              onOpenRemoveFileModalClicked={onOpenRemoveFileModalClicked}
              onDownloadUploadedFile={onDownloadUploadedFile}
              removeFileModalState={removeFileModalState}
              onRemoveFileModalCanceled={onRemoveFileModalCanceled}
              onRemoveTemplateClicked={onRemoveTemplateClicked} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <FileTypeDropdown
              label="File Type*"
              name="fileTypeId"
              value={formState.fileTypeId}
              error={errorState.fileTypeId}
              message={errorState.fileTypeId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'fileTypeId', newValueLabel, 'fileTypeName', e); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="File Name*"
              name="fileName"
              value={formState.fileName}
              error={errorState.fileName}
              message={errorState.fileName}
              onChange={(value) => { onFormValueChanged('fileName', value); }} />
          </div>
          <div className="col-xs-12">
            <Textarea
              className={global.Textarea}
              label="Description*"
              name="attachmentDescription"
              value={formState.attachmentDescription}
              error={errorState.attachmentDescription}
              message={errorState.attachmentDescription}
              onChange={(value) => { onFormValueChanged('attachmentDescription', value); }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment >
  );
};

export default NationalTeamAttachmentDetail;